export const EMAIL =
	// eslint-disable-next-line max-len
	/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const ALPHABETS = /^[a-zA-Z]+$/;
export const ALPHANUMERIC = /^[a-zA-Z0-9]+$/;
export const PASSWORD = /^[a-zA-Z0-9@#$%^&+*!="%`(),-./:;<>?\\\\/{}~'|_[\]]+$/;
export const NUMERIC = /^\d+$/;
export const PRICE_RANGE = /^(\s*|[0-9,]*)$/;
export const PASSWORD_REGEX =
	/^(?!.* )(?=.{8,})(?=.*[0-9])(?=.*[A-Z])(?=.*[@#$%^&+*!="%`(),-./:;<>?\\\\/{}~'|_[\]]).*$/;
