import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { CmsContentButton } from "..";
import {
	getCatalogImage,
	getCMSAssetUrl,
	getPrices,
	getProductUrl,
} from "../../../../common/common";
import { CmsContentButtonProps } from "../../../../common/interfaces/common.interfaces";
import { useProductAttribute } from "../../../../common/product-hooks";
import {
	ProductAttribute,
	ProductExtensionAttributesInterface,
} from "../../../Pages/Catalog/catalog-interfaces";
import { CmsProductProps } from "../common";
import { ProductPriceBox, ProductBuyType } from "../../";

const Features = ({
	features,
}: {
	features: Array<string>;
}): React.ReactElement => {
	return features.length > 0 ? (
		<ul className="nav flex-column">
			{features.map((feature) => (
				<li className="mb-2 d-flex align-items-center" key={feature}>
					<img
						className="icon icon-sm new-check me-2"
						src="/assets/images/check.svg"
						alt="checkmark"
					/>
					{feature}
				</li>
			))}
		</ul>
	) : (
		<></>
	);
};

Features.defaultProps = {
	features: [],
};

type ProductInfo = CmsContentButtonProps & {
	attributes?: ProductAttribute[];
	name?: string;
	image?: string;
	specialPrice?: number;
	normalPrice?: number;
	membershipPrice?: number;
	sku?: string;
	extension_attributes?: ProductExtensionAttributesInterface;
};

const Product = (props: CmsProductProps): React.ReactElement => {
	const attributes = useProductAttribute([]);
	const { t } = useTranslation();
	let productInfo: ProductInfo = {};
	if (props.product) {
		const prices = getPrices(props.product);
		const {
			name,
			media_gallery_entries: mediaGallery,
			sku,
			extension_attributes,
		} = props.product;
		productInfo = {
			attributes: attributes.filter(
				(attribute) => props.product[attribute.attributeKey] !== "0",
			),
			name,
			image: mediaGallery[0].file,
			sku,
			extension_attributes,
			...prices,
		};
	} else {
		productInfo = {
			attributes: attributes.filter(
				(attribute) => props?.productType?.includes(attribute.value),
			),
			...props,
		};
	}

	return (
		<div className="product-tile-container col-xl-6 col-md-12 d-flex mb-3">
			<div className="product-tile d-flex w-100">
				<div className="d-flex justify-content-end">
					<ProductBuyType long attributes={productInfo.attributes} />
				</div>
				<Link
					to={
						props.sku
							? getProductUrl(productInfo)
							: productInfo.navigateTo || ""
					}
				>
					<div className="product-tile__image text-center">
						<img
							src={
								props.sku
									? getCatalogImage(productInfo.image)
									: getCMSAssetUrl(productInfo.image)
							}
						/>
					</div>
				</Link>
				<h4 className="mt-0 flex-grow-1">{productInfo.name}</h4>
				<div className="product-tile__content">
					{props?.features && <Features features={props.features} />}
					<div className="product-tile__prices price-container mb-4">
						<ProductPriceBox
							type={props.product?.type_id}
							product={props.product}
							specialPrice={productInfo.specialPrice}
							normalPrice={props.normalPrice}
							configPrice={
								props?.product?.custom_attributes
									?.price_configurable
							}
							membershipPrice={productInfo.membershipPrice}
						/>
					</div>
					<div className="d-flex justify-content-center">
						<CmsContentButton
							className="btn-default"
							navigateTo={
								productInfo.navigateTo ||
								getProductUrl(productInfo)
							}
							buttonLabel={
								productInfo.buttonLabel ||
								t("common.productMoreInfo")
							}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Product;
