import ActionTypes from "../store/types/actionTypes";
import { AckType } from "../interfaces/common.interfaces";

function forgotPasswordReducer(state = {}, action: any) {
	switch (action.type) {
		case ActionTypes.FORGOT_PASSWORD_SUCCESS:
			return {
				...state,
				ackType: AckType.SUCCESS,
			};
		case ActionTypes.FORGOT_PASSWORD_ERROR:
			return {
				...state,
				ackType: AckType.ERROR,
			};
		default:
			return state;
	}
}
export default forgotPasswordReducer;
