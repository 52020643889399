import ActionTypes from "../store/types/actionTypes";
import { AckType } from "../interfaces/common.interfaces";

function loginReducer(state = {}, action: any) {
	switch (action.type) {
		case ActionTypes.LOGIN_SUCCESS:
			return {
				...state,
				ackType: AckType.SUCCESS,
				loading: false,
			};
		case ActionTypes.LOGIN_ERROR:
			return {
				...state,
				ackType: AckType.ERROR,
				loading: false,
			};
		default:
			return state;
	}
}
export default loginReducer;
