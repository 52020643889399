import { versionActions, VersionState } from "../types/versionTypes";
import moment from "moment/moment";
import { RESET_REDUX_TIMESTAMP } from "../types/actionTypes";
import { REDUX_VERSION_NUMBER } from "../../Redux.constants";

const initialState: VersionState = {
  version: REDUX_VERSION_NUMBER,
  reduxDateTimeStamp: moment().toISOString()
};

export default function versionReducer (state = initialState, action: versionActions) {
  switch (action.type as string) {
    case RESET_REDUX_TIMESTAMP: {
      return {
        ...state,
        reduxDateTimeStamp: moment().toISOString(),
      };
    }
    default:
      return state;
  }
}
