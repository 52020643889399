import React, { ReactElement, useState, lazy } from "react";
import { Link } from "react-router-dom";
import {
	getCatalogImage,
	getProductUrl,
	isItemOutOfStock,
	isMobile,
	GetEcommerceData,
	gtmEventPublish,
	isBusiness, isPartner,
} from "../../../../common/common";
import { ProductTileProps } from "../../../Pages/Catalog/catalog-interfaces";
import TextEllipsis from "../../TextEllipsis/TextEllipsis";
import { ProductModal } from "..";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import * as cartActions from "../../../../common/actions/cart";
import * as datalayerActions from "../../../../common/store/types/datalayerTypes";
import { ProductPriceBox } from "../../index";
import * as productActions from "../../../../common/actions/product";
import ProductTileButtons from "../../Buttons/ProductTileButtons/ProductTileButtons";
const ProductBuyType = lazy(() => import("./ProductBuyType"));

const mapStateToProps = (state: any) => {
	return {
		...state.datalayer
	};
};

const connector = connect(mapStateToProps, {
	...cartActions,
	...datalayerActions,
	...productActions,
});

const ProductTile = (props: ProductTileProps): ReactElement => {
	const {
		rent_price,
		rent_time,
		product,
		closeCartModal
	} = props;
	const [show, setShow] = useState(false);
	const image = product.media_gallery_entries[0];
	const evean = props?.feeLabel?.toLowerCase().includes("evean");
	const { t } = useTranslation();
	const urlRewrite = props?.product.extension_attributes?.url_rewrite
		? product.extension_attributes.url_rewrite[0]
		: { id: "0" };
	const isOutOfStock = isItemOutOfStock(product) && !isPartner();
	// Check if product is AD and evean
	const isEveanAD = product?.custom_attributes?.category_ids.includes(
		"16",
	) && evean;

	const linkProps = {
		className:"d-flex flex-column",
		to:
			props.button
				? !isMobile() &&
				getProductUrl(product) +
				(props.button
					? "?crossSell=true"
					: props.upsell
						? "?upSell=true"
						: "")
				: getProductUrl(product) +
				(props.button
					? "?crossSell=true"
					: props.upsell
						? "?upSell=true"
						: "")
		,
		onClick:
	!isMobile()
		? (): void => {
			props.button &&
			props.closeCartModal &&
			props.closeCartModal();
			if (window.dataLayer) {
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				window.dataLayer.push({ ecommerce: null });
			}
			gtmEventPublish(
				"select_item",
				GetEcommerceData(
					[props?.product],
					[],
					props?.categories,
					"select_item",
				),
			);
		}
		: null
	};

	return (
		<div
			className={
				props.upsell && !props.button
					? "product-tile d-flex mb-3"
					: "product-tile flex-column d-flex justify-content-between align-items-stretch h-100"
			}
		>
			<Link {...linkProps}>
				<div className="d-flex justify-content-end">
					{product.product_type !== "buy" && (
						<ProductBuyType
							attributes={props.attributes.filter(
								(attribute) =>
									product.product_type ===
									attribute.value,
							)}
						/>
					)}
				</div>
				<div className="product-tile__image d-flex align-items-lg-start justify-content-center mb-4">
					{image ? (
						<img
							key={image.id}
							className="img-fluid"
							height={180}
							width={180}
							src={getCatalogImage(image.url || image.file)}
							alt={product.name}
						/>
					) : (
						<img height={180} width={180} alt={product.name} />
					)}
				</div>
				<div className="product-tile_texts-container">
					<div className="d-flex flex-column mb-2 h-100 product-tile_texts">
						<TextEllipsis className="product-tile__title">
							{product.name}
						</TextEllipsis>
						{product.product_type === "free_rental" ? (
							product.price === 0 ? (
								"Gratis huren"
							) : (
								<ProductPriceBox
									type={product?.type_id}
									product={product}
									specialPrice={product.specialPrice}
									normalPrice={product.price}
									configPrice={
										props?.product?.custom_attributes
											?.price_configurable
									}
									membershipPrice={
										product.membershipPrice
									}
								/>
							)
						) : product.product_type === "loan" ? (
							product.price === 0 ||
							product.type_id === "grouped" ? (
								<p className="fw-bold">Gratis lenen</p>
							) : (
								<ProductPriceBox
									type={product?.type_id}
									product={product}
									specialPrice={product.specialPrice}
									normalPrice={product.price}
									configPrice={
										props?.product?.custom_attributes
											?.price_configurable
									}
									membershipPrice={
										product.membershipPrice
									}
								/>
							)
						) : (
							<ProductPriceBox
								type={product?.type_id}
								product={product}
								specialPrice={product.specialPrice}
								normalPrice={product.price}
								configPrice={
									props?.product?.custom_attributes
										?.price_configurable
								}
								rent_price={rent_price}
								rent_time={rent_time}
								membershipPrice={product.membershipPrice}
							/>
						)}
						{product?.custom_attributes?.category_ids.includes(
							"16",
						) && evean ? (
							<div className="out-of-stock">
								{t("product_detail.evean")}
							</div>
						) : (
							product.type_id !== "configurable" && (
								<div className="out-of-stock">
									{(props?.whereIsOutOfStock
										? props?.product?.extension_attributes
												?.stock_item?.qty < 1
										: isOutOfStock) &&
										t("product_detail.outOfStock")}
								</div>
							)
						)}
					</div>
				</div>
			</Link>
			<div className="mt-auto">
				<div className="d-flex justify-content-center">
					<ProductTileButtons
						tileProduct={product}
						type={props.button ? "crossSell" : (props.upsell ? "upsell" : "list")}
						isEveanAD={isEveanAD}
						closeCartModal={closeCartModal}
					/>
					{show && (
						<ProductModal
							sku={urlRewrite?.id}
							onHide={(): void => setShow(false)}
							show={show}
							callbackHandler={(buyOptions): void => {
								props.callback &&
									props.callback({
										buyOptions,
										callback: () => setShow(false),
									});
							}}
						/>
					)}
				</div>
				{!props.button &&
					product.custom_attributes?.delivery_options_new &&
					!props.type && (
						<div className="delivery">
							{
								product.custom_attributes
									.delivery_options_new
							}
						</div>
					)}
				{isBusiness() && (
					<Link {...linkProps}>
						<a
							className={`underline-link d-flex justify-content-center ${(isOutOfStock && !isEveanAD) ? "mb-4 mt-3" : "mt-3"}`}
						>
							{(!isOutOfStock && !isEveanAD) ? t("common.productMoreInfo") : ""}
						</a>
					</Link>
				)}
			</div>
		</div>
	);
};

export default connector(ProductTile);
