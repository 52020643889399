import { combineReducers } from "redux";

// enchancers

// reducers
import loginReducer from "../../reducers/login";
import changePasswordReducer from "../../reducers/changepassword";
import forgotPasswordReducer from "../../reducers/forgotPassword";
import paymentReducer from "../../reducers/payment";
import myAccountReducer from "../../reducers/myaccount";
import categoryReducer from "../../reducers/category";
import productReducer from "../../reducers/product";
import cartReducer from "../../reducers/cart";
import cmspage from "../../reducers/cmspage";
import cmsBlock from "../../reducers/cmsBlock";
import membershipReducer from "../../reducers/membership";
import checkoutReducer from "../../reducers/checkout";
import profileReducer from "../../reducers/profile";
import clientReducer from "../../reducers/client";
import orderReducer from "../../reducers/order";
import urlRewriteReducer from "../../reducers/urlRewrites";
import storeLocatorReducer from "../../reducers/storeLocator";
import elasticSearchReducer from "../../reducers/elasticSearch";
import faqPageReducer from "../../reducers/faqpage";
import checkoutReducerWithType from "./checkoutReducers";
import loadingReducer from "../../reducers/loading";
import productReducerWithType from "./productReducers";
import datalayerReducer from "./datalayerReducer";
import loadingReducers from "./loadingReducers";
import { RESET_STATE } from "../types/actionTypes";
import versionReducer from "./versionReducer";

const rootReducer = combineReducers({
	// old reducers
	login: loginReducer,
	changePassword: changePasswordReducer,
	forgotPassword: forgotPasswordReducer,
	payment: paymentReducer,
	myaccount: myAccountReducer,
	category: categoryReducer,
	product: productReducer,
	cart: cartReducer,
	cmspage: cmspage,
	cmsBlock: cmsBlock,
	membership: membershipReducer,
	checkout: checkoutReducer,
	profile: profileReducer,
	client: clientReducer,
	order: orderReducer,
	urlRewrite: urlRewriteReducer,
	storeLocator: storeLocatorReducer,
	elasticSearch: elasticSearchReducer,
	faqpage: faqPageReducer,
	loading: loadingReducer,
	isLoading: loadingReducers,

	// new reducers
	businessCheckout: checkoutReducerWithType("business"),
	consumerCheckout: checkoutReducerWithType("consumer"),
	consumerListPageFilters: productReducerWithType("listPage", "consumer"),
	consumerSearchPageFilters: productReducerWithType("searchPage", "consumer"),
	businessListPageFilters: productReducerWithType("listPage", "business"),
	businessSearchPageFilters: productReducerWithType("searchPage", "business"),
	version: versionReducer,
	datalayer: datalayerReducer
});

const rootReducerWithReset = (state, action) => {
	if (action.type === RESET_STATE) {
		// Reset the state to its initial values
		state = undefined;
	}

	return rootReducer(state, action);
};

export default rootReducerWithReset;
