import React from 'react';
import { ProductInterface } from "../../../Pages/Catalog/catalog-interfaces";
import { Skeleton } from "@mui/material";
import AddToCart from "../../Product/AddToCart/AddToCart";
import HomeService from "../../Cms/HomeService/HomeService";
import { useLoadingEndpoints } from "../../../../common/common-hooks";

interface Props {
    isShopOnly: boolean;
    product: ProductInterface;
    quantity: number;
    configProduct?: ProductInterface;
    isDisabled: boolean
    categories: any;
    type: "button" | "submit" | "reset";
    onSubmit?: () => void;
    addToCart?: (data: object) => void;
    setShowModal?: (open: boolean) => void
}

const ProductCtaButtons = (props: Props) => {
    // This component will return the correct button depending on the location and product properties
    const {
        isShopOnly,
        product,
        quantity,
        configProduct,
        isDisabled,
        categories,
        type,
        onSubmit,
        addToCart,
        setShowModal
    } = props;
    const { loadingGetSingleProduct } = useLoadingEndpoints();

    const isThuisservice = (): boolean =>
        product?.custom_attributes?.delivery_type === "Thuisservice" &&
        product?.custom_attributes?.thuisservice;

    const handleAddToCart = () => {
        addToCart({
            sku: product?.sku,
            quantity: quantity,
        }).then(() => setShowModal(true));
    };

    const AddToCartButtons = () => {
        return (
            loadingGetSingleProduct ? (
                <>
                    <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width="100%"
                        height={45}
                        sx={{
                            borderRadius: 16,
                        }}
                    />
                </>
            ) : (
                !isThuisservice() ? (
                    <div className="d-block my-3_5 grouped-delivery-info">
                        <div className="grouped-left">
                            <AddToCart
                                qty={quantity}
                                product={product}
                                configProduct={configProduct}
                                type={type}
                                categories={categories}
                                disabled={product
                                        .type_id !==
                                    "grouped" && isDisabled}
                                clickHandler={async (): Promise<void> => {
                                    onSubmit ? onSubmit() : handleAddToCart();
                                }}
                                isShopOnly={isShopOnly}
                            />
                        </div>
                    </div>
                ) : product?.custom_attributes?.thuisservice === "Addtocart-form" ? (
                    <div className="d-block my-3_5 grouped-delivery-info">
                        <div className="grouped-left">
                            <AddToCart
                                qty={quantity}
                                product={product}
                                configProduct={configProduct}
                                type={type}
                                categories={categories}
                                disabled={isDisabled}
                                clickHandler={async (): Promise<void> => {
                                    onSubmit ? onSubmit() : handleAddToCart();
                                }}
                                isShopOnly={isShopOnly}
                            />
                        </div>
                        <HomeService product={product}/>
                    </div>
                ) : (
                    <HomeService product={product}/>
                )
            )
        );
    };

    return (
        <AddToCartButtons/>
    );
};

export default ProductCtaButtons;
