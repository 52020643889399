import React, { ReactElement, useEffect } from "react";
import PropTypes from "prop-types";
import { Input } from "../..";
import {
	ConfigurableProductOptions,
	ProductInterface,
} from "../../../Pages/Catalog/catalog-interfaces";
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";

export interface ProductConfigurationOptions {
	options: Array<ConfigurableProductOptions>;
	type: string;
	onChange?: (val: any, field: any, index: number) => void;
	optionChange: (values: ProductInterface) => void;
}
const ConfigurableOption = (
	props: ProductConfigurationOptions,
): ReactElement => {
	let options;
	const { t } = useTranslation();
	const { values }: any = useFormikContext();
	useEffect(() => {
		props.optionChange(values);
	}, [values]);
	switch (props.type) {
		case "dropdown":
			options = props.options.map(
				(option: ConfigurableProductOptions, index: number) => {
					return (
						<div key={index} className="mt-3 col-md-12 col-24">
							<Input
								type="dropdown"
								labelKey={t("form.validation.unknown", {
									unknown: option.label,
								})}
								placeholder={t("form.placeholders.choose")}
								name={option.attribute_code}
								items={option.values}
								className="form-control"
								required
								disabled={!option.values.length}
								onChange={(val, field): void =>
									props.onChange(val, field, index)
								}
								{...option}
							/>
						</div>
					);
				},
			);
			break;
		default:
			options = "Please provide correct options";
	}
	return <div className="configuration row">{options}</div>;
};

ConfigurableOption.propTypes = {
	options: PropTypes.array,
	type: PropTypes.string,
};

export default ConfigurableOption;
