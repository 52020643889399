import ActionTypes from "../store/types/actionTypes";
import { widgetArray, widgetId } from "../../common/common";

interface CmsState {
	isPending: boolean;
	pageContent?: any;
	isError?: boolean;
}

const initialState: CmsState = {
	isPending: true,
	isError: false,
};

const blockContentReplace = (pageContent, contentBlock) => {
	const newContent = { ...pageContent };
	const widgetArr = widgetArray(newContent.content);

	let widgetContent = newContent.content;
	widgetArr.forEach((val) => {
		const blockId = widgetId(val);
		contentBlock.forEach((block) => {
			if (blockId === block.id.toString()) {
				widgetContent = widgetContent.replace(val, block.content);
			}
		});
	});
	return { ...pageContent, content: widgetContent };
};

function cmsPageReducer(state = initialState, action: any) {
	const contentBlock = action.payload?.contentBlock;
	switch (action.type) {
		case ActionTypes.CMSPAGE_CONTENT_PENDING:
			return {
				...state,
				isPending: true,
				pageContent: {},
			};
		case ActionTypes.CMSPAGE_CONTENT_SUCCESS:
			return {
				...state,
				isPending: false,
				isError: false,
				pageContent: action.payload.pageContent,
			};
		case ActionTypes.CMSPAGE_CONTENT_UPDATE_REQUEST:
			return {
				...state,
				isPending: true,
			};
		case ActionTypes.CMSPAGE_CONTENT_UPDATE:
			return {
				...state,
				isPending: false,
				pageContent: blockContentReplace(
					state.pageContent,
					contentBlock,
				),
			};
		case ActionTypes.CMSPAGE_CONTENT_ERROR:
			return {
				...state,
				isPending: false,
				pageContent: {},
				isError: true,
			};
		default:
			return state;
	}
}

export default cmsPageReducer;
