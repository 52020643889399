import { START_LOADING, STOP_LOADING } from "../types/actionTypes";
import { Dispatch } from "redux";

export const startLoading = (endpoint: string) => (
    async (dispatch: Dispatch) => {
        try {
        dispatch( {
            type: START_LOADING,
            payload: endpoint
        });
    } catch {
        console.error("Unable to set loading state");
    }
});

export const stopLoading = (endpoint: string) => (
    async (dispatch: Dispatch) => {
        try {
            dispatch({
                type: STOP_LOADING,
                payload: endpoint
            });
        } catch {
            console.error("Unable to set loading state");
        }
    }
);
