import ActionTypes from "../store/types/actionTypes";
import { setFilters, setPriceRangeFilters } from "../common";

const initialState = {
	products: {},
	product: {},
	filters: {
		productType: [],
		categoryId: [],
		sortBy: "recommended",
		currentPage: "1",
		pageSize: "12"
	},
	priceRange: { from: "", to: "" },
	lastSelectedCategory: "",
	linkedProducts: [],
	freeRentalMembershipName: ''
};

function productReducer(state = initialState, action: any) {
	switch (action.type) {
		case ActionTypes.PRODUCT_DETAIL_SUCCESS:
			return {
				...state,
				product: action.payload.product,
			};
		case ActionTypes.CMS_PRODUCT_DETAIL_SUCCESS:
			return {
				...state,
				products: { ...state.products, ...action.payload },
			};
		case ActionTypes.PRODUCT_GET_ALL_PENDING:
			return {
				...state,
				isLoading: true,
				products: [],
			};
		case ActionTypes.PRODUCT_GET_ALL_SUCCESS:
			return {
				...state,
				isLoading: false,
			};
		case ActionTypes.PRODUCT_FILTER_CHANGE: {
			setFilters(action.payload.filters);
			return {
				...state,
				filters: action.payload.filters,
			};
		}
		case ActionTypes.SET_PRICE_FILTER:
			setPriceRangeFilters(action.payload.priceRange);
			return {
				...state,
				priceRange: action.payload.priceRange,
			};
		case ActionTypes.CLEAR_PRICE_FILTER:
			setPriceRangeFilters({ from: "", to: "" });
			return {
				...state,
				priceRange: { from: "", to: "" },
			};
		case ActionTypes.LAST_SELECTED_CATEGORY_CHANGE:
			return {
				...state,
				lastSelectedCategory: action.payload.lastSelectedCategory,
			};
		case ActionTypes.LINKED_PRODUCT_SUCCESS:
			return {
				...state,
				linkedProducts: [
					...state.linkedProducts,
					action.payload.linkedProducts,
				],
			};
		case ActionTypes.CLEAR_LINKED_PRODUCTS:
			return {
				...state,
				linkedProducts: [],
			};
		case ActionTypes.SET_FREE_RENTAL_MEMBERSHIP_NAME:
			return {
				...state,
				freeRentalMembershipName: action.payload,
			};
		default:
			return state;
	}
}
export default productReducer;
