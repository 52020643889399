import React from "react";

export const LoaderContext = React.createContext({
	loading: false,
	updateLoader: null,
});

export const ProductDetailContext = React.createContext({
	isOutOfStock: false,
	setIsOutOfStock: null,
});
