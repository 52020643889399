import ActionTypes from "../store/types/actionTypes";

const initialState = {
	headerData: {
		productsSearchResult: {},
		productPageData: {},
		contentSearchResult: {},
		categorySearchResult: {},
		contentPageData: {}
	},
	pageData: {
		productsSearchResult: {},
		productPageData: {},
		contentSearchResult: {},
		categorySearchResult: {},
		contentPageData: {}
	}
};

function elasticSearchReducer(state = initialState, action: any) {
	switch (action.type) {
		case ActionTypes.SEARCH_ELASTIC_SEARCH_PRODUCTS_HEADER:
			return {
				...state,
				headerData: {
					...state.headerData,
					productsSearchResult: action.payload.data.results,
					productPageData: action.payload.data.meta.page,
				}
			};
		case ActionTypes.SEARCH_ELASTIC_SEARCH_CONTENT_HEADER:
			return {
				...state,
				headerData: {
					...state.headerData,
					contentSearchResult: action.payload.data.results,
					contentPageData: action.payload.data.meta.page,
				}
			};
		case ActionTypes.SEARCH_ELASTIC_SEARCH_CATEGORIES_HEADER:
			return {
				...state,
				headerData:{
					...state.headerData,
					categorySearchResult: action.payload.data.results,
					categoryPageData: action.payload.data.meta.page,
				}
			};
		case ActionTypes.SEARCH_ELASTIC_SEARCH_PRODUCTS_PAGE:
			return {
				...state,
				pageData: {
					...state.pageData,
					productsSearchResult: action.payload.data.results,
					productPageData: action.payload.data.meta.page,
				}
			};
		case ActionTypes.SEARCH_ELASTIC_SEARCH_CONTENT_PAGE:
			return {
				...state,
				pageData: {
					...state.pageData,
					contentSearchResult: action.payload.data.results,
					contentPageData: action.payload.data.meta.page,
					location: action.location
				}
			};
		case ActionTypes.SEARCH_ELASTIC_SEARCH_CATEGORIES_PAGE:
			return {
				...state,
				pageData:{
					...state.pageData,
					categorySearchResult: action.payload.data.results,
					categoryPageData: action.payload.data.meta.page,
				}
			};
		default:
			return state;
	}
}

export default elasticSearchReducer;
