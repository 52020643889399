import * as React from "react";

interface TextEllipsisProps {
	suffix?: string;
	children?: React.ReactNode;
	style?: React.CSSProperties;
	className?: string;
}

const TextEllipsis: React.FC<TextEllipsisProps> = (
	props: TextEllipsisProps,
) => {
	const eleRef = React.useRef<HTMLDivElement>(null);

	React.useEffect(() => {
		const currentEle = eleRef.current;
		const content = currentEle.innerHTML.split(" ");
		while (currentEle.scrollHeight > currentEle.offsetHeight) {
			content.pop();
			currentEle.innerHTML = content.join(" ") + props.suffix;
		}
	}, [props.suffix]);
	return (
		<div ref={eleRef} style={props.style} className={props.className}>
			{props.children}
		</div>
	);
};

TextEllipsis.defaultProps = {
	suffix: "...",
};

export default TextEllipsis;
