import { datalayerActions, EcommerceEvent, EcommerceItem } from "../types/datalayerTypes";
import {
	ADD_ECOMMERCE_ITEMS,
	CLEAR_ECOMMERCE_OBJECT,
	PREPARE_ECOMMERCE_FOR_PURCHASE,
	UPDATE_ECOMMERCE_OBJECT
} from "../types/actionTypes";
import { calculateCheckoutFlowPrice, formatEcommerceItem } from "../../datalayer.common";

export const initialState: EcommerceEvent = {
	ecommerce: {
		items: [],
		buy_products_value: null,
		lend_products_value: null,
		rent_products_value: null,
		free_rental_products_value: null,
		currency: "EUR",
		value: 0
	}
};

export default function datalayerReducer (state = initialState, action: datalayerActions) {
	switch (action.type as string) {
			case UPDATE_ECOMMERCE_OBJECT: {
				return {
					...state,
					ecommerce: action.payload.ecommerce,
				};
			}
			case ADD_ECOMMERCE_ITEMS: {
				// retrieve old ecommerce data
				const ecommerce = state.ecommerce;

				// format cart items to ecommerce items
				const ecommerceItems: EcommerceItem[] = formatEcommerceItem(action.payload.cartInfo, action.payload.categories);

				// only replace items if the formatted commerce items are more than 1
				// this makes sure the datalayer events are not send with an empty item array when the cart is cleared
				// this is possible due to the fact that you cannot create an order without items
				if (ecommerceItems.length > 0) {
					ecommerce.items = ecommerceItems;
				}

				//add totals
				const { buy, rent, free_rental, loan, total } = calculateCheckoutFlowPrice(ecommerce.items, action.payload.products);
				const shipment = action.payload.cartInfo.totals.shipping_amount;
				ecommerce.buy_products_value = buy;
				ecommerce.lend_products_value = loan;
				ecommerce.rent_products_value = rent;
				ecommerce.free_rental_products_value = free_rental;
				ecommerce.value = total;

				if(shipment >= 0) {
					ecommerce.shipping = shipment;
					ecommerce.value = parseFloat((total + shipment).toFixed(2));
				}
				// remove coupons from previous orders
				ecommerce.coupon = "";
				return {
					...state,
					ecommerce: ecommerce
				};
			}
			case PREPARE_ECOMMERCE_FOR_PURCHASE: {
				// retrieve old ecommerce data
				const ecommerce = state.ecommerce;
				const value = ecommerce.value + action.payload.discount;
				//add extra data
				ecommerce.shipping = action.payload.shipping;
				ecommerce.tax = action.payload.tax;
				ecommerce.coupon = action.payload.coupon;
				ecommerce.transaction_id = action.payload.transaction_id;
				ecommerce.value = parseFloat(value.toFixed(2));
				return {
					...state,
					ecommerce: ecommerce
				};
			}
			case CLEAR_ECOMMERCE_OBJECT: {
				return {
					...state,
					ecommerce: initialState
				};
			}
			default:
				return state;
		};
}
