import React, { ReactElement } from "react";

import { ProductInterface } from "../../../Pages/Catalog/catalog-interfaces";

type HomeServiceProps = {
	product: ProductInterface;
	maxAllowedQty?: number;
	disableButton?: boolean;
	detailDisplay?: boolean;
	productAttributeChange?: any;
};

const HomeService = (props: HomeServiceProps): ReactElement => {
	const { product } = props;

	return product?.custom_attributes?.thuisservice === "Form" ? (
		<div className="form mt-3 mb-3">
			{product?.custom_attributes?.cta_tekst && (
				<div
					dangerouslySetInnerHTML={{
						__html: product.custom_attributes.cta_tekst,
					}}
				/>
			)}
			<a
				className="thuisservice-mobile"
				href="/service/thuisservice/thuisservice-afspraak-maken"
			>
				<span className="btn btn-primary">
					<span>Maak een afspraak</span>
				</span>
			</a>
		</div>
	) : product?.custom_attributes?.thuisservice === "Addtocart-form" ? (
		<div className="form mt-3 mb-3">
			{product?.custom_attributes?.cta_tekst && (
				<div
					dangerouslySetInnerHTML={{
						__html: product.custom_attributes.cta_tekst,
					}}
				/>
			)}
			<a
				className="cta-link"
				href="/service/klantenservice/advies-aan-huis/afspraak?"
			>
				{product?.custom_attributes?.cta_link}
			</a>
		</div>
	) : (
		product?.custom_attributes?.thuisservice === "Call-form" && (
			<div className="call-form">
				<div
					className="pre-info"
					dangerouslySetInnerHTML={{
						__html: product.custom_attributes.thuisservice_info,
					}}
				/>
				<a
					className="align-items-center thuisservice-mobile"
					href="tel:088-1020100"
				>
					<span className="btn btn-primary">
						<span>Bel 088 - 10 20 100 (optie 3)</span>
					</span>
				</a>
				<div className="short-info">
					{product?.custom_attributes?.cta_tekst && (
						<div
							dangerouslySetInnerHTML={{
								__html: product.custom_attributes.cta_tekst,
							}}
						/>
					)}
					{product?.custom_attributes?.cta_link && (
						<a href="/service/thuisservice/thuisservice-afspraak-maken">
							{product.custom_attributes.cta_link}
						</a>
					)}
				</div>
			</div>
		)
	);
};

export default HomeService;
