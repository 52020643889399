import React, { FC } from "react";
import { Formik, Form } from "formik";
import { Input } from "../index";
import { useTranslation } from "react-i18next";
import Modal from "react-bootstrap/Modal";
import * as Yup from "yup";
import { newSku, setStorageItem } from "../../../common/common";
import { connect, ConnectedProps } from "react-redux";
import * as checkoutBusinessActions from "../../../common/store/actions/checkoutActions";
import * as productActions from "../../../common/actions/product";
import * as cartActions from "../../../common/actions/cart";

interface MemberShipModalProps {
    show?: boolean;
    setAccepted?: any;
    associations: Record<string, unknown>[];
	handleAssocCheck: any;
	product: any;
	isPLP?: boolean;
	setShow?: (b: boolean)=> void
}

const mapStateToProps = (state: any): any => {
	const { step, order, insuranceIndicationInformation } = state.businessCheckout;
	return {
		order,
		step,
		insuranceIndicationInformation
	};
};

const connector = connect<any, any, any>(mapStateToProps, {
	...checkoutBusinessActions,
	...productActions,
	...cartActions,
});

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & MemberShipModalProps;

const MembershipModal: FC<Props> = (props: Props) => {
	const {
		updateOrder,
		order,
		step,
		associations,
		setAccepted,
		show,
		handleAssocCheck,
		product,
		insuranceIndicationInformation,
		isPLP,
		setShow,
		addToCart
	} = props;
	const { t } = useTranslation();
	const initialValues = {
		selectedMembership: "",
		membershipNo: "",
		grantAccess: "",
	};

	const validationSchema = Yup.object().shape({
		grantAccess: Yup.boolean(),
		selectedMembership: Yup.string()
			.required(t("form.validation.required"))
			.test({
				name: "user-has-access",
				test: function () {
					return this.parent.grantAccess === false
						? this.createError({
								message: t("freeRental.form.denied"),
								path: "selectedMembership",
						  })
						: true;
				},
			}),
		// Free rental also accepts letters
		membershipNo: Yup.string()
			.max(10, t("form.validation.max", { max: 10 }))
			.required(t("form.validation.required")),
	});

	const handleSubmit = (e) => {
		setAccepted(true);
		setStorageItem("FREE_RENTAL_MEMBERSHIP_ID", e.selectedMembership);
		setStorageItem("FREE_RENTAL_MEMBERSHIP_NUMBER", e.membershipNo);

		// also update the state
		updateOrder(
			{ ...order, membershipNumber: e.membershipNo.toString(), membershipAssocation: e.selectedMembership.toString() },
			step,
			"business",
			insuranceIndicationInformation
		);

		// Find the name of the selected assosciation based on the assosciation number
		associations.map((assosciation: {label: string; value: number}) => {
			if (e.selectedMembership === assosciation.value){
				props.setFreeRentalMembershipName(assosciation.label);
			}
		});
		// If this modal is shown on the PDP, add the product to the cart
		if (isPLP && isPLP) {
			addToCart({
				sku: product,
				quantity: 1,
			});
			setShow(false);
		}
	};

	const checkInputContent = (values, errors): boolean => {
		return (
			Object.values(values).every((k) => k !== "") &&
			Object.keys(errors).length === 0
		);
	};

	const checkOrgAccess = async (value, setFieldValue): Promise<void> => {
		try {
			const accessRights = await handleAssocCheck(value, newSku(product));
			setFieldValue("grantAccess", accessRights.data.hasAccess);
		} catch {
			setFieldValue("grantAccess", false);
		}
	};

	return (
		<Modal
			show={show}
			backdropClassName={"free-rental-backdrop"}
			className="asoc-main-modal"
			centered
		>
			<Modal.Body className="membership-modal">
				<div className="membership-modal__inner">
					<h3 className="assoc-title mb-2">
						{t("freeRental.modal.modalTitle")}
					</h3>
					<p className="assoc-title mb-4">
						{t("freeRental.modal.modalSubTitle")}
					</p>
					<Formik
						onSubmit={(e): void => handleSubmit(e)}
						initialValues={initialValues}
						validationSchema={validationSchema}
					>
						{({
							isSubmitting,
							values,
							setFieldValue,
							errors,
						}): React.ReactNode => (
							<Form>
								<div className="mb-4">
									{associations && (
										<Input
											type="dropdown"
											labelKey="Ledenvereniging"
											placeholder={t(
												"membership.enroll.associationNumber",
											)}
											name="selectedMembership"
											items={associations}
											onChange={(e) => checkOrgAccess(e.value, setFieldValue)}
											className="form-control"
											required
										/>
									)}
								</div>
								<div>
									<Input
										type="text"
										labelKey="Lidmaatschapsnummer"
										placeholder={t("Lidmaatschapsnummer")}
										name="membershipNo"
										className="form-control"
										required
									/>
								</div>
								<div className="member-btn-container mt-4">
									<button
										onClick={(): string =>
											(window.location.href =
												"/producten")
										}
										disabled={isSubmitting}
										className="btn btn-secondary"
									>
										{t(
											"freeRental.modal.modalBackToProducts",
										)}
									</button>
									<button
										type="submit"
										disabled={
											!checkInputContent(values, errors)
										}
										className="btn btn-primary return-btn"
									>
										{t(
											"freeRental.modal.modalViewProducts",
										)}
									</button>
								</div>
							</Form>
						)}
					</Formik>
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default connector(MembershipModal);
