import ActionTypes from "../store/types/actionTypes";

const initialState = {
	categories: [],
	selectedCategoryPath: [],
};

function categoryReducer(state = initialState, action: any) {
	switch (action.type) {
		case ActionTypes.CATEGORY_GET_ALL:
			return {
				...state,
				categories: action.payload.categories,
			};
		case ActionTypes.SET_SELECTED_CATEGORY_PATH:
			return {
				...state,
				selectedCategoryPath: action.payload,
			};
		default:
			return state;
	}
}
export default categoryReducer;
