import { getStorageItem, removeStorageItem, setStorageItem } from "./common";

export const setCookie = (name: string, value: string, days: number, remember: boolean = true): void => {
	let expires = "";
	if (days) {
		const date = new Date();
		date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
		expires = "; expires=" + date.toUTCString();
	}

	if(remember) {
		document.cookie = name + "=" + value + expires + "; path=/; Secure";
	} else {
		setStorageItem(name, value);
	}
};

export const getCookie = (name: string): string | null => {
	const cookieName = name + "=";
	const decodedCookie = decodeURIComponent(document.cookie);
	const cookieArray = decodedCookie.split(';');
	for (let i = 0; i < cookieArray.length; i++) {
		const cookie = cookieArray[i].trim();
		if (cookie.indexOf(cookieName) === 0) {
			return cookie.substring(cookieName.length, cookie.length);
		}
	}
	return getStorageItem(name) || null;
};

export const deleteCookie = (name: string): void => {
	document.cookie = name + "=; Max-Age=-1; path=/";
	removeStorageItem(name);
};
