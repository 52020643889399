import { MutableRefObject, useCallback, useEffect, useRef, useState } from "react";
import { getLoggedInUserInfo } from "./common";
import { useSelector } from "react-redux";
import { RootState } from "./store";

export const isLoggedIn = (): boolean => {
	const userData = getLoggedInUserInfo();
	return userData && userData.role != null;
};
interface ComponentVisibleInterface {
	ref: MutableRefObject<HTMLInputElement>;
	isComponentVisible: boolean;
	setIsComponentVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

export const useComponentVisible = (
	initialIsVisible: boolean,
): ComponentVisibleInterface => {
	const [isComponentVisible, setIsComponentVisible] =
		useState(initialIsVisible);
	const ref = useRef(null);

	const handleClickOutside = (event): void => {
		if (ref.current && !ref.current.contains(event.target)) {
			setIsComponentVisible(false);
		}
	};

	useEffect(() => {
		document.addEventListener("click", handleClickOutside, true);
		return (): void => {
			document.removeEventListener("click", handleClickOutside, true);
		};
	});

	return { ref, isComponentVisible, setIsComponentVisible };
};

// Custom debounce hooks to allow us to delay input fields and such
export function useDebounce<T>(
	initialValue: T,
	time: number,
): [T, T, React.Dispatch<T>] {
	const [value, setValue] = useState<T>(initialValue);
	const [debouncedValue, setDebouncedValue] = useState<T>(initialValue);

	const setDebouncedValueMemoized = useCallback<React.Dispatch<T>>(
		(newValue) => {
			setValue(newValue);
		},
		[]
	);

	useEffect(() => {
		const debounce = setTimeout(() => {
			setDebouncedValue(value);
		}, time);
		return (): void => {
			clearTimeout(debounce);
		};
	}, [value, time]);

	return [debouncedValue, value, setValue, setDebouncedValueMemoized];
}

/**
 * A custom React Hook to manage state with a query parameter in the URL.
 *
 * @param {any | any[]} initialValue - The initial value of the state.
 * @param {string} paramName - The name of the query parameter.
 * @returns {Array} An array containing the current state value and a function to update it.
 *
 * @example
 * const [count, setCount] = useStateWithQueryParam(0, 'count');
 * setCount(1); will result in the URL being updated to ?count=1.
 */

export function useStateWithQueryParam (initialValue: any | any[], paramName: string): [any | any[], React.Dispatch<any>] {
  const [state, setState] = useState<any | any[]>(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const paramValue = urlParams.get(paramName);

    try {
      // Parse the query parameter value if it exists, otherwise use the initial value
      return paramValue ? JSON.parse(paramValue) : initialValue;
    } catch (error) {
      console.error(`Failed to parse query parameter value: ${paramValue}`);
      return initialValue;
    }
  });

  // Update the query parameter whenever the state changes.
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);

		// Remove the query parameter if the state is an empty array, null, or an empty object.
		// Otherwise, set the query parameter to the new state value.
		if ((Array.isArray(state) && state.length === 0) || state === null || (typeof state === "object" && Object.keys(state).length === 0)) {
			urlParams.delete(paramName);
		} else {
			urlParams.set(paramName, JSON.stringify(state));
		}

		// We create a replaceState, so we can decode the URL before replacing it
		const replaceState = window.location.pathname + `?${urlParams}`;

		window.history.replaceState({ path: window.location.pathname }, "", decodeURIComponent(replaceState));
  }, [state, paramName]);

  return [state, setState];
}

export const useLoadingEndpoints = () => {
	return useSelector((state: RootState) => state.isLoading.loadingEndpoints);
};
