import * as React from "react";
import { ToasterProps } from "./toaster-types";
import classNames from "classnames";
import { scrollToPageTop } from "../../../common/common";

const Toast: React.FC<ToasterProps> = (props: ToasterProps) => {
	React.useEffect(() => {
		const timeout = props.autoDismiss
			? setTimeout(() => props.onDismiss(props.id), props.dismissTimeout)
			: null;

		props.autoScroll && scrollToPageTop();
		return (): void => clearTimeout(timeout);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const toastClasses = classNames("custom-toast show", {
		[props.type]: true,
	});
	const iconClasses = classNames("icon icon-lg", {
		"info-circle-red-24": props.type === "error",
		"check-circle-green-24": props.type === "success",
	});

	return (
		<div className={toastClasses}>
			<div className="d-flex">
				<div className="custom-toast__icon">
					<em className={iconClasses}></em>
				</div>
				<div className="custom-toast__text">
					<strong className="custom-toast__text-header">
						{props.title}
					</strong>
					{props.content}
				</div>
				<div className="custom-toast__close">
					<button
						className="btn-link border-0 p-0"
						onClick={(): void => props.onDismiss(props.id)}
					>
						<em className="icon icon-sm close-red-16"></em>
					</button>
				</div>
			</div>
		</div>
	);
};

Toast.defaultProps = {
	dismissTimeout: 5000,
	autoDismiss: true,
	autoScroll: true,
	content: <p></p>,
};

export default Toast;
