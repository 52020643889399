import ActionTypes from "../store/types/actionTypes";

function profileReducer(state = {}, action: any) {
	switch (action.type) {
		case ActionTypes.USER_DETAIL_SUCCESS:
			return {
				...state,
				user: action.payload.data,
			};
		case ActionTypes.USER_DETAIL_CLEAR:
			return {
				...state,
				user: {},
			};
		default:
			return state;
	}
}
export default profileReducer;
