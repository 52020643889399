import ActionTypes from "../store/types/actionTypes";

interface CmsBlockState {
	content?: any;
	isError?: boolean;
}
const initialState: CmsBlockState = {
	isError: false,
};
type ActionProps = {
	type: string;
	payload: {
		content: string;
	};
};
function cmsBlockReducer(
	state = initialState,
	action: ActionProps,
): CmsBlockState {
	switch (action.type) {
		case ActionTypes.CMSPAGE_CONTENT_BLOCK_PENDING:
			return {
				...state,
				isError: false,
				content: {},
			};
		case ActionTypes.CMSPAGE_CONTENT_BLOCK_SUCCESS:
			return {
				...state,
				content: { ...state.content, ...action.payload },
			};
		case ActionTypes.CMSPAGE_CONTENT_BLOCK_ERROR:
			return {
				...state,
				isError: true,
				content: {},
			};
		default:
			return state;
	}
}

export default cmsBlockReducer;
