import React from "react";
import { replaceCmsTagsWithHtmlTags } from "../../../../common/cms-tag-parser";
import JsxParser from "react-jsx-parser";
import { CmsLink } from "../../Cms";

type CmsContentToHtmlProps = {
	content: string;
};

const CmsContentToHtml = (props: CmsContentToHtmlProps): JSX.Element => {
	const content = replaceCmsTagsWithHtmlTags(props.content);
	return <JsxParser components={{ CmsLink }} jsx={content} />;
};

export default CmsContentToHtml;
