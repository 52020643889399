import ActionTypes from "../store/types/actionTypes";
import { Dispatch } from "redux";

export const updateLoadingState = (loading: boolean) => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: ActionTypes.SET_LOADING,
      payload: {
        loading,
      },
    });
  };
};
