import { Dispatch } from "redux";
import { nodeAPI } from "../services/abstract.service";
import {
	getLoggedInUserInfo,
	getStorageItem,
	Roles,
	setStorageItem,
	StorageKeys
} from "../common";

import { getSelectedClient } from "../../components/UI/Client/client-helper";
import { _getProducts } from "./product";
import ActionTypes from "../store/types/actionTypes";
import { CategoryObjectProps } from "../interfaces/common.interfaces";
import { startLoading, stopLoading } from "../store/actions/loadingActions";

export const _getCategories = (): Promise<{
	data: Array<CategoryObjectProps>;
}> => {
	return nodeAPI
		.get("/category?isActive=true")
		.then((response: { data: Array<CategoryObjectProps> }) => {
			const categories = response.data;
			setStorageItem(
				StorageKeys.CATEGORIES,
				JSON.stringify([...categories]),
			);
			return Promise.resolve(response);
		})
		.catch((error) => {
			return Promise.reject(error);
		});
};

export const getCategories = () => {
	return async (
		dispatch: Dispatch,
	): Promise<{
		categories: Array<CategoryObjectProps>;
		productSuggestions: Record<string, string>[];
	}> => {

		dispatch<any>(startLoading("loadingCategories"));
		const user = getLoggedInUserInfo();

		let productSuggestions = [];

		/*
				Code is an work around for now to prevent breadcrum from breaking
				===================================================================
											START
				===================================================================
			*/
		let categories: CategoryObjectProps[] = JSON.parse(
			getStorageItem(StorageKeys.CATEGORIES),
		);
		if (!categories) {
			await _getCategories()
				.then((response) => (categories = filterCategoryByRole(response.data)))
				.catch((error) => {
					return Promise.reject(error);
				});
		}
		//check for new category property.
		if (categories.length > 0) {
			// eslint-disable-next-line no-prototype-builtins
			if (
				!Object.prototype?.hasOwnProperty.call(categories[0], "category_is_business") ||
				!Object.prototype?.hasOwnProperty.call(categories[0], "top_text")
			) {
				await _getCategories()
					.then((response) => (categories = filterCategoryByRole(response.data)))
					.catch((error) => {
						return Promise.reject(error);
					});
			}
		}

		if (user && [Roles.CARE_PROFESSIONAL].includes(user.role)) {
			const selectedClient = getSelectedClient();
			const indication = selectedClient?.client?.careIndication;
			if (indication && indication !== "") {
				//TODO: makes it redux base
				const cate = await nodeAPI.get(
					`category?categoryName=${indication}`,
				);
				if (cate.status === 200) {
					const fetchedCategory = cate.data;
					if (fetchedCategory.length > 0) {
						const { id } = cate.data[0];
						const { uzoviCode, packageCode } = JSON.parse(
							getStorageItem(StorageKeys.INSURANCE_DETAILS),
						);
						const fetchSuggestions = await _getProducts({
							id,
							uzoviCode,
							packageCode,
							role: user.role,
						});
						if (
							fetchSuggestions.status === 200 &&
							fetchSuggestions.data
						) {
							productSuggestions = fetchSuggestions.data.slice(
								0,
								3,
							);
						}
					}
				}
			}
		}

		/*
			===================================================================
										END
			===================================================================
		*/
		return new Promise((resolve) => {
			const payload = { categories, productSuggestions };
			dispatch({
				type: ActionTypes.CATEGORY_GET_ALL,
				payload,
			});
			dispatch<any>(stopLoading("loadingCategories"));
			resolve(payload);
		});
	};
};

export const setSelectedCategoryPath = (selectedCategoryPath: string) => {
	return (dispatch: Dispatch): Promise<Array<string>> => {
		const categoryPath = selectedCategoryPath?.split("/");

		return new Promise((resolve) => {
			dispatch({
				type: ActionTypes.SET_SELECTED_CATEGORY_PATH,
				payload: categoryPath,
			});
			resolve(categoryPath);
		});
	};
};

// Should receive "filter" object which contains  "productType" array
// Ex.: filters.productType = ['loan', 'buy', 'rent']
// Allowed types: loan, buy, rent, free_rental
// role: one of 'CARE_PROFESSIONAL', 'PARTNER', 'CONSUMER'(default), 'CLIENT', 'HOSPICE'
export const getCategoryProductQuantity = (filters, role = "CONSUMER") => {
	const types = filters.productType;
	const priceRange = filters.priceRange;
	let query = `?role=${role}&`;
	if (types?.length > 0) {
		types.forEach((value) => {
			query += `types[]=${value}&`;
		});
	}
	if (priceRange?.length > 0) {
		query += `priceRange=${priceRange}`;
	}
	return async (dispatch: Dispatch): Promise<any> => {
		dispatch<any>(startLoading("loadingCategoriesProductQuantity"));
		return new Promise((resolve, reject) => {
			nodeAPI
				.get("/category/product-qty" + query)
				.then((result) => resolve(result))
				.catch((error) => {
					reject(error);
				})
				.finally(() => {
					dispatch<any>(stopLoading("loadingCategoriesProductQuantity"));
				});
		});
	};
};

export const getMenuItems = () => {
	return async (dispatch: Dispatch): Promise<any> => {
		dispatch<any>(startLoading("loadingMenuItems"));
		return new Promise((resolve, reject) => {
			nodeAPI
				.get("/navbar")
				.then((result) => resolve(result))
				.catch((error) => {
					reject(error);
				})
				.finally(() => {
					dispatch<any>(stopLoading("loadingMenuItems"));
				});
		});
	};
};

export const filterCategoryByRole = (categories) => {
	const filterCategoriesRecursively = (categories) => {
		const filteredCategories = categories.filter((category) => category.category_is_business === "1");

		for (const category of categories) {
			if (category.children_data && category.children_data.length > 0) {
				category.children_data = filterCategoriesRecursively(category.children_data);
			}
		}

		return filteredCategories;
	};

	const user = JSON.parse(sessionStorage.getItem(StorageKeys.USER_INFO));
	const businessRoles = [Roles.CARE_PROFESSIONAL, Roles.PARTNER, Roles.HOSPICE];
	if (businessRoles.includes(user?.role)) {
		if (categories) {
			categories = filterCategoriesRecursively(categories);
		}
	}

	return categories;
};
