import ActionTypes from "../store/types/actionTypes";

const initialState = {
	payment: {},
};

function paymentReducer(state = initialState, action: any) {
	switch (action.type) {
		case ActionTypes.PAYMENT_COUPON_SUCCESS:
			return {
				...state,
				payment: action.payload.payment,
			};
		default:
			return state;
	}
}
export default paymentReducer;
