
import * as hist from "history";
const createHistory = hist.createBrowserHistory;
import reducer from "./reducers/rootReducer";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import { configureStore } from "@reduxjs/toolkit";
import { routerMiddleware } from "react-router-redux";
import { thunk } from "redux-thunk";
import { RESET_REDUX_TIMESTAMP, RESET_STATE } from "./types/actionTypes";
import { REDUX_VERSION_NUMBER } from "../Redux.constants";
import moment from "moment/moment";
import { clearStorages } from "../common";

const historyOpts = {};

const preloadedState = {};
export const history = createHistory(historyOpts);
const middleware = [thunk, routerMiddleware(history)];

const persistConfig = {
	key: "root",
	storage,
	blacklist: ['profile']
};

const persistedReducer = persistReducer(persistConfig, reducer);

const store = configureStore({
	reducer: persistedReducer,
	middleware: middleware,
	devTools: process.env.NODE_ENV !== "production",
	preloadedState,
});

const persistor = persistStore(store, null, () => {
	// check for differences in version or too old timestamps and reset the store to keep it updated.
	const state = store.getState();
	if (
		state.version.version !== REDUX_VERSION_NUMBER ||
		moment().diff(moment(state.version.reduxDateTimeStamp), 'days') > 2
	) {
		clearStorages();
		localStorage.clear();
		store.dispatch({ type: RESET_STATE });
	}

	// Reset timestamp on new load
	store.dispatch({
		type: RESET_REDUX_TIMESTAMP,
	});
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

export { store, persistor };
