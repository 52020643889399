import { useFormikContext } from "formik";
import React, { ReactElement, useEffect, useState } from "react";
import { ConfigurableOption } from "..";
import { ProductTypeIds, sortObjectByKey } from "../../../../common/common";
import {
	ConfigurableProductOptions,
	ProductInterface,
} from "../../../Pages/Catalog/catalog-interfaces";
interface ConfigurableProductInterface {
	product: ProductInterface;
	productAttributeChange?: (values: ProductInterface) => void;
	resetProductHandler?: () => void;
}

const ConfigurableProduct = (
	props: ConfigurableProductInterface,
): ReactElement => {
	const [configOptions, setConfigOptions] = useState([]);
	const [changeField, setChangeField] = useState<any>({});
	const { dirty, setFieldValue, setFieldTouched }: any = useFormikContext();

	const isConfigurableProduct = (): boolean => {
		return props.product.type_id === ProductTypeIds.CONFIGURABLE;
	};

	const getConfigurableProductOptions = (
		product,
	): Array<ConfigurableProductOptions> => {
		return sortObjectByKey(
			product?.extension_attributes?.configurable_product_options,
			"position",
		);
	};

	const getConfigurableProduct = (values): ProductInterface => {
		const configProduct = props.product.configurable_attributes.find(
			(prod) => {
				return Object.keys(values).every(
					(attr) => prod.custom_attributes[attr] == values[attr],
				);
			},
		);
		return configProduct;
	};

	const prepareDropdownOptions = (values = {}, ind = 0): any => {
		const newOptions = [];
		const arrVal = Object.keys(values);
		const options = getConfigurableProductOptions(props.product);
		options?.forEach((option, index) => {
			let obj;
			if (index === ind) {
				obj = {
					...option,
					values: option.values
						.map((item) => ({
							label: item.value_label,
							value: item.value_index,
						}))
						.filter(
							(item) =>
								props.product?.configurable_attributes.some(
									(prod) => {
										if (arrVal[0]) {
											return (
												prod?.custom_attributes[
													arrVal[0]
												] == values[arrVal[0]] &&
												prod?.custom_attributes[
													option.attribute_code
												] == item.value
											);
										} else {
											return true;
										}
									},
								),
						),
					selectedValue: "",
				};
			} else if (index < ind) {
				obj = {
					...option,
					values: configOptions[index]?.values,
					selectedValue: values[option.attribute_code],
				};
			} else {
				obj = {
					...option,
					values: [],
					selectedValue: "",
				};
			}
			newOptions.push(obj);
		});
		setConfigOptions(newOptions);
	};

	const resetFieldValues = (values, setFieldValue, setFieldTouched): void => {
		const options = getConfigurableProductOptions(props.product);
		let flag = false;
		const newConfig = { ...configOptions };
		options.forEach((option, index: number) => {
			if (flag) {
				setFieldValue(option.attribute_code, "", false);
				setFieldTouched(option.attribute_code, false, false);
				newConfig[index].values = [];
			}
			if (option.attribute_code === changeField?.field) {
				flag = true;
			}
		});
		changeField?.field &&
			prepareDropdownOptions(
				{ [changeField.field]: changeField?.value },
				changeField?.index,
			);
	};

	const productChangeHandler = (dirty, values): void => {
		const selectedValuesArr = Object.keys(values);
		let newProduct = props.product;
		if (
			dirty &&
			selectedValuesArr.length ===
				getConfigurableProductOptions(props.product).length &&
			selectedValuesArr.every((value) => values[value])
		) {
			const newConfigProduct = getConfigurableProduct(values);
			if (newConfigProduct) {
				newProduct = newConfigProduct;
			}
			props.productAttributeChange(newProduct);
		}

		if (selectedValuesArr.every((value) => values[value] === "")) {
			props.resetProductHandler();
		}
	};

	useEffect(() => {
		prepareDropdownOptions();
	}, [props.product]);

	return (
		isConfigurableProduct() && (
			<div className="product-info-configurable-options">
				<ConfigurableOption
					type="dropdown"
					options={configOptions}
					onChange={(val, field, ind): void => {
						setChangeField({
							value: val.value,
							field: field.name,
							index: ind + 1,
						});
					}}
					optionChange={(selectedValues): void => {
						resetFieldValues(
							selectedValues,
							setFieldValue,
							setFieldTouched,
						);
						productChangeHandler(dirty, selectedValues);
					}}
				/>
			</div>
		)
	);
};

export default ConfigurableProduct;
