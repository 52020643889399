import { CheckoutActions, CheckoutState } from "../types/checkoutTypes";
import {
	CLEAR_CHECKOUT_ORDER, SET_CHECKOUT_ISECD,
	SET_CHECKOUT_ORDER_ID,
	UPDATE_CHECKOUT_ORDER,
	UPDATE_CHECKOUT_STEP,
} from "../types/actionTypes";

export const initialState: CheckoutState = {
	order: {
		role: "",
		cartId: 0,
		totalAmount: 0,
		paymentOptionList: 0,
		paymentOptionSubList: 0,
		isNewsletterSubscribe: false,
		deliveryInfo: {
			deliveryDate: "",
			deliveryType: "",
			addressRemarks: [
				""
			],
			organisation: "",
			clientAddress: {
				country: "NL",
				street: "",
				houseNumber: "",
				houseNumberSuffix: "",
				postalCode: "",
				city: "",
			},
			clientInformation: {
				emailAddress: "",
				phoneNumber: "",
				firstName: "",
				middleName: "",
				lastName: "",
				gender: "",
				birthDate: "",
			},
			deviatingAddress: {
				country: "NL",
				street: "",
				houseNumber: "",
				houseNumberSuffix: "",
				postalCode: "",
				city: "",
			},
			invoiceAddress: {
				emailAddress: "",
				phoneNumber: "",
				firstName: "",
				middleName: "",
				lastName: "",
				birthDate: "",
				country: "NL",
				street: "",
				houseNumber: "",
				houseNumberSuffix: "",
				postalCode: "",
				city: "",
			}
		},
		indications: undefined,
		membershipAssocation: undefined,
		membershipNumber: undefined,
		caregiverInfo: {
			fullName: "",
			emailAddress: ""
		}
	},
	step: 0,
	completedList: [],
	orderId: "",
	insuranceIndicationInformation: {
		organisations: [
			{
				code: "",
				name: "",
				type: "",
				freeText: false,
				ssoEnabled: false,
				ecdEnabled: false,
				ecdID: "",
				ecdName: ""
			}
		],
		indicationQuestions: [
			{
				id: "",
				type: "",
				description: "",
				mandatory: false
			}
		]
	},
	ecd: {
		isEcd: false,
		ecdClientId: "",
		ecdClientCode: ""
	}
};

export default function checkoutReducerWithType (orderName: "business" | "consumer") {
	return function checkoutReducer (state = initialState, action: CheckoutActions) {
		if(orderName !== action.name)
			return state;
		switch (action.type as string) {
			case CLEAR_CHECKOUT_ORDER:
				// If step is -1 clear the whole state
				if (action.payload.step === -1) return initialState;

				return {
					...state,
					order: initialState.order,
					step: action.payload.step,
					completedList: action.payload.completedList,
					orderId: action.payload.orderId,
					insuranceIndicationInformation: initialState.insuranceIndicationInformation,
					ecd: {
						isEcd: false,
						ecdClientId: "",
						ecdClientCode: ""
					}
				};
			case UPDATE_CHECKOUT_ORDER:
				return {
					...state,
					order: action.payload.order,
					step: action.payload.step,
					insuranceIndicationInformation: action.payload.insuranceIndicationInformation
				};
			case UPDATE_CHECKOUT_STEP:
				return {
					...state,
					step: action.payload.step,
					completedList: action.payload.completedList,
				};
			case SET_CHECKOUT_ORDER_ID:
				return {
					...state,
					orderId: action.payload.orderId
				};
			case SET_CHECKOUT_ISECD:
				return {
					...state,
					ecd: action.payload.ecd
				};
			default:
				return state;
		}
	};
}
