import ActionTypes from "../store/types/actionTypes";

function urlRewrites(state = {}, action: any): any {
	if (action.type === ActionTypes.URL_REWRITE) {
		return {
			...state,
			...action.payload,
		};
	} else {
		return state;
	}
}
export default urlRewrites;
