import { magentoCloudUrl } from "./services/abstract.service";
import { PASSWORD_REGEX } from "./patterns";
import { isLoggedIn } from "./common-hooks";
import { store } from "./store";
import { ProductFilterType, ProductInterface } from "../components/Pages/Catalog/catalog-interfaces";
import moment from "moment";
import { clearCart, fetchTotal } from "./actions/cart";
import bedverhogers from "./data/bedverhogers.json";
import { filterCategoryByRole, getCategories } from "./actions/category";
declare global {
	interface Window {
		permanentRedirects: any;
		Cypress: any; // For automated testing
		authService: any; // For automated testing
		dataLayer?: unknown // datalayer
	}
}

export const getCMSAssetUrl = (assetUrl: string): string =>
	`${magentoCloudUrl}/media/${assetUrl}`;

export const getCatalogImage = (assetURL: string): string =>
	`${magentoCloudUrl}/media/catalog/product/${assetURL}`;

export const termsConditionUrl = "/over-medipoint/algemene-voorwaarden";

export const routesURL = {
	CATEGORIES: "/producten",
	FULL_ASSORTMENT: "/producten/volledig-assortiment.html" || "/producten/volledigassortiment.html",
	HOME: "/home",
	CHECKOUT: "/checkout",
	STORELOCATOR: "/locaties",
	SEARCH: "/search",
	LOGIN: "/login",
	CLIENTS: "/clients",
	CART: "/cart",
	PRODUCT: "/product",
	CONTACT: "/service/klantenservice/contact",
	LANDING: "/landing",
	RETURN: "/order-hub",
	AD_REQUEST: "/ad-materiaal-aanvragen",
	URGENT_ORDER: "/spoed-bestelling",
	FAQ_PAGE: "/service/klantenservice/veelgestelde-vragen",
	SEARCH_RESULT_PAGE: "/zoekresultaten/"
};

export const StorageKeys = {
	INSURANCE_DETAILS: "insuranceDetails",
	CATEGORIES: "categories",
	COUPON_CODE: "couponcode",
	BREADCRUMB: "breadcrumb",
	CHECKOUT_ITEMS: "checkoutItems",
	PAYMENT_METHODS: "paymentMethods",
	SELECTED_USER: "selectedUser",
	CARTID: "cartId",
	SEARCH_CATEGORY: "searchCategory",
	SHIPPING: "shipping",
	TAX: "tax",
	FIRST_SEARCH: "firstSearch",
	ACCESS_TOKEN: "accessToken",
	USER_INFO: "userInfo",
	CALLOUT_DISABLED: "callout",
	ONLOAD_ERROR_400: "ONLOAD_ERROR_400",
	ORDER_ID: "orderId",
	CART_INFO: "cartInfo",
	SELECTED_CLIENT: "clients.selectedClient",
	ORDER_DETAILS: "orderDetails",
	URL_REWRITE: "urlRewrite",
	RECENTLY_VIEWED_ITEMS: "recentlyViewedItems",
	PRODUCT_FILTERS: "productFilters",
	PRICE_RANGE_FILTER: "priceRangeFilter",
	PRODUCT_TYPE_FILTER: "productTypeFilter",
	PRODUCT_VIEW_TYPE: "productViewType",
	LAST_VISITED_CATEGORY: "lastVisitedCategory",
	ECOMMERCE: "ecommerce",
	SEARCH_PAGE_NUMBER: "searchPage",
};
export const Roles = {
	CARE_PROFESSIONAL: "CARE_PROFESSIONAL",
	CONSUMER: "CONSUMER",
	PARTNER: "PARTNER",
	HOSPICE: "HOSPICE",
};

export const MisaRoles = {
	CARE_GIVER: "CAREGIVER",
	CLIENT: "CLIENT",
	PARTNER: "PARTNER",
	HOSPICE: "HOSPICE",
};
export const PaymentOptions = {
	BILLINK: "Billink",
	VISA_MASTERCARD: "Visa Mastercard",
	IDEAL: "iDEAL",
};

export const dateFormat = "DD-MM-YYYY"; // will break if translated

export const displayDate = "DD MMM YYYY"; // will break if translated

export enum LayoutTypes {
	ONE_COLUMN = "oneColumn",
	TWO_COLUMN_LEFT = "twoColumnLeft",
}

export const removeStorageItem = (key): void => {
	sessionStorage.removeItem(key);
};

export const setStorageItem = (key, value): void => {
	sessionStorage.setItem(key, value);
};

const filterBusinessCategories = () => {
	let categories = sessionStorage.getItem(StorageKeys.CATEGORIES);
	const parsedCategories = JSON.parse(categories);
	categories = filterCategoryByRole(parsedCategories);
	return JSON.stringify(categories);
};

export const getStorageItem = (key: string): string => {
	if (key === StorageKeys.CATEGORIES) {
		return filterBusinessCategories();
	} else {
		return sessionStorage.getItem(key);
	}
};

export const formatToYYYYMMDD = (date = ""): string => {
	const dateObj = date.split("-");
	return dateObj.length > 1
		? `${dateObj[2]}-${dateObj[1]}-${dateObj[0]}`
		: "";
};

export const convertToCamelCase = (input) => {
	if (input) {
		const splitStr = input.toLowerCase().split(" ");
		for (let i = 0; i < splitStr.length; i++) {
			splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
		}
		return splitStr.join(" ");
	}
};
export const toTitleCase = (str) => {
	return str?.replace(
		/\w\S*/g,
		function (txt) {
			return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
		}
	);
};

// Formik Form HandleKeyDown to prevent Enter not triggering the handleSubmit()
export const handleFormKeyDown = (event, formik): void => {
	if (event.key === "Enter" && !event.shiftKey) {
		event.preventDefault();
		formik.handleSubmit();
	}
};

export const handleServerError = (toast, message) => {
	return toast({ type: "error", content: message });
};

export function generateUEID(): string {
	let first: any = (Math.random() * 46656) | 0;
	let second: any = (Math.random() * 46656) | 0;
	first = ("000" + first.toString(36)).slice(-3);
	second = ("000" + second.toString(36)).slice(-3);
	return first + second;
}

export const isValidPassword = (value: string): boolean => {
	return PASSWORD_REGEX.test(value);
};

export const currencyFormatter = (amount = 0, currencySymbol = "€"): string => {
	return `${currencySymbol} ${new Intl.NumberFormat("de-DE", {
		style: "currency",
		currency: "EUR",
	})
		.format(amount)
		.replace(currencySymbol, "")}`;
};

export const objToQueryString = (obj): string => {
	const arr = [];
	Object.keys(obj).forEach((key) => {
		if (obj[key]) {
			arr.push(`${key}=${obj[key]}`);
		}
	});
	return arr.join("&");
};

export const filterProductQuery = (
	query: ProductFilterType,
	excludeKey = [],
): string => {
	const q = [];
	Object.keys(query).forEach((k) => {
		if (Array.isArray(query[k])) {
			query[k].length > 0 && q.push(`${k}=${query[k].toString()}`);
		} else {
			if (query[k]) {
				q.push(
					excludeKey.includes(k) ? `${query[k]}` : `${k}=${query[k]}`,
				);
			}
		}
	});
	return q.join("&");
};

export const getLoggedInUserInfo = () => {
	return JSON.parse(getStorageItem(StorageKeys.USER_INFO));
};

export const needToAdd = (sku) => {
	const viewedItems = JSON.parse(
		getStorageItem(StorageKeys.RECENTLY_VIEWED_ITEMS),
	);
	let needToAdd = true;
	viewedItems.map((val) => {
		if (val.sku === sku) {
			needToAdd = false;
		}
	});
	return needToAdd;
};

export const addRecentlyViewedProduct = (product) => {
	let viewedItems = JSON.parse(
		getStorageItem(StorageKeys.RECENTLY_VIEWED_ITEMS),
	);
	if (!viewedItems) {
		const itemsArray = [];
		itemsArray.push(product);
		setStorageItem(
			StorageKeys.RECENTLY_VIEWED_ITEMS,
			JSON.stringify(itemsArray),
		);
	} else {
		if (needToAdd(product.sku)) {
			if (viewedItems.length === 8) {
				viewedItems = viewedItems.slice(1, 8);
				viewedItems.push(product);
			} else {
				viewedItems.push(product);
			}
			setStorageItem(
				StorageKeys.RECENTLY_VIEWED_ITEMS,
				JSON.stringify(viewedItems),
			);
		}
	}
};

export const getRecentlyViewedProducts = () => {
	return JSON.parse(
		getStorageItem(StorageKeys.RECENTLY_VIEWED_ITEMS),
	)?.reverse();
};

// set SessionStorage for PLP Filters
export const setFilters = (filters) => {
	setStorageItem(StorageKeys.PRODUCT_FILTERS, JSON.stringify(filters));
};

export const setPriceRangeFilters = (priceRange) => {
	setStorageItem(StorageKeys.PRICE_RANGE_FILTER, JSON.stringify(priceRange));
};

export const setProductTypeFilter = (productType) => {
	setStorageItem(
		StorageKeys.PRODUCT_TYPE_FILTER,
		JSON.stringify(productType),
	);
};

export const setProductViewType = (viewType) => {
	setStorageItem(StorageKeys.PRODUCT_VIEW_TYPE, JSON.stringify(viewType));
};

export const getProductViewType = () => {
	return JSON.parse(getStorageItem(StorageKeys.PRODUCT_VIEW_TYPE)) || false;
};

// TODO navigator.userAgent will deprecate starting April 2022
export const isMobile = (): boolean => {
	return /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
		navigator.userAgent,
	);
};

export const isTablet = (): boolean => {
	return window.innerWidth < 1024.9;
};

export const isHomePage = (): boolean => {
	return (
		window.location.pathname === "/home" || window.location.pathname === "/"
	);
};

export const isMerkBeeld = (): boolean => {
	// Temporary fix until all pages have been saved from .container
	return (
		window.location.pathname.includes("/product/") ||
		window.location.pathname.includes("/product2/")
	);
};

export const isTrackAndTrace = (): boolean => {
	return (
		window.location.pathname.includes("/service/klantenservice/trackandtrace")
	);
};

export const isFAQ = (): boolean => {
	return (
		window.location.pathname.includes("/service/klantenservice/veelgestelde-vragen")
	);
};

export const setLoggedInUserInfo = (userInfo) => {
	setStorageItem(StorageKeys.USER_INFO, JSON.stringify(userInfo));
};

export const isPartner = (): boolean => {
	const user = getLoggedInUserInfo();
	return user?.role === Roles.PARTNER || user?.role === Roles.HOSPICE;
};
export const isHospice = (): boolean => {
	const user = getLoggedInUserInfo();
	return user?.role === Roles.HOSPICE;
};
export const isCareProfessional = (): boolean => {
	const user = getLoggedInUserInfo();
	return user?.role === Roles.CARE_PROFESSIONAL;
};
export const isBusiness = (): boolean => {
	return isCareProfessional() || isPartner();
};

export const getFilters = () => {
	return (
		JSON.parse(getStorageItem(StorageKeys.PRODUCT_FILTERS)) || {
			productType: [],
			categoryId: [],
			priceRange: "",
			sortBy: isBusiness() || isHospice()
					? "name"
					: "recommended",
			sortDirection: "ASC",
			currentPage: "1",
			pageSize: "12",
		}
	);
};

export const ProductTypeIds = {
	SIMPLE: "simple",
	CONFIGURABLE: "configurable",
};

export enum ProductType {
	LOAN = "loan",
	BUY = "buy",
	RENT = "rent",
	FREE_RENTAL = "free_rental",
}

export const ProductTypes = {
	loan: {
		shortHand: "L",
	},
	rent: {
		shortHand: "H",
	},
	buy: {
		shortHand: "K",
	},
	free_rental: {
		shortHand: "H",
	},
};

export const ErrorCodes = {
	ERR4007: "ERR4007",
	ERR4008: "ERR4008",
	ERR5003: "ERR5003",
	ERR4014: "ERR4014",
	ERR4019: "ERR4019",
	ERR4024: "ERR4024",
	ERR4021: "ERR4021",
};

export const productNormalPrice = (product: any): number =>
	product?.custom_attributes?.rent_price || product.price;

export const productSpecialPrice = (product: any): number =>
	product?.custom_attributes?.special_price || 0;

export const convertItemToGtmProduct = (item: any) => {
	const {
		name,
		price,
		product_type: productType,
		sku,
		quantity,
		custom_attributes,
		media_gallery_entries,
		type_id,
		variantId,
		variantName,
	} = item;
	const gtmPrice = (): string => {
		switch (productType) {
			case "loan":
				return null;
			case "rent":
				return (22).toFixed(2);
			case "buy": {
				const isConfigurableProduct =
					type_id === ProductTypeIds.CONFIGURABLE;
				const priceValue = isConfigurableProduct
					? productNormalPrice(item)
					: price || 0;
				return Number(priceValue).toFixed(2);
			}
			default:
				return price;
		}
	};
	return JSON.parse(
		JSON.stringify({
			name,
			id: sku,
			price: gtmPrice(),
			coreAssortment: custom_attributes.assortiment || "",
			variantId,
			variantName,
			quantity,
			description: custom_attributes?.meta_description,
			imageUrl:
				Array.isArray(media_gallery_entries) &&
					media_gallery_entries.length > 0
					? getCatalogImage(media_gallery_entries[0].file)
					: undefined,
			attributeSet: productType === "loan" ? "lend" : undefined,
		}),
	);
};

export const gtmEventPublish = (event: string, data?: any): void => {
	(window as any).dataLayer.push({
		event,
		...data,
	});
	if (event === "purchase") {
		//Log the content of the "data" variable for the purchase event BY MODERN MINDS
		clearCart();
		removeStorageItem(StorageKeys.ORDER_ID);
		removeStorageItem(StorageKeys.COUPON_CODE);
	}
};

export const disappointmentEventPublish = (
	message: string,
	type: "error" | "warning" | "informative" = "error",
): void => {
	gtmEventPublish("disappointment", {
		disappointment_warning: message,
		disappointment_type: type,
	});
};

export const formDisappointmentEventPublish = (errors: string[]): void => {
	errors.map((error) => disappointmentEventPublish(error));
};

export const scrollToPageTop = () => {
	(window as any).scrollTo({ top: 0, left: 0, behavior: "smooth" });
};

export const widgetArray = (content) => content.match(/{{widget(.*?)}}/g);
export const widgetId = (content) => {
	const regex = /{{widget(.*?(block_id=(["\\']([^"\\']+)["\\'])))(.*?)}}/g;
	const elem: Array<string> = regex.exec(content);
	return elem[4];
};

export const replaceCmsImagePath = (content): string => {
	const reg = /{{media url=(.*?)}}/;
	const imgTagRegex = /(<img("[^"]*"|[^/">])*)>/gi;
	let result;
	while ((result = reg.exec(content)) !== null) {
		content = content?.replace(result[0], getCMSAssetUrl(result[1]));
	}
	return content?.replace(imgTagRegex, "$1/>");
};

export const isSunday = (day: number): boolean => day > 5;

export const isWeekend = (day: number): boolean => day > 4;

export const whoLoggedIn = getLoggedInUserInfo();

export const isEvean =
	whoLoggedIn?.feeLabel &&
	whoLoggedIn?.feeLabel.toLowerCase().indexOf("evean") !== -1;

export const hasCareProfessionalSidebar = (): boolean => {
	const user = getLoggedInUserInfo();
	return (
		isLoggedIn() &&
		(user.role === Roles.CARE_PROFESSIONAL ||
			user.role === Roles.PARTNER ||
			user?.role === Roles.HOSPICE)
	);
};

export const initialCategoryBreadcrumb = [
	{ title: "common.categories", linkTo: routesURL.FULL_ASSORTMENT, active: false },
];

export const prepareProductBreadcrumb = (selectedCategoryPath, entity) => {
	const breadcrumbs = isBusiness() ? [...initialCategoryBreadcrumb] : [];
	const cleanedPath = selectedCategoryPath?.filter((i) => i !== undefined);
	if (!isBusiness() && !isMobile()) {
		cleanedPath.map((e) => {
			breadcrumbs.push({
				title: e.name,
				linkTo: `/${e?.url_rewrite[0]?.request_path}`,
				active: false,
			});
		});
		breadcrumbs.push({
			title: entity?.name,
			linkTo: null,
			active: true,
		});
	} else if (!isBusiness() && isMobile()) {
		breadcrumbs.push({
			title: cleanedPath[0]?.name,
			linkTo: `/${cleanedPath[0]?.url_rewrite[0]?.request_path}`,
			active: false,
		});
	} else {
		selectedCategoryPath?.forEach((categoryName: string) => {
			breadcrumbs.push({
				title: categoryName,
				linkTo: null,
				active: true,
			});
		});
	}
	return breadcrumbs;
};

export const hasProductFilterSidebar = (location): boolean => {
	return isBusiness()
		? location.pathname.indexOf("/zoekresultaten") !== -1 ||
		location.pathname.indexOf(`${routesURL.CATEGORIES}`) !== -1
		: location.pathname.indexOf("/zoekresultaten") !== -1 ||
		location.pathname.indexOf(`${routesURL.CATEGORIES}/`) !== -1;
};

export const isSidebarEnabled = (location): boolean => {
	return (
		(hasCareProfessionalSidebar() &&
			![routesURL.CHECKOUT, routesURL.HOME, routesURL.STORELOCATOR, routesURL.RETURN].includes(location.pathname) &&
			location.pathname.indexOf("/page/") === -1) ||
		hasProductFilterSidebar(location)
	);
};
export const getPrices = (product: ProductInterface): any => {
	const user = getLoggedInUserInfo();
	const isBuy = product.product_type === "buy";
	const isRent = product.product_type === "rent";
	let membershipPrice = null;
	if (user?.role === Roles.CONSUMER) {
		membershipPrice = product?.tier_prices?.find(
			(t) => t.customer_group_id === user?.groupId,
		)?.value;
	}
	if (isBuy) {
		membershipPrice =
			membershipPrice ||
			product?.custom_attributes?.extra_high_membership_price;
	} else if (isRent) {
		membershipPrice =
			membershipPrice || product?.custom_attributes?.rent_member_price;
	}

	return {
		normalPrice: product?.price || null,
		specialPrice:
			(isBuy ? product?.custom_attributes?.special_price : null) || null,
		membershipPrice,
	};
};

export const stockQty = (product: any): number =>
	product?.extension_attributes?.stock_item?.qty || 0;

export const isProductOutOfStock = (product: any): boolean => {
	return stockQty(product) < 1;
};

export const formatDisplayDate = (date: string): string =>
	moment(formatToYYYYMMDD(date)).format(displayDate);

export const getImageMeta = (url, callback): void => {
	const img = new Image();
	img.onload = callback.bind(img);
	img.src = url;
};

export const sortObjectByKey = (object, key: string) => {
	return (
		object &&
		object.sort((a, b) => {
			if (a[key] > b[key]) return 1;
			if (b[key] > a[key]) return -1;

			return 0;
		})
	);
};

// This function adds the relevant user data into the page_view event for the datalayers
export const getUserDimensions = () => {
	const userInfo = getLoggedInUserInfo();
	const userDimensions = {
		loginStatus: userInfo ? "Logged in" : "Logged out",
		type: "CONSUMER"
	} as any;

	// add optional variables
	if (userInfo?.customerId) {
		userDimensions.userId = userInfo?.customerId;
	}
	// add the role of a logged in user, if the user is not business, add the consumer type
	if (userInfo?.role) {
		userDimensions.type = userInfo.role ? userInfo.role : "CONSUMER";
	}
	if (userInfo?.membershipType) {
		userDimensions.memberAssociation = userInfo?.membershipType;
	}
	if (userInfo?.feeLabel && isBusiness()) {
		userDimensions.organization = userInfo.feeLabel;
	}

	return userDimensions;
};

export const screenInfoPublish = (): void => {
	const cookieData =
		document.cookie && decodeURIComponent(document.cookie).split(",");
	const cookies = {
		marketing: false,
		statistics: false,
		functional: true,
	};
	cookieData &&
		cookieData.forEach((cookie) => {
			const [cookieKey, cookieVal] = cookie.split(":");
			if (
				cookieKey &&
				cookieVal &&
				Object.keys(cookies).includes(cookieKey)
			) {
				cookies[cookieKey] = cookieVal === "true";
			}
		});

	const user = getUserDimensions();
	const screenData = {
		page: {
			url: window.location.href,
			referrer: document.referrer,
			title: document.title,
		},
		user,
		cookies,
	};
	gtmEventPublish("page_view", screenData);
};

export const removeKeysFromObject = (obj, keys: Array<string>) => {
	const newObj = {};
	for (const key in obj) {
		if (!keys.includes(key)) {
			newObj[key] = obj[key];
		}
	}
	return newObj;
};

export const isItemOutOfStock = (product: any): boolean => {
	// Grouped products have no quantity, it should always be in stock
	if (product.type_id === "grouped" || product.type_id === "configurable") {
		return false;
	}
	const stockInformation = product.extension_attributes?.stock_information;
	const stockItem = product.extension_attributes?.stock_item;
	let qty = 0;
	if (Array.isArray(stockInformation)) {
		if (stockInformation.length) {
			qty = stockInformation[0]?.quantity;
		}
	} else {
		if (stockInformation) {
			qty = stockInformation[0]?.quantity;
		} else if (stockItem) {
			qty = stockItem[0]?.qty;
		}
	}
	if (Array.isArray(stockItem)) {
		if (stockItem.length) {
			qty = stockItem[0]?.qty;
		}
	} else {
		if (stockInformation) {
			qty = stockInformation[0]?.quantity;
		} else if (stockItem) {
			qty = stockItem[0]?.qty;
		}
	}
	return qty < 1;
};

export const getProductUrl = (product): string => {
	if (product?.extension_attributes?.url_rewrite) {
		return `${routesURL.PRODUCT}/${product?.extension_attributes?.url_rewrite[0].request_path}`;
	}
	return "";};

export const getCategoryUrl = (category): string => {
	if (!category) {
		return routesURL.CATEGORIES;
	}
	const [urlRewrite] = category.url_rewrite;
	if (urlRewrite) {
		return `/${urlRewrite?.request_path}`;
	}
	return routesURL.CATEGORIES;
};

export const userMembershipPrice = (
	groupId: number,
	tierPrices: any[],
): number => {
	if (!groupId || !tierPrices) return null;
	return tierPrices?.find((t) => t?.customer_group_id === groupId)?.value;
};

export const userHasHighMembership = (
	groupId: string,
	group: string,
): boolean => {
	if (!groupId || !group) return false;
	const groups = group?.split(",") ?? [];
	return groups.includes(groupId);
};

export const clearStorages = () => {
	Object.keys(StorageKeys).forEach((key) =>
		removeStorageItem(StorageKeys[key]),
	);
};

export const getPermanentRedirectPath = (): string => {
	// If there is no permanent redirects, return null
	if (!window.permanentRedirects) return null;
	const permaRedirect = window.permanentRedirects[window.location.pathname];
	// If there is a permanent redirect, return the path
	if (permaRedirect) return permaRedirect;
	// Otherwise return null
	return null;
};

export enum ProductFilter {
	CurrentPage = "1",
	PageSize = "12",
	SortBy = "name",
	PriceRange = "",
}

export enum ProductFilterOrders {
	CurrentPage = "1",
	PageSize = "3",
	SortBy = "recommended",
	PriceRange = "",
}

export const debounce = (func: () => void, delay = 500): (() => void) => {
	let timeout;
	const fn = (...args): void => {
		clearTimeout(timeout);
		timeout = setTimeout(() => func(...args), delay);
	};
	return fn;
};

export const _replaceHTMLEntities = (html): string => {
	html = replaceCmsImagePath(html);
	html = html?.replace(/&lt;/g, "<").replace(/&gt;/g, ">");
	const removeStyleRe = /<\s*style.+?<\s*\/\s*style.*?>/is;
	html = html?.replace(removeStyleRe, "");
	const removeComments = /<!--(.*?)-->/is;
	html = html?.replace(removeComments, "");
	return html;
};

export const additionalEmailChecks = (emailAddress) => {
	if (!emailAddress) {
		return true;
	}
	return emailAddress.indexOf("'") == -1;
};

export const getFullClientAddress = (values: {
	street?: string;
	streetName?: string;
	houseNumber?: string;
	houseNumberSuffix?: unknown;
	city?: string;
	postalCode?: string;
}): string => {
	const requiredKeys = ["postalCode", "houseNumber", "city"];

	let isValid = true;
	if (
		values.city !== null &&
		values.postalCode !== null &&
		values.houseNumber !== null
	) {
		requiredKeys?.map((e) => {
			if (values[e] && values[e].length === 0) {
				isValid = false;
			}
		});
	}

	if (!isValid) {
		return "Bezorgadres";
	}
	return `${values?.street || values?.streetName} ${values?.houseNumber}
		${values?.houseNumberSuffix ? " " + values?.houseNumberSuffix : ""}
		${values?.postalCode} ${values?.city}`;
};

export const IBMFont = (): void => {
	const googleApis = document.createElement("link");
	const gStatic = document.createElement("link");
	const font = document.createElement("link");
	googleApis.rel = "preconnect";
	googleApis.href = "//fonts.googleapis.com";
	gStatic.rel = "preconnect";
	gStatic.href = "//fonts.gstatic.com";
	gStatic.crossOrigin = String(true);
	font.href =
		"//fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,400;0,500;0,600;1,400;1,500;1,600&display=swap";
	font.rel = "stylesheet";
	document.body.appendChild(googleApis);
	document.body.appendChild(gStatic);
	document.body.appendChild(font);
};

export const FrankNewFont = (): void => {
	const font = document.createElement("link");
	font.href = "//use.typekit.net/cls2lld.css";
	font.rel = "stylesheet";
	document.body.appendChild(font);
};

export const removeBootstrapShow = (): void => {
	const el = document.getElementById("navbarSupportedContent");
	if (el.classList.contains("show")) {
		el.classList.remove("show");
	}
};

export const getChildrenData = (obj: any): any => {
	const childrenData = [];
	if (obj) {
		for (let i = 0; i < obj.length; i++) {
			childrenData.push(obj[i]);
			if (obj[i].children_data?.length) {
				childrenData.push(...obj[i].children_data);
				for (let p = 0; p < obj[i].children_data.length; p++) {
					if (obj[i].children_data[p].children_data?.length) {
						childrenData.push(
							...obj[i].children_data[p].children_data,
						);
					}
				}
			}
		}
	}
	return childrenData;
};

// Display current year. Mostly used within Footer.tsx
export const currentYear = new Date().getFullYear();

/**
 * Exported function for refactoring PDP. Used only in MainProductInfo.tsx at the moments.
 * @param product_type
 */
export const produceProductType = (
	product_type,
	t,
): { symbol: string; name: string; slug: string } => {
	switch (product_type) {
		case "loan":
			return {
				slug: "loan",
				symbol: t("common.productTypes.loanSymbol"),
				name: t("common.productTypes.loan"),
			};
		case "buy":
			return {
				slug: "buy",
				symbol: t("common.productTypes.buySymbol"),
				name: t("common.productTypes.buy"),
			};
		case "rent":
			return {
				slug: "rent",
				symbol: t("common.productTypes.rentSymbol"),
				name: t("common.productTypes.rent"),
			};
		case "free_rental":
			return {
				slug: "rent",
				symbol: t("common.productTypes.rentSymbol"),
				name: t("common.productTypes.free_rental"),
			};
		default:
			break;
	}
};

export const resultWording = (results: number | string): string => {
	if (results == 1) return `(${results} resultaat)`;
	return `(${results} resultaten)`;
};

export const hasBuyProducts = () => {
	const currentState = store.getState();
	const cartInfo = currentState.cart.cartInfo;
	return cartInfo?.items?.some((item) => item.product_type === "buy");
};

export const getCategoryName = (product): string => {
	const categoryId =
		product?.custom_attributes?.category_ids?.length > 0
			? product?.custom_attributes?.category_ids[0]
			: undefined;
	const categories = JSON.parse(getStorageItem(StorageKeys.CATEGORIES));
	const getCategory =
		categoryId && categories?.find((s) => s.id.toString() === categoryId);
	const categoryName = getCategory ? getCategory.name : "";
	return categoryName || "";
};

export const getAddedCartItem = (previousCartInfo): object => {
	const newCart = JSON.parse(getStorageItem(StorageKeys.CART_INFO));
	const oldCartObject = previousCartInfo;

	if (!newCart) {
		return [];
	}

	if (!oldCartObject) {
		return newCart.items.pop();
	} else if (Object.keys(oldCartObject).length === 0) {
		return newCart.items.pop();
	}

	const oldItems = oldCartObject.items;
	const newItems = newCart.items;
	if (oldItems.length === 0 && newItems.length === 1) {
		return newItems.pop();
	} else {
		if (oldItems.length !== newItems.length) {
			const filtered = newItems.filter(
				({ item_id: id1 }) =>
					!oldItems.some(({ item_id: id2 }) => id2 === id1),
			);
			return filtered[0];
		} else {
			let addedItem = {};
			newItems.map((val, key) => {
				if (val.quantity !== oldItems[key].quantity) {
					val.quantity = val.quantity - oldItems[key].quantity;
					addedItem = val;
				}
			});
			return addedItem;
		}
	}
};

export const transformBuyOptions = (buyOptions): object => {
	const regEx = /[0-9][-]?$/;
	const sku = buyOptions?.sku;
	let newSku;

	if (!regEx.test(sku)) {
		newSku = buyOptions?.variantId;
		return { ...buyOptions, sku: newSku };
	}

	return { ...buyOptions };
};

export const newSku = (sku): string => {
	const skuObj = sku.split("-");
	return skuObj.length > 1 ? skuObj[0] : sku;
};

export const getDefaultAssortiment = (): any => {
	const categories = JSON.parse(getStorageItem("categories"));
	if (categories) {
		const volledigAssortiment = categories?.find((obj) => obj.name === "Volledig Assortiment");
		const { id } = volledigAssortiment;

		return String(id);
	}
	return "";
};

export const handleSKU = (cart): string[] => {
	const regEx = /[0-9][-]?$/;
	const products = cart.items
		?.filter((item) => item.product_type === "loan");
	const altIds = products?.map(product => {
		if (!regEx.test(product.sku)) {
			return product.extension_attributes?.stock_information[0]?.sku;
		} else {
			return product.sku;
		}
	});

	const cleanedStrings = altIds?.map(sku => {
		return sku.split("-")[0];
	});

	return cleanedStrings;
};

export const mostReturnedProducts = [
	"Bed",
	"Douchekruk",
	"Bedverhoger",
	"Rolstoel (met/zonder gipssteun)",
	"Douche-toiletstoel",
	"Matras",
	"Elleboogkruk",
	"Toiletstoel",
	"Bedleestafel",
	"Toiletverhoger",
	"Rugsteun",
	"Ondersteek",
	"Douchestoel",
	"Zitkussen",
	"Rollator",
];

export const allReturnProducts = [
	"Aangepaste stoel",
	"Badplank",
	"Bed",
	"Bedbeugel",
	"Bedcarrier",
	"Bedhekbeschermer",
	"Bedleestafel",
	"Bedverhoger",
	"Bedverlenger",
	"Dekenboog",
	"Douchekruk",
	"Douchestoel",
	"Douche-toiletstoel",
	"Draaischijf",
	"Drempelhulp",
	"Eiffel",
	"Elleboogkruk",
	"Hometrainer",
	"Infuusstandaard",
	"Loopfiets",
	"Looprek",
	"Matras",
	"Okselkruk",
	"Ondersteek",
	"Overzetstoel",
	"Papegaai vrijstaand",
	"Rol-/glijlaken",
	"Rollator",
	"Rolstoel (met/zonder gipssteun)",
	"Rugsteun",
	"Sta op stoel",
	"Sta-/ loopbeugel",
	"Tilband",
	"Tillift",
	"Toiletband",
	"Toiletstoel",
	"Toiletverhoger",
	"Transferplank",
	"Trippelstoel",
	"Windring",
	"Zitkussen",
];

export const convertElasticAttribute = (att) => {
	let newAtt = att;
	switch (att) {
		case "Lend":
			newAtt = "loan";
			break;
		case "Rent":
			newAtt = "rent";
			break;
	}
	return newAtt;
};
export const convertElasticToMagentoProduct = (product) => {
	const newProduct: ProductInterface = {
		product_links: [],
		normalPrice: product.price.raw,
		membershipPrice: product.extra_high_membership_price?.raw,
		specialPrice: product.special_price?.raw,
		id: product.id.raw,
		sku: "",
		name: product.name.raw,
		price: parseFloat(product.price.raw),
		button: 0,
		media_gallery_entries: [
			{ id: 1, url: product.gallery.raw, file: product.gallery.raw },
		],
		imageUrl: null,
		imageUrls: null,
		extension_attributes: {
			url_rewrite: [
				{
					request_path: product.url_key.raw,
					id: "",
					target_path: product.url_key.raw,
					entity_type: product.url_key.raw,
				},
			],
			configurable_product_options: null,
			stock_item: [{ qty: product.qty?.raw || 0 }],
			stock_information: null,
			category_links: null,
		},
		product_type: convertElasticAttribute(product.attribute_set.raw),
		orderType: product.attribute_set.raw,
		type_id: product?.type_id?.raw,
		rent_sku_id: product.id.raw,
		loan_sku_id: product.id.raw,
		buy_sku_id: product.id.raw,
		free_rent_sku_id: product.id.raw,
		groupedProducts: null,
		configurable_attributes: null,
		custom_attributes: {
			special_price: product.special_price?.raw,
			price_configurable: product.price_configurable?.raw,
			category_ids: product.category_ids?.raw ?? []
		}
	};

	if (product.price_configurable?.raw) {
		newProduct.type_id = "configurable";
	}

	return newProduct;
};
export const getDeliveryRadioOptions = (values, noDelivery) => {
	if (noDelivery) {
		return [
			{
				label: "Meegeven",
				value: "HANDOVER",
				className: `col-lg-9 col-24 padded-radio ${values.deliveryType === "HANDOVER" ? "active" : ""
					}`,
			},
		];
	}
	return [
		{
			label: "Meegeven",
			value: "HANDOVER",
			className: `col-lg-9 col-24 padded-radio ${values.deliveryType === "HANDOVER" ? "active" : ""
				}`,
		},
		{
			label: "Bezorgen",
			value: "DELIVER",
			className: `col-lg-9 col-24 padded-radio ${values.deliveryType === "DELIVER" ? "active" : ""
				}`,
		},
	];
};

export const dateHolidays = (date: Date, holidays: string[]) => {
	//Check if the picked date is a holiday
	const foundDays = holidays.find(
		(day) => day == moment(date).format("DD-MM-YYYY"),
	);
	return foundDays ? false : true;
};

export const excludeWeekends = (date: Date) => {
	const day = date.getDay();
	return day !== 0 && day !== 6;
};

export const weekendDay = (date: Date) => {
	return date.getDay();
};

export const replaceEmbolden = (string, queryToTest): any => {
	if (string) {
		const newString = string.replace(/<\/?[^>]+(>|$)/g, "");
		const re = queryToTest.replace(/\\/g, "\\\\");
		return newString.replace(re, "<strong>" + queryToTest + "</strong>");
	}
};

export const getSituation = (label) => {
	const situations = {
		risk_of_infection: "Risico op infectie",
		apartment_with_elevator: "Appartement met lift",
		gravel_path: "Grind pad",
		hotel_camping_park: "Hotel / Camping / Vakantiepark",
		client_cannot_open: "Client kan deur niet openen",
		parking_problem: "Parkeerproblemen",
		stairway: "Trappenhuis",
		nursing_home: "Verzorgingstehuis",
		other: "Anders",
		none: "Geen bijzonderheden"
	};

	if (situations[label] !== undefined) {
		return situations[label];
	}

	return "";
};

export const addSellTypeToSession = (id, type) => {
	const types = JSON.parse(getStorageItem(StorageKeys.ECOMMERCE)) || [];
	types.push({ id: id, type: type });
	setStorageItem(StorageKeys.ECOMMERCE, JSON.stringify(types));
};

export const GetEcommerceData = (eCommerceProducts: any, eCommerceData?: any, categoryData?: any, type?: string, userMail?: string) => {
	const totalPrice = (item, type) => {
		let accumulator = 0;
		let containsLoan = false;
		item.map((product) => {
			if (type === "buy") {
				if (product?.product_type === "buy") {
					if (product.type_id === "configurable") {
						if (parseFloat(product?.custom_attributes?.special_price) > 0) {
							accumulator += product.quantity ? parseFloat(product.price) * product.quantity : parseFloat(product.price);
						} else {
							accumulator += product.quantity ?
								parseFloat(product.price) * product.quantity :
								parseFloat(product.price);
						}
					} else if (product.type_id === "simple") {
						if ((product?.custom_attributes?.special_price * 1) > 0) {
							accumulator += product.quantity ?
								parseFloat(product.custom_attributes?.special_price) * product.quantity :
								parseFloat(product.custom_attributes?.special_price);
						} else {
							accumulator += product.quantity
								? parseFloat(product.price) * product.quantity
								: parseFloat(product.price);
						}
					}
				}
			} else if (type === "rent") {
				if (product?.product_type === "rent") {
					accumulator += parseFloat(product?.custom_attributes?.rent_price);
				}
			} else if (type === "lend") {
				if (product?.product_type === "loan") {
					containsLoan = true;
				}
			} else if (type === "free_rental") {
				if (product?.product_type === "free_rental") {
					containsLoan = true;
				}
			}
		});
		return (accumulator === 0 && containsLoan === false) ? null : accumulator;
	};

	const itemPriceCalculator = (item) => {
		if (item?.product_type === "buy" && item?.type_id === "simple") {
			if ((item?.custom_attributes?.special_price * 1) > 0 && type !== "view_item") {
				return parseFloat(item?.custom_attributes?.special_price);
			} else {
				return parseFloat(item?.price);
			}
		} else if (item?.product_type === "rent") {
			return parseFloat(item?.custom_attributes?.rent_price);
		} else if (item?.product_type === "free_rental") {
			return 0;
		} else if (item?.product_type === "loan") {
			return 0;
		} else {
			if (item?.type_id === "configurable") {
				if (item?.custom_attributes?.special_price * 1 > 0) {
					return parseFloat(item?.price);
				} else {
					return parseFloat(
						item?.price,
					);
				}
			}
			return parseFloat(item?.price);
		}
	};
	const getItemText = (itemID) => {
		switch (itemID) {
			case "category":
				return "";
			case "upsell":
				return "Anderen bekeken ook";
			case "crosssell":
				return "Anderen bestelden ook";
		}
		return itemID;
	};

	const getQuantity = (product) => {
		if (product?.quantity) return product.quantity;
		return bedverhogers.indexOf(product?.sku) > -1 ? 4 : 1;
	};

	const types = JSON.parse(getStorageItem(StorageKeys.ECOMMERCE));
	eCommerceData?.cartId && fetchTotal(eCommerceData);

	const object = {
		ecommerce: {
			items: eCommerceProducts.map((product, index) => {
				fetchTotal(eCommerceData);
				let specialPrice = 0;
				if (product?.custom_attributes?.special_price > 0) {
					if (product.type_id === "configurable") {
						specialPrice =
							parseFloat(
								product?.custom_attributes?.special_price,
							) -
							parseFloat(
								product?.custom_attributes?.price_configurable,
							);
					} else {
						specialPrice =
							parseFloat(
								product?.custom_attributes?.special_price,
							) - parseFloat(product?.price);
					}
				} else if (
					product?.custom_attributes?.extra_high_membership_price > 0
				) {
					if (product?.type_id === "configurable") {
						specialPrice =
							parseFloat(
								product.custom_attributes
									?.extra_high_membership_price,
							) -
							parseFloat(
								product?.custom_attributes.price_configurable,
							);
					} else {
						specialPrice =
							parseFloat(
								product.custom_attributes
									?.extra_high_membership_price,
							) - parseFloat(product?.price);
					}
				}
				// // @ts-ignore
				const itemObject = ({
					item_id: product?.options?.length > 0 ? product?.sku + "-" + product?.options[0]?.value : product?.sku,
					item_name: product?.options?.length > 0 ? product?.name + "-" + product?.options[0]?.value : product?.name,
					coupon: getStorageItem(StorageKeys.COUPON_CODE) || "",
					currency: "EUR",
					discount: type === "view_item" ? specialPrice < 0 ? (specialPrice * -1) : specialPrice : 0,
					index: index,
					item_variant: product?.options ? product?.options[0]?.value || "" : "",
					price: itemPriceCalculator(product),
					quantity: getQuantity(product)
				});
				itemObject["item_category"] = product?.product_type;
				let counter = 2;
				product?.custom_attributes?.category_ids?.reverse().map((category, index) => {
					if (index <= 5) {
						const name = categoryData?.categories?.filter((c) => c.id.toString() === category && c.parent_id !== 2)[0]?.name;
						if (name) {
							itemObject["item_category" + counter] = name;
							counter++;
						}
					}
				});
				return itemObject;
			}),
		},
	} as any;
	if (type !== "view_item_list") {
		object.ecommerce.buy_products_value = totalPrice(eCommerceProducts, "buy");
		object.ecommerce.lend_products_value = totalPrice(eCommerceProducts, "lend");
		object.ecommerce.rent_products_value = totalPrice(eCommerceProducts, "rent");
		object.ecommerce.free_rental_products_value = totalPrice(eCommerceProducts, "free_rental");
	}
	if (type === "purchase") {
		// add ecommerce values
		object.ecommerce.transaction_id = getStorageItem(StorageKeys.ORDER_ID) || 0;
		object.ecommerce.coupon = getStorageItem(StorageKeys.COUPON_CODE) || "";
		object.ecommerce.shipping = Number(getStorageItem(StorageKeys.SHIPPING)) || 0;
		object.ecommerce.tax = Number(getStorageItem(StorageKeys.TAX)) || 0;

		// add user mail
		object.user = { email: userMail };
	}
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	if (!(type === "view_item_list" || type === "select_item")) {
		object.ecommerce.currency = "EUR";
		object.ecommerce.value =
			object.ecommerce.buy_products_value + object.ecommerce.shipping +
				object.ecommerce.rent_products_value >
				0
				? object.ecommerce.buy_products_value +
				object.ecommerce.rent_products_value
				: 0;
	}

	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	if (type === "view_item_list" || type === "select_item") {
		object.ecommerce.items.map((product) => {
			const itemType = types
				? types.find((x) => x.id === product.sku)
				: null;
			product.item_list_id =
				(itemType && itemType.type) ??
				"category" +
				(categoryData?.currentCategory && categoryData?.currentCategory.length > 0 && categoryData?.currentCategory[0]
					? "_" + categoryData?.currentCategory[0].id
					: "");
			product.item_list_name = getItemText(
				(itemType && itemType.type) ??
				(categoryData?.currentCategory[0]?.name || ""),
			);
		});
		object.ecommerce.item_list_id =
			"category" +
			(categoryData?.currentCategory.length > 0 && categoryData?.currentCategory[0]
				? "_" + categoryData?.currentCategory[0].id
				: "");
		object.ecommerce.item_list_name = getItemText(
			categoryData?.currentCategory[0]?.name || "",
		);
	}
	return object;
};

export const dispatchAction = (action) => {
	store.dispatch(action);
};

export const getDeliveryAddress = (order) => {
	const address = order.deliveryInfo?.deviatingAddress ?? order.deliveryInfo?.clientAddress;
	const deliveryAddress = {
		...address,
		...order.deliveryInfo?.clientInformation,
	};
	return deliveryAddress;
};

export const convertGender = (gender: string) => {
	const genderMap = {
		M: "MALE",
		V: "FEMALE"
	};

	// Check if gender is in genderMap, else just return the gender
	return genderMap?.[gender] || gender;
};

export const getCategoryPath = (categoryId?): Array<any> => {
	if (!JSON.parse(getStorageItem(StorageKeys.CATEGORIES))) {
		getCategories();
	}
	const allCategories = getChildrenData(JSON.parse(getStorageItem(StorageKeys.CATEGORIES)));
	// HARDCODED TO 1 AS ITS NOT NEEDED FOR PURCHASE EVENTS
	// TODO: Make plan with Medux to move ecommerce data to store
	const selectedId = categoryId ? categoryId : ["1"];
	const catId = selectedId && selectedId[0];
	const getCategory = allCategories?.filter(
		(c) => c?.id?.toString() === catId?.toString(),
	);
	return [...getCategory] || [];
};

/**
 * Get category data by request path
 */
export const getCategoryByPath = (path) => {
	if (!JSON.parse(getStorageItem(StorageKeys.CATEGORIES))) {
		getCategories();
	}
	const allCategories = getChildrenData(JSON.parse(getStorageItem(StorageKeys.CATEGORIES)));
	path = path.startsWith('/') ? path.substring(1) : path;

	return allCategories.find(obj =>
		obj.url_rewrite.some(item => item.request_path === path)
	);
};

export const ecommerceCategories = () => {
	const categoryPath = getCategoryPath();
	let allCategories = [];
	if (JSON.parse(getStorageItem(StorageKeys.CATEGORIES))?.length > 0) {
		allCategories = getChildrenData(JSON.parse(getStorageItem(StorageKeys.CATEGORIES)));
	}

	const categoryObject = {
		currentCategory: categoryPath && categoryPath,
		categories: allCategories && allCategories,
	};
	return categoryObject;
};

export const translateProductTypeEmail = (productType: string) => {
	// Translate the product type to fit it in the correct category in the email
	switch (productType) {
		case "loan":
			return "Gratis lenen";
		case "rent":
			return "Huur";
		case "buy":
			return "Kopen";
		case "free_rental":
			return "Huur";
		default:
			return productType;
	}
};

export const sortItems = [
	{
		label: "Aanbevolen",
		value: "recommended",
		sortBy: "recommended",
	},
	{
		label: "a-z productnaam",
		value: "nameASC",
		sortBy: "name",
		direction: "ASC",
	},
	{
		label: "z-a productnaam",
		value: "nameDESC",
		sortBy: "name",
		direction: "DESC",
	},
	{
		label: "Prijs Hoog-Laag",
		value: "priceDESC",
		sortBy: "price",
		direction: "DESC",
	},
	{
		label: "Prijs Laag-Hoog",
		value: "priceASC",
		sortBy: "price",
		direction: "ASC",
	},
];

export const isListPage = () => !location.pathname.includes("zoekresultaten");

export const firstLetterToUppercase = (string) => {
	return string.charAt(0).toUpperCase() + string.slice(1) || "";
};

 /**
 * This is the code for the breadcrumbs,
 * I did not want to refactor this because it is a mess and i dont know how its supposed to work.
 */
 export const getProductCategories = (product, categories): any | string => {
	const categoryIDs =
		product?.custom_attributes?.category_ids?.length > 0
			? product?.custom_attributes?.category_ids
			: undefined;

	const filteredCategory =
		categoryIDs &&
		categories?.filter(
			(c) =>
				categoryIDs.includes(c.id.toString()) &&
				c.name !== "Volledig Assortiment",
		);
	const filteredMainWithChildren =
		(filteredCategory?.length > 0 &&
			filteredCategory?.filter((category) => {
				if (category?.children_data?.length > 0) {
					const test = category?.children_data?.filter((sub) =>
						categoryIDs.includes(sub.id.toString()),
					);
					if (test.length > 0) {
						return category;
					}
				}
				return;
			})) ||
		undefined;

	if (!filteredCategory && !filteredMainWithChildren) {
		return [];
	}
	const mainCategory =
		filteredMainWithChildren?.length > 0
			? filteredMainWithChildren[0]
			: filteredCategory[0];
	const mainCategoryChildData =
		(filteredMainWithChildren &&
			filteredMainWithChildren[0]?.children_data) ||
		filteredMainWithChildren?.children_data;
	const subCategory =
		(mainCategoryChildData &&
			mainCategoryChildData.filter((item) =>
				categoryIDs.includes(item.id.toString()),
			)) ||
		{};
	const subSubCategory =
		(subCategory?.length > 0 &&
			subCategory[0]?.children_data?.filter((item) =>
				categoryIDs.includes(item.id.toString()),
			)) ||
		{};
	return [mainCategory, subCategory[0], subSubCategory[0]] || "";
};

/**
 * Checks if the cart contains items that are from the category "loophulp"
 * Is important to decide whether the rental shipping costs will be 7,50 or 25,00
 * Does contain loophulp -> 7,50 shipment for (free)rent products
 * Does not contain loophulp -> 25,00 shipment for (free)rent products
 * @param cartItems
 */
export const doesCartContainLoophulp = (cartItems: Array<{
	custom_attributes: {
		category_ids: string[]
	},
	product_type: string,
}>) => {
	 const currentState = store.getState();
	 const loophulpCategoryID = currentState.category.categories.find((cat) => cat.name.toLowerCase() === "loophulpmiddelen")?.id || "0";
	 let doesNotContain = false;
	 cartItems?.forEach((item) => {
		 if (!item.custom_attributes.category_ids.includes(loophulpCategoryID.toString()) &&
			 (item.product_type === "rent" || item.product_type === "free_rental")) {
			 doesNotContain = true;
		 }
		 });
	return !doesNotContain;
 };

//  Retrieves all pricing info necessary to show respective prices on the product detail page
export const getPricingInfo = (product: any) => {
	const {
		price,
		custom_attributes: {
			extra_high_membership_price_customer_groups = null,
			extra_high_membership_price = null,
			rent_member_price = null,
		},
		product_type = "",
		tier_prices = [],
	} = product;

	const isBuy = product_type === "buy";
	const isRent = product_type === "rent";
	const userInfo = getLoggedInUserInfo();
	const specialPrice = productSpecialPrice(product);

	const hasHighMembership = userHasHighMembership(
		String(userInfo?.groupId),
		extra_high_membership_price_customer_groups,
	);
	const highMembershipPrice = extra_high_membership_price || 0;

	let membershipPrice = 0;

	if (!hasHighMembership && !highMembershipPrice && !specialPrice) {
		membershipPrice =
			(isBuy && userMembershipPrice(userInfo?.groupId, tier_prices)) ||
			(isRent && rent_member_price) ||
			0;
	}

	const memberPrice = isBuy
		? membershipPrice || highMembershipPrice
		: isRent && membershipPrice;

	return {
		price: price,
		membershipPrice: memberPrice,
		specialPrice: specialPrice,
		highMembershipPrice,
		isRent,
		isBuy,
		memberPrice
	};
};

export const scrollToSection = (anchorTarget) => {
	// Find the anchortag from the DOM and scroll to it
	const section = document.getElementById(anchorTarget);
	if (section) {
		section.scrollIntoView({ behavior: 'smooth' });
	}
};
