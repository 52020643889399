import React, { useEffect, useState } from 'react';
import { ProductInterface } from "../../../Pages/Catalog/catalog-interfaces";
import { checkAssociationPDP, fetchAllAssociations } from "../../../../common/actions/membership";
import MembershipModal from "../../MembershipModal/MembershipModal";
import {
    addSellTypeToSession,
    GetEcommerceData,
    getProductUrl,
    gtmEventPublish,
    isBusiness,
    isItemOutOfStock, isPartner
} from "../../../../common/common";
import { connect, ConnectedProps } from "react-redux";
import { useToaster } from "../../Toaster";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import * as cartActions from "../../../../common/actions/cart";
import * as productActions from "../../../../common/actions/product";
import * as datalayerActions from "../../../../common/store/types/datalayerTypes";
import bedverhogers from "../../../../common/data/bedverhogers.json";

interface TileProps {
    tileProduct: ProductInterface;
    type: "list" | "crossSell" | "upsell";
    isEveanAD: boolean;
    closeCartModal: (boolean)=> void;
}

const mapStateToProps = (state: any) => {
    return {
        ...state.product,
    };
};

const connector = connect(mapStateToProps, {
    fetchAllAssociations,
    ...cartActions,
    ...datalayerActions,
    ...productActions,
});

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & TileProps;

const ProductTileButtons = (props: Props) => {
    // This component will return the correct button depending on the location and product properties
    const {
        tileProduct,
        type,
        addToCart,
        addGroupedToCart,
        isEveanAD,
        fetchAllAssociations,
        closeCartModal
    } = props;

    const [membershipModal, setMembershipModal] = useState(false);
    const [accepted, setAccepted] = useState(false);
    const [associations, setAssociations] = useState([]);
    const [isShopOnly, setIsShopOnly] = useState<boolean>(false);
    const [addedToCart, setAddedToCart] = useState(false);
    const { addToast } = useToaster();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const isOutOfStock = isItemOutOfStock(tileProduct) && !isPartner();
    const isBedVerhogers = bedverhogers.indexOf(tileProduct.sku) > -1;

    useEffect(() => {
        // Check if product is only available in a shop
        if (!isBusiness()){
            // Configurable products
            if (tileProduct?.custom_attributes?.shop_only && tileProduct?.custom_attributes?.shop_only === "1") {
                setIsShopOnly(true);
            } else {
                setIsShopOnly(false);
            }
        }
    }, [tileProduct]);

    const fetchAssociations = async (): Promise<void> => {
        try {
            const result = await fetchAllAssociations();
            setAssociations(
                result.data.membership_associations.map((asso) => ({
                    label: asso.name,
                    value: asso.magento_id,
                })),
            );
        } catch (error) {
            addToast({
                type: "error",
                content: <p className="m-0">{t("form.error.server")}</p>,
            });
            setAssociations([]);
        }
    };

    const getGroupedData = async () => {
        try {
            // Fetch product detail
            const response = await props.getProductDetailBySKU({ sku: tileProduct.sku });

            // Extract groupedData from the response and return it
            const groupedData = response.groupedProducts.map((product) => ({
                sku: product.sku,
                quantity: product.groupedDefaultQty,
            }));

            return groupedData;
        } catch (error) {
            // Handle errors if getProductDetailBySKU fails
            console.error("Error fetching product detail:", error);
            throw error; // Optionally rethrow the error for handling outside of this function
        }
    };

    const businessButtons = () => {
        // Return CTA button on product tile grid view depending on product attributes
        if (tileProduct?.custom_attributes?.delivery_type === "Thuisservice") {
            // If product is Thuisservice
            return (
                <button
                    type="button"
                    onClick={(): void =>
                        navigate(getProductUrl(tileProduct))
                    }
                    className="btn btn-default text-truncate"
                >
                    {t("common.buttons.thuisServiceButton")}
                </button>
            );
        }else if (tileProduct.type_id === "configurable" && !isShopOnly) {
            // If product is configurable
            return (
                <button
                    type="button"
                    onClick={(): void =>
                        navigate(getProductUrl(tileProduct))
                    }
                    className="btn btn-default text-truncate"
                >
                    {t("common.buttons.configurableButton")}
                </button>
            );
        }else if ((tileProduct.type_id === "simple" || tileProduct.type_id === "grouped") && !isOutOfStock && !isEveanAD){
            // If product is grouped or simple, not out of stock and not AD/Evean
            return (
                <button
                    type="button"
                    onClick={async () => {
                        if (tileProduct.product_type === "free_rental"){
                            if (!accepted){
                                await fetchAssociations().then(()=>{
                                        setMembershipModal(true);
                                    }
                                );

                            }
                        }else if (tileProduct.type_id === "simple") {
                            addToCart({
                                sku: tileProduct.sku,
                                quantity: isBedVerhogers ? 4 : 1,
                            });
                        } else if (tileProduct.type_id === "grouped") {
                            const payload = await getGroupedData();
                            addGroupedToCart(payload);
                        }
                        setAddedToCart(true);
                        if (window.dataLayer) {
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            window.dataLayer.push({
                                ecommerce: null,
                            });
                        }
                        gtmEventPublish(
                            "add_to_cart",
                            GetEcommerceData(
                                [props?.product],
                                [],
                                [],
                            ),
                        );
                    }}
                    disabled={addedToCart}
                    className="btn btn-default text-truncate"
                >
                    {addedToCart ? "Toegevoegd" : t("common.addToCartBtnLabel")}
                </button>
            );
        }else {
            // Display regular more information button
            return (
                <button
                    type="button"
                    onClick={(): void =>
                        navigate(
                            getProductUrl(tileProduct) +
                            (type === "upsell" ? "?upSell=true" : ""),
                        )
                    }
                    className="btn btn-default text-truncate"
                >
                    {t("common.productMoreInfo")}
                </button>
            );
        }
    };

    return (
        <>
            {(type === "list" || type === "upsell") ?
                (
                    isBusiness() ? (
                        // Business CTA buttons
                        businessButtons()
                    ) : (
                        <button
                            type="button"
                            onClick={(): void =>
                                navigate(
                                    getProductUrl(tileProduct) +
                                    (type === "upsell" ? "?upSell=true" : ""),
                                )
                            }
                            className="btn btn-default text-truncate"
                        >
                            {t("common.productMoreInfo")}
                        </button>
                    )
                ) : (
                    <button
                        type="button"
                        onClick={() => {
                            if (tileProduct.type_id === "configurable") {
                                closeCartModal(false);
                                navigate(
                                    getProductUrl(tileProduct) +
                                    (type === "upsell" ? "?upSell=true" : ""),
                                );
                            }else {
                                props.addToCart({
                                    sku: tileProduct.sku,
                                    quantity: isBedVerhogers ? 4 : 1,
                                });
                                setAddedToCart(true);
                                if (window.dataLayer) {
                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                    // @ts-ignore
                                    window.dataLayer.push({
                                        ecommerce: null,
                                    });
                                }
                                addSellTypeToSession(
                                    props?.product.sku,
                                    "crosssell",
                                );

                                gtmEventPublish(
                                    "add_to_cart",
                                    GetEcommerceData(
                                        [props?.product],
                                        [],
                                        [],
                                    ),
                                );
                            }
                        }}
                        disabled={addedToCart}
                        className={
                            addedToCart
                                ? "btn btn-primary text-truncate cross-sell added"
                                : "btn btn-primary text-truncate cross-sell"
                        }
                    >
                        {addedToCart
                            ? t("common.addedToCartCrossSellLabel")
                            : t("common.addToCartCrossSellLabel")}
                    </button>
                )
            }
            <MembershipModal
                show={membershipModal}
                setShow={setMembershipModal}
                setAccepted={setAccepted}
                associations={associations}
                handleAssocCheck={checkAssociationPDP}
                product={tileProduct?.sku}
                isPLP
            />
        </>
    );
};

export default connector(ProductTileButtons);
