import React, { ReactElement } from "react";
import { LoaderContext } from "../../../common/contexts";

const Loader = (): ReactElement => {
	return (
		<LoaderContext.Consumer>
			{({ loading }): ReactElement => {
				return (
					<div className={loading ? "loader loading" : "loader"}>
						<div className="spinner-border" role="status"></div>
					</div>
				);
			}}
		</LoaderContext.Consumer>
	);
};

export const withLoader = (Component: any): React.FC => {
	const WrappedComponent = (props): ReactElement => (
		<LoaderContext.Consumer>
			{(context): ReactElement => {
				return <Component {...props} context={context} />;
			}}
		</LoaderContext.Consumer>
	);

	Object.defineProperty(WrappedComponent, "displayName", {
		value: `withLoader(${Component.displayName || Component.name})`,
		configurable: true,
	});

	return WrappedComponent;
};

export interface LoaderHOCInterface {
	context: {
		loading: boolean;
		updateLoader: (flag: boolean) => null;
	};
}

export default Loader;
