import React, { ReactElement, Fragment } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";
// import { ProductInterface } from "../../../Pages/Catalog/catalog-interfaces";

const PriceTooltip = ({ product }: { product: any }): ReactElement => {
	let tooltip = "";
	const { t } = useTranslation();
	if (product.sku === product.free_rent_sku_id) {
		tooltip = "product_detail.tooltip.freeRental";
	} else if (product.sku === product.loan_sku_id) {
		tooltip = "product_detail.tooltip.loan";
	} else if (product.sku === product.rent_sku_id) {
		tooltip = "product_detail.tooltip.rent";
	}
	if (product === "my order") {
		tooltip =
			"Dit is het bedrag dat je online bij jouw bestelling hebt afgerekend. " +
			"Leenproducten worden volledig vergoed door jouw verzekeraar en huurproducten " +
			"worden achteraf per maandelijkse factuur betaald.";
	}
	if (tooltip) {
		return (
			<OverlayTrigger
				placement="auto"
				overlay={
					<Tooltip style={{ zIndex: 100000 }} id="tooltip-disabled">
						{t(tooltip)}
					</Tooltip>
				}
			>
				<em className="icon icon-sm info-circle-blue-16 ms-2"></em>
			</OverlayTrigger>
		);
	}
	return <Fragment />;
};

export default PriceTooltip;
