import { nodeAPI } from "../services/abstract.service";
import { Dispatch } from "redux";
import {
	getLoggedInUserInfo,
	getStorageItem,
	isBusiness,
	removeKeysFromObject,
	removeStorageItem,
	setStorageItem,
	StorageKeys,
} from "../common";
import ActionTypes, { ADD_ECOMMERCE_ITEMS } from "../store/types/actionTypes";
import { ObjectProps } from "../interfaces/common.interfaces";
import { updateStep } from "../store/actions/checkoutActions";
import { store } from "../store";

export const fetchTotal = (cartInfo: any) => {
	return async (dispatch: Dispatch): Promise<void> => {
		try {
			const result = await nodeAPI.get(`/cart/${cartInfo.cartId}/totals`);
			/*
			  This is a hack to show the product price inclusive tax since
			  the cart api doesn't return inclusive tax price for the product.
			  This code is subjected to revise in future once
			  the cart api gets updated with product inclusive tax price.
			  ------------------------------------------------------
									START
			  ------------------------------------------------------
			*/
			cartInfo?.items?.forEach((cartItem: any, index: number) => {
				const item = result?.data?.items[index];
				if (item && item?.item_id === cartItem?.item_id) {
					cartItem.price = item?.price_incl_tax;
				}
			});

			dispatch({
				type: ActionTypes.CART_LOAD_SUCCESS,
				payload: {
					cartInfo: cartInfo,
				},
			});
			dispatch({
				type: ActionTypes.UPDATE_CART_TOTAL,
				payload: {
					totals: result.data,
				},
			});
			const currentState = store.getState();
			dispatch({
				type: ADD_ECOMMERCE_ITEMS,
				payload: {
					cartInfo: currentState.cart,
					categories: currentState.category.categories
				}
			});
			/*
			------------------------------------------------------
									END
			------------------------------------------------------
			*/
			return Promise.resolve();
		} catch (e) {
			return Promise.reject(e);
		}
	};
};

const updateInfo = async (dispatch, cartInfo): Promise<void> => {
	setStorageItem(StorageKeys.CART_INFO, JSON.stringify(cartInfo));

	dispatch({
		type: ActionTypes.CART_LOAD_SUCCESS,
		payload: {
			cartInfo,
		},
	});
	cartInfo?.cartId && dispatch(fetchTotal(cartInfo));
};

export const addToCart = (payload: any) => {
	return async (dispatch: Dispatch): Promise<void> => {
		const newPayload = { ...payload };
		const { data } = await nodeAPI.post("/cart", {
			...removeKeysFromObject(payload, ["variantId", "variantName"]),
			cartId: getStorageItem(StorageKeys.CARTID) || undefined,
		});
		const { variantId, variantName } = newPayload;
		const cartInfo = { ...data };
		const itemIndex = cartInfo.items.findIndex(
			(s) => s.sku === payload.sku,
		);
		cartInfo.items[itemIndex] = {
			...cartInfo.items[itemIndex],
			variantId,
			variantName,
		};

		cartInfo?.items?.forEach((cartItem: any, index: number) => {
			const item = data?.data?.items[index];
			if (item && item?.item_id === cartItem?.item_id) {
				cartItem.price = item.price_incl_tax;
			}
		});
		// Reset the step to make sure the effects the product has on the checkout information is respected
		dispatch<any>(updateStep(0, [], isBusiness() ? "business" : "consumer"));
		setStorageItem(StorageKeys.CARTID, cartInfo.cartId);
		return new Promise((resolve, _reject) => {
			updateInfo(dispatch, cartInfo);
			resolve(cartInfo);
		});
	};
};

export const addGroupedToCart = (payload: any) => {
	return async (dispatch: Dispatch): Promise<void> => {
		const newPayload = payload.filter((e) => e.quantity !== 0);
		const { data } = await nodeAPI.post("/cart/multiple", {
			cartId: getStorageItem(StorageKeys.CARTID) || undefined,
			products: [...newPayload],
		});
		const cartInfo = { ...data };
		setStorageItem(StorageKeys.CARTID, cartInfo.cartId);
		// Reset the step to make sure the effects the product has on the checkout information is respected
		dispatch<any>(updateStep(0, [], isBusiness() ? "business" : "consumer"));
		return new Promise((resolve, _reject) => {
			updateInfo(dispatch, cartInfo);
			resolve(cartInfo);
		});
	};
};

export const getItemFromCart = (itemId: number) => {
	const cartData = getStorageItem(StorageKeys.CART_INFO);
	const selItem = (JSON.parse(cartData)?.items as any)?.find(
		(s) => s.item_id === itemId,
	);
	return selItem;
};

export const removeItemFromCart = ({ itemId }) => {
	return async (dispatch: Dispatch): Promise<void> => {
		const currentState = store.getState();
		const cartId = currentState.cart?.cartInfo?.cartId || undefined;
		const { data } = await nodeAPI.delete(
			`/cart/${cartId}/items/${itemId}`,
		);
		const cartInfo = data;
		// Reset the step to make sure the effects the product has on the checkout information is respected
		dispatch<any>(updateStep(0, [], isBusiness() ? "business" : "consumer"));
		return new Promise((resolve) => {
			updateInfo(dispatch, cartInfo);
			dispatch({
				type: ActionTypes.CART_DELETE_SUCCESS,
			});
			resolve(cartInfo);
		});
	};
};

export const getCart = () => {
	return async (dispatch: Dispatch): Promise<void> => {
		const currentState = store.getState();
		const cartId = currentState.cart?.cartInfo?.cartId || undefined;
		const user = getLoggedInUserInfo();
		const cartUrl = user ? "/cart/mine" : `/cart/${cartId}`;
		if (cartId || user) {
			if (window.location.pathname !== "/checkout") {
				dispatch({
					type: ActionTypes.CART_LOAD_PENDING,
					payload: { cartInfo: {}, isPending: true },
				});
			}
			try {
				const { data } = await nodeAPI.get(cartUrl);
				const cartInfo = data;
				updateInfo(dispatch, cartInfo);
			} catch (e) {
				removeStorageItem(StorageKeys.CARTID);
				removeStorageItem(StorageKeys.CART_INFO);
				dispatch({
					type: ActionTypes.CART_LOAD_ERROR,
					payload: { cartInfo: {}, isPending: true },
				});
			}
		}
	};
};

export const updatePreviousCart = (previousCartInfo) => {
	return async (dispatch: Dispatch): Promise<void> => {
		// Reset the step to make sure the effects the product has on the checkout information is respected
		dispatch<any>(updateStep(0, [], isBusiness() ? "business" : "consumer"));
		dispatch({
			type: ActionTypes.UPDATE_PREVIOUS_CART,
			payload: {
				previousCartInfo,
			},
		});
	};
};

export const updateCart = ({ itemId, qty }) => {
	return async (dispatch: Dispatch): Promise<ObjectProps<boolean>> => {
		try {
			const cartId = getStorageItem(StorageKeys.CARTID) || undefined;
			const { data } = await nodeAPI.put(
				`/cart/${cartId}/items/${itemId}`,
				{
					qty,
				},
			);
			const cartInfo = data;
			updateInfo(dispatch, cartInfo);
			// Reset the step to make sure the effects the product has on the checkout information is respected
			dispatch<any>(updateStep(0, [], isBusiness() ? "business" : "consumer"));
			return { success: true };
		} catch (error) {
			return { success: false };
		}
	};
};

export const emptyCart = () => {
	return async (dispatch: Dispatch): Promise<boolean> => {
		const cartId = getStorageItem(StorageKeys.CARTID) || undefined;
		const cartUrl = cartId ? `/cart/${cartId}` : "/cart/mine";
		const { data } = await nodeAPI.delete(cartUrl);
		const cartInfo = data;

		// Reset the step to make sure the effects the product has on the checkout information is respected
		dispatch<any>(updateStep(0, [], isBusiness() ? "business" : "consumer"));
		return new Promise((resolve, _reject) => {
			updateInfo(dispatch, cartInfo);
			resolve(cartInfo);
		});
	};
};

export const assignCartToCustomer = ({ customerId }) => {
	return async (dispatch: Dispatch): Promise<void> => {
		const cartId = getStorageItem(StorageKeys.CARTID) || undefined;
		const { data } = await nodeAPI.put(`/cart/${cartId}`, {
			customerId,
		});
		const cartInfo = data;
		updateInfo(dispatch, cartInfo);
		setStorageItem(StorageKeys.CARTID, cartInfo.cartId);
	};
};

export const clearCart = () => {
	return async (dispatch: Dispatch): Promise<void> => {
		// Reset the step to make sure the effects the product has on the checkout information is respected
		dispatch<any>(updateStep(0, [], isBusiness() ? "business" : "consumer"));
		return new Promise((resolve) => {
			const cartInfo = {};
			removeStorageItem(StorageKeys.CARTID);
			removeStorageItem(StorageKeys.CART_INFO);
			removeStorageItem(StorageKeys.ECOMMERCE);
			updateInfo(dispatch, cartInfo);
			resolve();
		});
	};
};
