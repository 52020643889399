import ActionTypes from "../store/types/actionTypes";

const initialState = {
	buttonStatus: false,
};

const checkoutReducer = (state = initialState, action: any): any => {
	switch (action.type) {
		case ActionTypes.ADD_VECOZO_DETAILS: {
			return {
				...state,
				vecozoDetails: action.payload,
			};
		}
		case ActionTypes.PAYMENT_COUPON_SUCCESS:
			return {
				...state,
				payment: action.payload.payment,
			};
		case ActionTypes.SET_BUTTON_DISABLE:
			return {
				...state,
				buttonStatus: action.payload,
			};
		default:
			return state;
	}
};
export default checkoutReducer;
