import React, { ReactElement, useState, useEffect } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { ProductQuantity } from "../..";
import { magentoCloudUrl } from "../../../../common/services/abstract.service";
import loadable from "@loadable/component";

const GroupedPrice = loadable(() => import("./GroupedPrice"));

interface GroupedItemProps {
	name: string;
	sku: string;
	customAttributes?: {
		subtitle?: string;
		special_price?: string;
		configurable_price?: string;
		extra_high_membership_price_customer_groups?: string;
		extra_high_membership_price?: string;
		rent_member_price?: string;
	};
	minQuantity: number;
	maxQuantity?: number;
	increment?: number;
	mediaGalleryEntries?: any;
	price?: any;
	isBedVerhogers?: boolean;
	setGroupedState: any;
	groupedState: any[];
	index?: number;
	product: any;
}

const GroupedItem = (props: GroupedItemProps): ReactElement => {
	// Destructuring
	const {
		name,
		sku,
		customAttributes,
		mediaGalleryEntries,
		setGroupedState,
		groupedState,
		minQuantity,
		maxQuantity,
		increment,
		product,
	} = props;

	// States
	const [quantity, setQuantity] = useState(minQuantity);

	// Functions
	const handleGroupChange = (): any[] => {
		return groupedState.map((item) =>
			item.sku === sku ? { ...item, quantity } : item,
		);
	};

	useEffect(() => {
		setGroupedState(handleGroupChange);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [quantity]);

	return (
		<div className="d-flex align-items-center bordered-item">
			<div className="grouped-image">
				<img
					width="70"
					src={
						magentoCloudUrl +
						"/media/catalog/product" +
						mediaGalleryEntries[0]?.file
					}
					alt={"Grouped Product Image"} // TODO improve alt tag
				/>
			</div>
			<div className="grouped-text-container">
				<div className="d-flex align-items-center">
					<p>
						{name}
						<OverlayTrigger
							placement="top"
							overlay={
								<Tooltip className="group-info-text" id="group-info-text">
									{customAttributes.subtitle || ""}
								</Tooltip>
							}
						>
							<em className="icon icon-sm info-circle-blue-16 ms-2 mb-1 group-info-tooltip"></em>
						</OverlayTrigger>
					</p>
				</div>
				<GroupedPrice item={product} />
			</div>
			<div className="quantity-counter ms-auto">
				<div className="product-info-qty">
					<ProductQuantity
						type="group"
						updateQuantity={setQuantity}
						qty={quantity}
						increment={increment}
						minAllowedQuantity={minQuantity}
						maxAllowedQuantity={maxQuantity}
					/>
				</div>
			</div>
		</div>
	);
};

export default GroupedItem;
