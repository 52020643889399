import React from "react";
import CustomSkeleton from "../../Skeletons/CustomSkeleton";

interface Props {
	tileHeight?: string | number;
	featuredProducts?: boolean;
}

const ProductTileSkeleton: React.FC<Props> = ({ featuredProducts = false, tileHeight }) => (
	<div className="product-tile d-flex mb-3 product-tile-skeleton"
		 style={{ height: tileHeight, maxHeight: tileHeight }}>
		{/* Image */}
		<div className="product-tile__image d-flex align-items-lg-start justify-content-center mb-4">
			<CustomSkeleton variant="rectangular" width={180} height={180} />
		</div>
		<div className="product-tile__title">
			{/* Title */}
			<CustomSkeleton variant="text" height={40} />
			<CustomSkeleton variant="text" height={40} width="50%" />
		</div>
		{featuredProducts && (
			<div className="mt-4">
				<ul className="ps-0">
					{Array.from({ length: 3 }).map((_, index) => (
						<li className="mb-2 d-flex align-items-center gap-2" key={index}>
							<CustomSkeleton variant="rounded" width={16} height={16} />
							<CustomSkeleton variant="text" width="66%" height={24} />
						</li>
					))}
				</ul>
			</div>
		)}
		{/* Descriptions */}
		<div className={featuredProducts ? "mt-2" : "mt-auto"}>
			<div className="product-tile_texts-container">
				<div className="d-flex row mb-3 mt-3 h-100 product-tile_texts">
					<CustomSkeleton variant="text" width="50%" />
				</div>
			</div>
			<div className="d-flex mt-3 justify-content-center">
				<CustomSkeleton button width="100%" />
			</div>
		</div>
	</div>
);

export default ProductTileSkeleton;
