import React, { ReactElement, ReactNode } from "react";
import ClassNames from "classnames";
import {
	AbstractImageProps,
	CmsContentButtonProps,
} from "../../../../common/interfaces/common.interfaces";
import { getCMSAssetUrl } from "../../../../common/common";
import { CmsContentButton } from "..";
import { MaskImage } from "../..";

type SlideProps = Partial<AbstractImageProps> &
	CmsContentButtonProps & {
		title: string;
		caption: string;
		className?: string;
		image?: string;
	};

interface SliderProps {
	children: ReactNode;
}

const Slider = (props: SliderProps): ReactElement => {
	return (
		<div className="slider">
			<div
				id="carouselExampleControls"
				className="carousel slide col-18"
				data-ride="carousel"
			>
				<div className="carousel-inner col-22 offset-sm-1">
					{props.children}
				</div>
				<a
					className="carousel-control-prev"
					href="#carouselExampleControls"
					role="button"
					data-slide="prev"
				>
					<span
						className="icon icon-sm chevron-left"
						aria-hidden="true"
					>
     </span>
					<span className="sr-only">Previous</span>
				</a>
				<a
					className="carousel-control-next"
					href="#carouselExampleControls"
					role="button"
					data-slide="next"
				>
					<span
						className="icon icon-sm chevron-right"
						aria-hidden="true"
					>
     </span>
					<span className="sr-only">Next</span>
				</a>
			</div>
		</div>
	);
};

export const Slide = (props: SlideProps): ReactElement => {
	const itemClasses = ClassNames("carousel-item", props.className);
	return (
		<div className={itemClasses}>
			<div className="row">
				<div className="col-xl-5 col-24">
					{props.image ? (
						<MaskImage
							image={getCMSAssetUrl(props.image)}
							width={props.imageWidth}
							height={props.imageHeight}
							x={props.blobPositionX}
							y={props.blobPositionY}
							scale={props.blobScale}
						/>
					) : (
						<img
							className="img-fluid"
							src="/assets/images/avatar-placeholder.png"
						/>
					)}
				</div>
				<div className="col-xl-19 col-24">
					<h3 className="fw-normal m-0">{props.title}</h3>
					<p className="carousel-item__title">{props.caption}</p>
					<div className="col-lg-9 col-24 ps-0 pe-0 pb-2">
						<CmsContentButton
							className="btn-secondary"
							{...props}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Slider;
