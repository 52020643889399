import React, { ReactElement, ReactNode, useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { connect, ConnectedProps } from "react-redux";

import { useProductAttribute } from "../../../../common/product-hooks";
import {
	ProductInterface,
	SelectedProductValuesProps,
} from "../../../Pages/Catalog/catalog-interfaces";
import { QuickView } from "..";
import * as productActions from "../../../../common/actions/product";
import { ObjectProps } from "../../../../common/interfaces/common.interfaces";
type StateProps = {
	getProductDetailBySKU: (sku: string) => void;
};
const mapStateToProps = (state: ObjectProps<StateProps>): StateProps => {
	return {
		...state.product,
	};
};

type ProductModalInterface = {
	show: boolean;
	onHide(flag: boolean);
	children?: ReactNode;
	sku: string;
	callbackHandler?: (data: object) => void;
	selectedValues?: SelectedProductValuesProps;
	product?: ProductInterface;
};
const connector = connect<any, StateProps, ProductModalInterface>(
	mapStateToProps,
	productActions,
);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = ProductModalInterface & PropsFromRedux;

const ProductModal = (props: Props): ReactElement => {
	const attributes = useProductAttribute([]);
	const [productSku, setProductSku] = useState(props.sku);
	useEffect(() => {
		props.getProductDetailBySKU({ sku: productSku });
	}, [productSku]);
	return (
		<>
			{props.children}
			<Modal
				show={props.show}
				onHide={(): void => props.onHide(false)}
				centered
				size="lg"
			>
				<Modal.Body>
					<div className="d-flex flex-column">
						<button
							className="btn-link border-0 p-0 d-flex align-self-end"
							onClick={(): void => props.onHide(false)}
						>
							<em className="icon icon-sm close-16"></em>
						</button>
						{props.product && props.product.sku && (
							<QuickView
								product={{
									...props.product,
									...props.selectedValues,
								}}
								attributes={attributes}
								clickHandler={props.callbackHandler}
								productChange={(sku): void =>
									setProductSku(sku)
								}
							/>
						)}
					</div>
				</Modal.Body>
			</Modal>
		</>
	);
};

export default connector(ProductModal);
