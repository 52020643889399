import axios, { AxiosResponse, AxiosInstance } from "axios";
import {
	StorageKeys,
	setStorageItem,
	disappointmentEventPublish,
} from "../common";
import { updateLoadingState } from "../actions/loading";
import { dispatchAction } from "../common";
import { getCookie } from "../cookies.common";
const magentoCloudUrl = process.env.REACT_APP_NODE_URL;

const getApiInstance = (options): AxiosInstance => {
	return axios.create({
		...{
			headers: {
				"Content-Type": "application/json;charset=utf-8",
				"Cache-Control": "no-store",
				Pragma: "no-store",
			},
			withCredentials: true,
		},
		...{ ...options },
	});
};

const getElasticInstance = (options): AxiosInstance => {
	return axios.create({
		...{
			headers: {
				"Content-Type": "application/json;charset=utf-8",
				Authorization: `Bearer ${process.env.REACT_APP_ELASTIC_SEARCH_BEARER}`,
			},
		},
		...{ ...options },
	});
};

const responseHandler = (config: AxiosResponse): AxiosResponse => {
	return config;
};

const errorHandler = (error) => {
	if (error && error.response) {
		switch (error.response.status) {
			case 401:
				if (
					error.response.data.error.errorCode &&
					error.response.data.error.errorCode !== "ERR4021"
				) {
					setStorageItem(StorageKeys.ONLOAD_ERROR_400, "true");
					window.location.href = "/login";
					throw { message: error.response.data.message };
				} else {
					disappointmentEventPublish(
						error?.response?.data?.error?.errorDetail,
					);
				}
				throw error?.response?.data?.error;
			case 500:
				disappointmentEventPublish(
					error?.response?.data?.error?.errorDetail,
				);
				throw error?.response?.data?.error;
			case 400:
				if (
					error.response.data.error.errorCode &&
					error.response.data.error.errorCode === "ERR4020"
				) {
					disappointmentEventPublish(
						error?.response?.data?.error?.errorDetail,
					);
				}
				throw error?.response?.data?.error;
			default:
				throw error;
		}
	} else {
		throw error;
	}
};

axios.interceptors.response.use(responseHandler, errorHandler);
const nodeAPI = getApiInstance({ baseURL: process.env.REACT_APP_NODE_URL });
const elasticAPI = getElasticInstance({
	baseURL: process.env.REACT_APP_ELASTIC_SEARCH_BASE_URL,
});
const loginUrl = "/login";
const customerUrl = "/customers/me";
const cmsUrl = "/cmsblock";
const productSearchUrl = "/search/product";

// Initialize an empty Set to track active requests
const activeRequests = new Set();

const excludeUrls = [
	"/category/product-qty",
	"/category?isActive=true",
	"/urlrewrite/all",
	"/faq",
	"/cmspage/home",
	"/cmsblock/footer-bottom",
	"/cmsblock/footer-top",
	"/cmsblock/alertbox",
	"/cmsblock/greenticker",
	"/search/product?",
	"/product/search?",
	"/product/get/*",
	"/cmsblock/store-locator-block-merkbeeld",
	"client/find?"
];

const isAbortExcluded = (url: string | undefined, abortExcluded: string[] = []): boolean => {
  return abortExcluded.some((endpoint) => new RegExp(endpoint).test(url || ''));
};

// Add a request interceptor
nodeAPI.interceptors.request.use(function (config) {
	config.headers.Authorization = `Basic ${process.env.REACT_APP_NODE_TOKEN}`;
	const token = getCookie(StorageKeys.ACCESS_TOKEN);
	if (token) {
		config.headers.authToken = token;
	}

	// If the url is not in the excludeUrls array, add it to the activeRequests Set
	if (!isAbortExcluded(config.url, excludeUrls)) {
		activeRequests.add(config.url);
		// Call updateLoadingState to set the loading state to true
		dispatchAction(updateLoadingState(true));
	}

	return config;
});

nodeAPI.interceptors.response.use(
	async (response) => {
		// Remove the request from the activeRequests Set
		await activeRequests.delete(response.config.url);

		// Call updateLoadingState to set the loading state to false if there are no active requests
		if (activeRequests.size === 0) {
			dispatchAction(updateLoadingState(false));
		}

		return responseHandler(response);
	},
	async (error) => {
		// Remove the request from the activeRequests Set
		await activeRequests.delete(error.response.config.url);

		// Call updateLoadingState to set the loading state to false if there are no active requests
		if (activeRequests.size === 0) {
			dispatchAction(updateLoadingState(false));
		}

		return errorHandler(error);
	},
);

export {
	nodeAPI,
	loginUrl,
	cmsUrl,
	customerUrl,
	productSearchUrl,
	magentoCloudUrl,
	elasticAPI,
};
