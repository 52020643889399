import {
	ProductAttribute,
	ProductInterface,
} from "../../components/Pages/Catalog/catalog-interfaces";

export interface UserInterface {
	firstName: string;
	lastName: string;
	role: number;
}

export interface MaskProps {
	x?: string;
	y?: string;
	scale?: string;
	image: string;
	width?: string;
	height?: string;
}

export interface AbstractImageProps {
	imageWidth?: string;
	imageHeight?: string;
	blobPositionX?: string;
	blobPositionY?: string;
	blobScale?: string;
	image?: string;
}

export interface CmsContentButtonProps {
	buttonLabel?: string;
	navigateTo?: string;
	isExternalLink?: boolean;
	target?: string;
	buttonColor?: string;
}

export const AckType = {
	SUCCESS: "SUCCESS",
	ERROR: "ERROR",
	PENDING: "PENDING",
	REQUEST: "REQUEST",
};

export type ObjectProps<T> = Record<string, T>;

export type CategoryObjectProps = {
	category_is_business: string;
	level: number;
	id: number;
	name: string;
	children_data: Array<CategoryObjectProps>;
	meta_title: string;
	meta_description: string;
	meta_keywords: string;
	url_rewrite: Array<{ request_path: string }>;
};

export interface ProductInfoInterface {
	product: ProductInterface;
	buyOptions: Array<ProductAttribute>;
	orderType?: Array<string>;
	clickHandler?: (e: any) => void;
	productChange?: (e: any) => void;
	productAttributeChange?: (e: any) => void;
}

export interface ElasticSearchItems {
	attribute_set: { raw: string };
	name: { raw: string };
	price: { raw: string };
	gallery: { raw: string };
	url_key: { raw: string };
	_meta: { engine: string };
	title: { raw: string };
	meta_description: { raw: HTMLElement };
}
