import { EcommerceItem } from "./store/types/datalayerTypes";
import { getProductCategories } from "./common";

export const calculateCheckoutFlowPrice = (items: EcommerceItem[]) => {
	let buy = null;
	let rent = null;
	let free_rental = null;
	let loan = null;

	items.map((item: EcommerceItem) => {
		if (item?.item_category == "buy") {
			buy += (item.price * item.quantity);
		} else if (item?.item_category == "rent") {
			rent += (item.price * item.quantity);
		} else if (item?.item_category == "free_rental") {
			free_rental = 0;
		} else if (item?.item_category == "loan") {
			loan = 0;
		}
	});
	return { buy: buy, rent: rent, free_rental: free_rental, loan: loan, total: buy + rent + loan + free_rental };
};

export const formatEcommerceItem = (cartInfo, categories): EcommerceItem[] => {
	const items: EcommerceItem[] = [];
	cartInfo?.cartInfo?.items?.map((product, index) => {

		const priceInfo = cartInfo.totals?.items?.find(obj => obj.item_id === product.item_id);

		let price = 0;
		if(priceInfo?.price_incl_tax) {
			price = priceInfo.price_incl_tax;
		} else if (product?.price > 0) {
			price = product.price;
		} else if (product.product_type === "rent") {
			price = parseFloat(product.custom_attributes.rent_price);
		}

		const categoryData = getProductCategories(product, categories);

		const item: EcommerceItem = {
			item_id: product?.options.length > 0 ? product?.sku + "-" + product?.options[0]?.value : product?.sku,
			item_name: product?.options.length > 0 ? product?.name + "-" + product?.options[0]?.value : product?.name,
			coupon: "",
			currency: "EUR",
			discount: 0,
			index: index,
			item_variant: product?.options.length > 0 ? product?.options[0]?.value : "",
			price: price,
			quantity: product?.quantity,
			item_category: product?.product_type
		};
		let counter = 2;
		product?.custom_attributes?.category_ids?.reverse().map((category, index) => {
			if (index <= 5) {
				const name = categoryData?.filter((c) => c?.id.toString() === category && c.parent_id !== 2)[0]?.name;
				if (name) {
					item["item_category" + counter] = name;
					counter++;
				}
			}
		});
		items.push(item);
	});
	return items;
};
