import React, { ReactElement, useState } from "react";
import {
	ProductInterface,
	ProductTileProps,
} from "../../../Pages/Catalog/catalog-interfaces";
import { Product } from "../../../UI";

type QuickViewInterface = ProductTileProps & {
	clickHandler?: (data: any) => void;
	productChange?: (sku: string) => void;
};

const QuickView = (props: QuickViewInterface): ReactElement => {
	const [configProduct, setConfigProduct] = useState<ProductInterface>(
		props.product,
	);
	return (
		<div className="quick-view d-flex flex-column">
			<div className="quick-view__header">
				<p className="quick-view__header-subinfo">
					Toevoegen aan bestelling
				</p>
				<h2 className="quick-view__header-title mt-2">
					{props.product.name}
				</h2>
				<span>SKU#: {props.product.sku}</span>
			</div>

			<div className="quick-view__content row">
				<Product.ProductImages
					images={configProduct.media_gallery_entries}
				/>
				<Product.ProductInfo
					buyOptions={props.attributes}
					product={props.product}
					clickHandler={props.clickHandler}
					productChange={(sku: string): void =>
						props.productChange(sku)
					}
					productAttributeChange={(product): void =>
						setConfigProduct(product)
					}
				/>
			</div>
		</div>
	);
};

export default QuickView;
