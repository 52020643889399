import loadable from "@loadable/component";
import * as Loader from "./Loader/Loader";
import * as Product from "./Product";

import Accordion from "./Accordion";
import * as Client from "./Client";
import {
	Cmsblock,
	Descriptor,
	FeaturePoint,
	Hero,
	LinkButton,
	Products,
} from "./Cms";
import { ToasterProvider } from "./Toaster";
import Spinner from "./Spinner/Spinner";

const Steps = loadable(() => import(/* webpackPrefetch: true */ "./Steps"));

const BGImage = loadable(
	() => import(/* webpackPrefetch: true */ "./BGImage/BGImage"),
);
const Breadcrumb = loadable(
	() => import(/* webpackPrefetch: true */ "./Breadcrumb/Breadcrumb"),
);
const ButtonTile = loadable(
	() => import(/* webpackPrefetch: true */ "./Buttons/ButtonTile/ButtonTile"),
);
const ChangePasswordForm = loadable(
	() =>
		import(
			/* webpackPrefetch: true */ "./ChangePasswordForm/ChangePasswordForm"
		),
);
const FontSizeSelector = loadable(
	() =>
		import(
			/* webpackPrefetch: true */ "./FontSizeSelector/FontSizeSelector"
		),
);
const ProductQuantity = loadable(
	() =>
		import(/* webpackPrefetch: true */ "./ProductQuantity/ProductQuantity"),
);
const MiniCart = loadable(
	() => import(/* webpackPrefetch: true */ "./MiniCart/MiniCart"),
);
const TextEllipsis = loadable(
	() => import(/* webpackPrefetch: true */ "./TextEllipsis/TextEllipsis"),
);
const RadioButton = loadable(
	() => import(/* webpackPrefetch: true */ "./Radio/RadioButton/RadioButton"),
);
const RadioGroup = loadable(
	() => import(/* webpackPrefetch: true */ "./Radio/RadioGroup/RadioGroup"),
);
const CheckboxButton = loadable(
	() =>
		import(
			/* webpackPrefetch: true */ "./Checkbox/CheckboxButton/Checkbox"
		),
);
const CheckboxGroup = loadable(
	() =>
		import(
			/* webpackPrefetch: true */ "./Checkbox/CheckboxGroup/CheckboxGroup"
		),
);
const Menu = loadable(() => import(/* webpackPrefetch: true */ "./Menu/Menu"));
const ImageMagnifier = loadable(
	() => import(/* webpackPrefetch: true */ "./ImageMagnifier/ImageMagnifier"),
);
const Card = loadable(() => import(/* webpackPrefetch: true */ "./Card/Card"));
const CartItem = loadable(
	() => import(/* webpackPrefetch: true */ "./CartItem/CartItem"),
);
const Dropdown = loadable(
	() => import(/* webpackPrefetch: true */ "./Dropdown/Dropdown"),
);
const DateTimePicker = loadable(
	() => import(/* webpackPrefetch: true */ "./DateTimePicker/DateTimePicker"),
);
const Input = loadable(
	() => import(/* webpackPrefetch: true */ "./Input/Input"),
);
const ContentHeader = loadable(
	() => import(/* webpackPrefetch: true */ "./ContentHeader/ContentHeader"),
);
const CategoryMenu = loadable(
	() => import(/* webpackPrefetch: true */ "./CategoryMenu/CategoryMenu"),
);
const Pagination = loadable(
	() => import(/* webpackPrefetch: true */ "./Pagination/Pagination"),
);
const MaskImage = loadable(
	() => import(/* webpackPrefetch: true */ "./MaskImage/MaskImage"),
);
const ImageBgBlob = loadable(
	() => import(/* webpackPrefetch: true */ "./ImageBgBlob/ImageBgBlob"),
);
const Header = loadable(
	() => import(/* webpackPrefetch: true */ "./Header/Header"),
);
const Footer = loadable(
	() => import(/* webpackPrefetch: true */ "./Footer/Footer"),
);
const ForgotPasswordModal = loadable(
	() =>
		import(
			/* webpackPrefetch: true */ "./ForgotPasswordModal/ForgotPasswordModal"
		),
);
const Sidebar = loadable(
	() => import(/* webpackPrefetch: true */ "./Sidebar/Sidebar"),
);
const SimpleTextModal = loadable(
	() =>
		import(/* webpackPrefetch: true */ "./SimpleTextModal/SimpleTextModal"),
);
const SimpleModal = loadable(
	() => import(/* webpackPrefetch: true */ "./SimplestModal/SimpleModal"),
);
const ReturnModal = loadable(
	() => import(/* webpackPrefetch: true */ "./ReturnModal/ReturnModal"),
);
const ProductConfigurableLabel = loadable(
	() =>
		import(
			/* webpackPrefetch: true */ "./ProductConfigurableLabel/ProductConfigurableLabel"
		),
);
const MegaMenu = loadable(
	() => import(/* webpackPrefetch: true */ "./MegaMenu/MegaMenu"),
);
const MegaMenuMobile = loadable(
	() => import(/* webpackPrefetch: true */ "./MegaMenu/MegaMenuMobile"),
);
const ProductPriceBox = loadable(
	() =>
		import(
			/* webpackPrefetch: true */ "./Cms/ProductPriceBox/ProductPriceBox"
		),
);
const ProductBuyType = loadable(
	() =>
		import(
			/* webpackPrefetch: true */ "./Product/ProductTile/ProductBuyType"
		),
);
const LoginForm = loadable(
	() => import(/* webpackPrefetch: true */ "./LoginForm/LoginForm"),
);
const ElasticSearchModals = loadable(
	() =>
		import(
			/* webpackPrefetch: true */ "./ElasticSearchModals/ElasticSearchModals"
		),
);

const OptionalContentBlock = loadable(
	() =>
		import(
			/* webpackPrefetch: true */ "./OptionalContentBlock/OptionalContentBlock"
			),
);

const Meta = loadable(() => import(/* webpackPrefetch: true */ "./Meta/Meta"));
const NoIndex = loadable(
	() => import(/* webpackPrefetch: true */ "./Meta/NoIndex"),
);

export {
	BGImage,
	Breadcrumb,
	ButtonTile,
	FontSizeSelector,
	ProductQuantity,
	Loader,
	Product,
	MiniCart,
	Accordion,
	Steps,
	TextEllipsis,
	CheckboxButton,
	CheckboxGroup,
	RadioButton,
	Menu,
	Card,
	CartItem,
	ChangePasswordForm,
	Dropdown,
	DateTimePicker,
	RadioGroup,
	Input,
	Client,
	ContentHeader,
	Cmsblock,
	Hero,
	Products,
	LinkButton,
	ToasterProvider,
	Descriptor,
	MaskImage,
	FeaturePoint,
	ImageBgBlob,
	LoginForm,
	Footer,
	Header,
	Spinner,
	ReturnModal,
	SimpleTextModal,
	SimpleModal,
	ImageMagnifier,
	ForgotPasswordModal,
	Sidebar,
	ProductConfigurableLabel,
	CategoryMenu,
	Pagination,
	MegaMenu,
	MegaMenuMobile,
	ProductPriceBox,
	ProductBuyType,
	Meta,
	NoIndex,
	ElasticSearchModals,
	OptionalContentBlock
};
