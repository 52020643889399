import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { ProductInterface } from "../../../Pages/Catalog/catalog-interfaces";
import {
	GetEcommerceData,
	gtmEventPublish,
} from "../../../../common/common";

interface AddToCartInterface {
	qty: number;
	product: ProductInterface;
	configProduct: ProductInterface;
	clickHandler?: (data: any) => void;
	disabled?: boolean;
	categories?: any;
	type?: "button" | "submit" | "reset";
	isShopOnly: boolean;
}

const AddToCart = (props: AddToCartInterface): ReactElement => {
	const { isShopOnly } = props;
	const { t } = useTranslation();
	return (
		<div className="add-to-cart">
			{isShopOnly ? (
				<button
					type={props.type}
					className="btn btn-primary d-flex justify-content-center align-items-center text-truncate"
					onClick={(): void => {
						window.location.href = "/locaties";
					}}
				>
					{t("common.findStoreBtnLabel")}
				</button>
			) : ( <button
					type={props.type}
					className="btn btn-primary d-flex justify-content-center align-items-center text-truncate"
					disabled={props.disabled}
					onClick={(): void => {
						if (props.clickHandler) {
							props.clickHandler({
								quantity: props.qty,
								sku: props.configProduct ? props.configProduct.sku : props.product.sku,
							});
							if (window.dataLayer) {
								// eslint-disable-next-line @typescript-eslint/ban-ts-comment
								// @ts-ignore
								window.dataLayer.push({ ecommerce: null });
							}
							gtmEventPublish(
								"add_to_cart",
								GetEcommerceData(
									[props.configProduct || props.product],
									[props],
									props.categories,
								),
							);
						}
					}}
				>
					{t("common.addToCartBtnLabel")}
				</button>
				)}
		</div>
	);
};

export default AddToCart;
