import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../common/store";

interface SpinnerProps {
	suspense?: boolean;
}

const Spinner: React.FC<SpinnerProps> = ({ suspense }) => {
	const loading = useSelector((state: RootState) => state.loading);
	const { isLoading } = loading;

	return suspense || isLoading ? (
		<div className="loader loading">
			<div className="spinner-border" role="status">
				<span className="sr-only"></span>
			</div>
		</div>
	) : null;
};

export default Spinner;
