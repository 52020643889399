import * as React from "react";
import classNames from "classnames";
import { CmsContentToHtml } from "../Cms";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";

interface AccordionProps {
	multiple?: boolean;
	defaultExpanded?: boolean;
	activeIndex?: number[];
	children: React.ReactNode;
	style?: object;
	className?: string;
	backgroundColor?: string;
	onTabChange?: (event: any) => void;
	tabIcon?: {
		expand: string;
		collapse: string;
	};
}

interface AccordionTabProps {
	header: string;
	children?: React.ReactNode;
	disabled?: boolean;
	tooltip?: string;
	hasCounter?: boolean;
	countIs?: number;
	tabClass?: string;
	hasShowMore?: boolean;
}
const AccordionTab: React.FC<AccordionTabProps> = () => null;

AccordionTab.defaultProps = {
	disabled: false,
};

const Accordion: React.FC<AccordionProps> & {
	Tab: React.FC<AccordionTabProps>;
} = (props: AccordionProps) => {
	const [indexes, setIndexes] = useState(props.activeIndex);
	const { t } = useTranslation();
	const accordionClass = classNames("accordion", props.className);

	const isActive = (index: number): boolean => {
		return indexes.indexOf(index) > -1;
	};

	useEffect(() => {
		if (window.location.pathname.indexOf("/veelgestelde-vragen") > -1) {
			setIndexes([]);
		}
	}, [props.children]);

	const children = React.Children.toArray(
		props.children,
	) as React.ReactElement<AccordionTabProps>[];

	const handleHeaderClick = (
		event: React.MouseEvent,
		tab: object,
		index: number,
	): void => {
		const selected = isActive(index);
		let activeIndexes = null;
		if (selected) {
			activeIndexes = props.multiple
				? indexes.filter((i) => i !== index)
				: [];
		} else {
			activeIndexes = props.multiple ? [...indexes, index] : [index];
			if (props.onTabChange) {
				props.onTabChange({ event: event, activeIndex: index });
			}
		}
		setIndexes(activeIndexes);
		event.preventDefault();
	};

	const clickShowMore = (tabClass: string, currElement: any): void => {
		const element = document.querySelector(
			`.${tabClass} > .accordion-tab__content`,
		);
		const htmlElement = currElement.target;
		element.classList.toggle("expanded");

		if (
			htmlElement.innerHTML.toLowerCase() ===
			t("productfilter.showMore").toLowerCase()
		) {
			htmlElement.innerHTML = t("productfilter.showLess");
		} else {
			htmlElement.innerHTML = t("productfilter.showMore");
		}
	};

	useEffect(() => {
		props.defaultExpanded && setIndexes(Array.from(children.keys()));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.defaultExpanded]);

	return (
		<div className={accordionClass} style={props.style}>
			{children
				.filter((s) => s.props.header != undefined)
				.map((tab, index) => {
					const selected = isActive(index);
					const tabClass = classNames(
						"accordion-tab",
						{
							active: selected,
							disabled: tab.props.disabled,
						},
						`bg-${props.backgroundColor}`,
					);
					const iconClass = classNames(
						"icon icon-s",
						selected
							? props.tabIcon.expand
							: props.tabIcon.collapse,
					);
					const contentClass = classNames("accordion-tab__content", {
						active: selected,
					});
					const showMoreClass = classNames("tab-show-more", {
						active: selected,
					});
					return (
						<div
							key={index}
							className={`${tabClass} ${tab.props.tabClass}`}
						>
							<div
								className="accordion-tab__header d-flex justify-content-between align-items-center"
								onClick={(event): void =>
									handleHeaderClick(event, tab, index)
								}
							>
								<div className="d-flex align-items-center">
									{CmsContentToHtml({
										content: tab.props.header,
									})}
									{tab.props.tooltip && (
										<OverlayTrigger
											placement="right"
											overlay={
												<Tooltip id="tooltip-info">
													{tab.props.tooltip}
												</Tooltip>
											}
										>
											<em className="icon icon-sm info-circle-blue-16 ms-1"></em>
										</OverlayTrigger>
									)}
								</div>
								<div className="tab-heading-flex-container d-flex align-items-center">
									{tab.props.hasCounter ? (
										<div className="item-count-indicator">
											<p className="m-0">
												{tab.props.countIs}
											</p>
										</div>
									) : (
										""
									)}
									<em className={iconClass}></em>
								</div>
							</div>

							<div
								key={tab.props.header}
								className={contentClass}
							>
								{tab.props.children}
							</div>

							{tab.props.hasShowMore &&
								tab.props.tabClass.length > 0 && (
									<button
										onClick={(e): void =>
											clickShowMore(tab.props.tabClass, e)
										}
										className={showMoreClass}
									>
										{t("productfilter.showMore")}
									</button>
								)}
						</div>
					);
				})}
		</div>
	);
};

Accordion.defaultProps = {
	multiple: true,
	activeIndex: [0],
	defaultExpanded: true,
	tabIcon: { expand: "chevron-up", collapse: "chevron-down" },
};

Accordion.Tab = AccordionTab;

export default Accordion;
