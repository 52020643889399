import {
	objToQueryString,
	filterProductQuery,
	isBusiness,
	getLoggedInUserInfo,
} from "../common";
import { nodeAPI } from "../services/abstract.service";
import { Dispatch } from "redux";
import ActionTypes from "../store/types/actionTypes";
import { ProductFilterType } from "../../components/Pages/Catalog/catalog-interfaces";
import { startLoading, stopLoading } from "../store/actions/loadingActions";

export const _getProducts = ({
	id,
	uzoviCode,
	packageCode,
	role = undefined,
	productType = undefined,
}): Promise<any> => {
	const queryString = objToQueryString({
		uzoviCode,
		packageCode,
		role,
		productType,
	});
	return nodeAPI.get(`/category/${id}/products${"?" + queryString}`);
};

export const getProducts = (filters: ProductFilterType) => {
	let productFilters = {};

	if (isBusiness() && !filters.categoryId[0]) {
		Object.entries(filters).map((e) => {
			if (e[0] !== "categoryId") {
				productFilters[e[0]] = e[1];
			}
		});
		productFilters = {
			productType: filters.productType,
			role: filters.role,
			pageSize: filters.pageSize,
			priceRange: filters.priceRange,
			sortBy: filters.sortBy,
			currentPage: filters.currentPage,
		};
	} else {
		productFilters = filters;
	}

	const queryString = filterProductQuery(
		productFilters as ProductFilterType,
		["sortBy"],
	);
	const url = "/product/search?";
	return async (dispatch: Dispatch): Promise<any> => {
		dispatch({
			type: ActionTypes.PRODUCT_GET_ALL_PENDING,
			payload: {},
		});
		const userInfo = getLoggedInUserInfo();
		try {
			const products = await nodeAPI.get(
				`${url}${queryString}&role=${userInfo?.role ? userInfo?.role : "CONSUMER"
				}`,
			);
			dispatch({
				type: ActionTypes.PRODUCT_GET_ALL_SUCCESS,
				payload: {},
			});
			const items = products.data;
			return Promise.resolve(items);
		} catch (error) {
			return Promise.reject(error);
		}
	};
};

export const getProductDetailBySKU = ({ sku, cmsProduct }) => {
	return async (dispatch) => {

		const productDataMap = (productData) => {
			const cmsProductSuccessAction = {
				type: ActionTypes.CMS_PRODUCT_DETAIL_SUCCESS,
				payload: { [productData.sku]: productData },
			};
			const productSuccessAction = {
				type: ActionTypes.PRODUCT_DETAIL_SUCCESS,
				payload: { product: productData },
			};
			const actionDetail = cmsProduct
				? cmsProductSuccessAction
				: productSuccessAction;

			dispatch(actionDetail);
		};
		/*
		Clearing the previous product detail,
		so that it won't be displayed on Quickview while new product detail fetching.
		*/
		dispatch({
			type: ActionTypes.PRODUCT_DETAIL_SUCCESS,
			payload: { product: {} },
		});

		try {
			const product = await nodeAPI.get(`/product/${sku}`);
			if (product?.data) {
				if (product?.data.status !== 2) {
					productDataMap(product?.data);
				}
				// Return the product data
				return product.data;
			}
		} catch (err) {
			// eslint-disable-next-line no-prototype-builtins
			if (typeof err === "object" && Object.prototype?.hasOwnProperty.call(err, "response")) {
				productDataMap(err.response.data.error);
			}
		}
	};
};

export const getProductDetailByUrl = ({ requestPath, cmsProduct }) => {
	return async (dispatch: Dispatch): Promise<void> => {
		dispatch<any>(startLoading("loadingGetSingleProduct"));

		const productDataMap = (productData): void => {
			const cmsProductSuccessAction = {
				type: ActionTypes.CMS_PRODUCT_DETAIL_SUCCESS,
				payload: { [productData.sku]: productData },
			};
			const productSuccessAction = {
				type: ActionTypes.PRODUCT_DETAIL_SUCCESS,
				payload: { product: productData },
			};
			const actionDetail = cmsProduct
				? cmsProductSuccessAction
				: productSuccessAction;

			dispatch(actionDetail);
		};
		/*
		Clearing the previous product detail,
		so that it won't be display on Quickview while new product detail fetching.
		*/
		dispatch({
			type: ActionTypes.PRODUCT_DETAIL_SUCCESS,
			payload: { product: {} },
		});
		nodeAPI
			.get(`/product/get/${requestPath}`)
			.then((product) => {
				if (product?.data) {
					if (product?.data.status != 2) {
						productDataMap(product?.data);
						dispatch<any>(stopLoading("loadingGetSingleProduct"));
					}
				}
			})
			.catch((err) => {
				if (typeof err === "object") {
					productDataMap(err);
				}
			});
	};
};

export const getLinkedProducts = (arr: string[]) => {
	return async (dispatch: Dispatch): Promise<void> => {

		const productDataMap = (productData): void => {
			const linkedSuccessAction = {
				type: ActionTypes.LINKED_PRODUCT_SUCCESS,
				payload: { linkedProducts: productData },
			};

			dispatch(linkedSuccessAction);
		};

		// Clear previous array of linked products
		dispatch({
			type: ActionTypes.CLEAR_LINKED_PRODUCTS,
		});
		arr.map((e) => {
			nodeAPI
				.get(`/product/${e}`)
				.then((product) => {
					if (product.data) {
						if (product.data.status != 2) {
							productDataMap(product.data);
						}
					}
				})
				.catch((err) => {
					// eslint-disable-next-line no-prototype-builtins
					if (
						typeof err === "object" &&
						Object.prototype?.hasOwnProperty.call(err, "response")
					) {
						productDataMap(err.response.data.error);
					}
				});
		});
	};
};

export const getProductSuggestion = ({
	search,
	pageSize,
	role,
	conditionType = "like",
	field = "name",
}) => {
	return async (): Promise<any> => {
		const products = await nodeAPI.get(
			`/search/product?conditionType=${conditionType}&field=${field}&value=${search}&pageSize=${pageSize}${role ? `&role=${role}` : ""
			}`,
		);
		return new Promise((resolve) => {
			resolve(products);
		});
	};
};

export const getProductsByIds = ({ productsIds, pageSize }) => {
	return async (): Promise<any> => {

		try {
			const products = await nodeAPI.get(
				`/search/product?conditionType=in&field=entity_id&value=${productsIds}&pageSize=${pageSize}`,
			);
			return Promise.resolve(products);
		} catch (error) {
			return Promise.reject(error);
		}
	};
};

export const filterProducts = (filters: any) => {
	return async (dispatch: Dispatch): Promise<void> => {
		dispatch({
			type: ActionTypes.PRODUCT_FILTER_CHANGE,
			payload: { filters },
		});
	};
};
export const setLastSelectedCategory = (categoryId: any) => {
	return async (dispatch: Dispatch): Promise<void> => {
		dispatch({
			type: ActionTypes.LAST_SELECTED_CATEGORY_CHANGE,
			payload: { lastSelectedCategory: categoryId },
		});
	};
};

export const setPriceRange = (priceRange: any) => {
	return async (dispatch: Dispatch): Promise<void> => {
		dispatch({
			type: ActionTypes.SET_PRICE_FILTER,
			payload: { priceRange },
		});
	};
};

export const clearPriceRange = () => {
	return async (dispatch: Dispatch): Promise<void> => {
		dispatch({
			type: ActionTypes.CLEAR_PRICE_FILTER,
		});
	};
};

export const setFreeRentalMembershipName = (name: string) => {
	return async (dispatch: Dispatch): Promise<void> => {
		dispatch({
			type: ActionTypes.SET_FREE_RENTAL_MEMBERSHIP_NAME,
			payload: name,
		});
	};
};
