import React, { ReactNode } from "react";
import { getCMSAssetUrl } from "../../../../common/common";
import { CmsContentButton } from "..";
import { CmsContentButtonProps } from "../../../../common/interfaces/common.interfaces";

interface FeaturedTopicsProps {
	title: string;
	children: ReactNode;
}

type TopicProps = CmsContentButtonProps & {
	image: string;
	title: string;
	caption: string;
};

const FeaturedTopics = (props: FeaturedTopicsProps): React.ReactElement => {
	return (
		<div className="content-block content-block-twelve">
			<h2>{props.title}</h2>
			<div className="row">{props.children}</div>
		</div>
	);
};

const Topic = (props: TopicProps): React.ReactElement => {
	return (
		<div className="col-xl-8 col-lg-8 col-24 mb-3">
			<div className="product-tile h-100 d-flex justify-content-between">
				<div className="product-tile__image text-center object-fit-cover">
					<img src={getCMSAssetUrl(props.image)} alt={props.title} />
				</div>
				<div className="product-tile__content h-100 d-flex flex-column">
					<h4 className="mt-0">{props.title}</h4>
					<p className="common-content mb-5 h-100">{props.caption}</p>
					<div className="d-flex justify-content-center">
						<CmsContentButton {...props} />
					</div>
				</div>
			</div>
		</div>
	);
};

export { FeaturedTopics, Topic };
