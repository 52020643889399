import loadable from "@loadable/component";
import Products, { LinkButton } from "./Products/Products";
import Slider, { Slide } from "./Slider/Slider";
import { FeaturedTopics, Topic } from "./FeaturedTopic/FeaturedTopic";
import Product from "./Product/Product";
import CmsContentToHtml from "./CmsContentToHtml/CmsContentToHtml";

const Cmsblock = loadable(
	() => import(/* webpackPrefetch: true */ "./Cmsblock/Cmsblock"),
);
const Hero = loadable(() => import(/* webpackPrefetch: true */ "./Hero/Hero"));
const HeroBanner = loadable(
	() => import(/* webpackPrefetch: true */ "./HeroBanner/HeroBanner"),
);
const HeroShowCase = loadable(
	() => import(/* webpackPrefetch: true */ "./HeroShowCase/HeroShowCase"),
);
const Descriptor = loadable(
	() => import(/* webpackPrefetch: true */ "./Descriptor/Descriptor"),
);
const BlockWithBlobImage = loadable(
	() =>
		import(
			/* webpackPrefetch: true */ "./BlockWithBlobImage/BlockWithBlobImage"
		),
);
const BlockWithBlobImageStoreLocator = loadable(
	() =>
		import(
			/* webpackPrefetch: true */ "./BlockWithBlobImageStoreLocator/BlockWithBlobImageStoreLocator"
		),
);
const OptionalContentBlock = loadable(
	() =>
		import(
			/* webpackPrefetch: true */ "./OptionalContentBlock/OptionalContentBlock"
		),
);
const FeaturePoint = loadable(
	() => import(/* webpackPrefetch: true */ "./FeaturePoint/FeaturePoint"),
);
const BlockWithImageBgBlob = loadable(
	() =>
		import(
			/* webpackPrefetch: true */ "./BlockWithImageBgBlob/BlockWithImageBgBlob"
		),
);
const CustomerService = loadable(
	() =>
		import(/* webpackPrefetch: true */ "./CustomerService/CustomerService"),
);
const PersonalTestimony = loadable(
	() =>
		import(
			/* webpackPrefetch: true */ "./PersonalTestimony/PersonalTestimony"
		),
);
const VideoComponent = loadable(
	() => import(/* webpackPrefetch: true */ "./VideoComponent/VideoComponent"),
);
const CalloutWidget = loadable(
	() => import(/* webpackPrefetch: true */ "./CalloutWidget/CalloutWidget"),
);
const ProductAssessment = loadable(
	() =>
		import(
			/* webpackPrefetch: true */ "./ProductAssessment/ProductAssessment"
		),
);
const BulletPoints = loadable(
	() => import(/* webpackPrefetch: true */ "./BulletPoints/BulletPoints"),
);
const BuyDirect = loadable(
	() => import(/* webpackPrefetch: true */ "./BuyDirect/BuyDirect"),
);
const FeatureProducts = loadable(
	() =>
		import(/* webpackPrefetch: true */ "./FeatureProducts/FeatureProducts"),
);
const ProductTeaser = loadable(
	() => import(/* webpackPrefetch: true */ "./ProductTeaser/ProductTeaser"),
);
const ExpertBlock = loadable(
	() => import(/* webpackPrefetch: true */ "./ExpertBlock/ExpertBlock"),
);
const DoubleTextBlock = loadable(
	() =>
		import(/* webpackPrefetch: true */ "./DoubleTextBlock/DoubleTextBlock"),
);
const TextBlueBlockAndImage = loadable(
	() =>
		import(
			/* webpackPrefetch: true */ "./TextBlueBlockAndImage/TestBlueBlockAndImage"
		),
);
const TextWithBlueBlock = loadable(
	() =>
		import(
			/* webpackPrefetch: true */ "./TextWithBlueBlocks/TextWithBlueBlocks"
		),
);
const NarrowText = loadable(
	() => import(/* webpackPrefetch: true */ "./NarrowText/NarrowText"),
);
const CmsContentButton = loadable(
	() =>
		import(
			/* webpackPrefetch: true */ "./CmsContentButton/CmsContentButton"
		),
);
const ServiceBlock = loadable(
	() => import(/* webpackPrefetch: true */ "./ServiceBlock/ServiceBlock"),
);
const ContactColumn = loadable(
	() => import(/* webpackPrefetch: true */ "./ContactColumn/ContactColumn"),
);
const CookieConsentDeclaration = loadable(
	() =>
		import(
			/* webpackPrefetch: true */ "./CookieConsentDeclaration/CookieConsentDeclaration"
		),
);
const ProductAddToCart = loadable(
	() =>
		import(
			/* webpackPrefetch: true */ "./ProductAddToCart/ProductAddToCart"
		),
);
const FaqBlock = loadable(
	() => import(/* webpackPrefetch: true */ "./FaqBlock/FaqBlock"),
);
const FaqQuickLinks = loadable(
	() => import(/* webpackPrefetch: true */ "./FaqQuickLinks/FaqQuickLinks"),
);
const Question = loadable(
	() => import(/* webpackPrefetch: true */ "./Question/Question"),
);
const CmsLink = loadable(
	() => import(/* webpackPrefetch: true */ "./CmsLink/CmsLink"),
);
const OrderThankYou = loadable(
	() => import(/* webpackPrefetch: true */ "../OrderThankYou/OrderThankYou"),
);
const Trustpilot = loadable(
	() => import(/* webpackPrefetch: true */ "./Trustpilot/Trustpilot"),
);
const StorelocatorBlock = loadable(
	() =>
		import(
			/* webpackPrefetch: true */ "./StorelocatorBlock/StorelocatorBlock"
		),
);

const StorelocatorBlockLanding = loadable(
	() =>
		import(
			/* webpackPrefetch: true */ "./StorelocatorBlockLanding/StorelocatorBlockLanding"
			),
);
const FeaturedThemes = loadable(
	() => import(/* webpackPrefetch: true */ "./FeaturedThemes/FeaturedThemes"),
);
const SelfserviceBlock = loadable(
	() =>
		import(
			/* webpackPrefetch: true */ "./SelfserviceBlock/SelfserviceBlock"
		),
);
const GreenTicker = loadable(
	() => import(/* webpackPrefetch: true */ "./GreenTicker/GreenTicker"),
);
const AlertBox = loadable(
	() => import(/* webpackPrefetch: true */ "./AlertBox/AlertBox"),
);
const Zendesk = loadable(
	() => import(/* webpackPrefetch: true */ "./Zendesk/Zendesk"),
);

const Voys = loadable(
	() => import(/* webpackPrefetch: true */ "./Voys/Voys"),
);

const TextImageBlock = loadable(
	() => import(/* webpackPrefetch: true */ "./TextImageBlock/TextImageBlock"),
);

const LandingpageHeader = loadable(
	() => import(/* webpackPrefetch: true */ "./LandingpageHeader/LandingpageHeader"),
);

const QuickLink = loadable(
	() => import(/* webpackPrefetch: true */ "./LandingpageHeader/QuickLink/QuickLink"),
);

const UspSmall = loadable(
	() => import(/* webpackPrefetch: true */ "./UspSmall/UspSmall"),
);

const UspBig = loadable(
	() => import(/* webpackPrefetch: true */ "./UspBig/UspBig"),
);

const UspTile = loadable(
	() => import(/* webpackPrefetch: true */ "./UspBig/UspTile"),
);

export {
	Cmsblock,
	Hero,
	HeroBanner,
	HeroShowCase,
	LinkButton,
	Descriptor,
	BlockWithBlobImage,
	BlockWithBlobImageStoreLocator,
	OptionalContentBlock,
	FeaturePoint,
	BlockWithImageBgBlob,
	Slider,
	Slide,
	FeaturedTopics,
	Topic,
	CustomerService,
	PersonalTestimony,
	VideoComponent,
	CalloutWidget,
	ProductAssessment,
	BulletPoints,
	BuyDirect,
	Product,
	FeatureProducts,
	ProductTeaser,
	ExpertBlock,
	DoubleTextBlock,
	TextWithBlueBlock,
	TextBlueBlockAndImage,
	NarrowText,
	Products,
	CmsContentButton,
	CmsContentToHtml,
	ServiceBlock,
	CookieConsentDeclaration,
	ProductAddToCart,
	FaqBlock,
	FaqQuickLinks,
	Question,
	CmsLink,
	OrderThankYou,
	Trustpilot,
	StorelocatorBlock,
	StorelocatorBlockLanding,
	FeaturedThemes,
	SelfserviceBlock,
	GreenTicker,
	AlertBox,
	ContactColumn,
	Zendesk,
	Voys,
	TextImageBlock,
	LandingpageHeader,
	QuickLink,
	UspSmall,
	UspBig,
	UspTile,
};
