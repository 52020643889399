import ActionTypes from "../store/types/actionTypes";

const initialState = {
	success: false,
	orderHistory: [],
	page: 1,
	total: 0,
	pages: 0,
};
const attributeSets = {
	LOANORDER: "Lend",
	RENTALORDER: "Rent",
};
const ordersReducer = (state = initialState, action: any): any => {
	switch (action.type) {
		case ActionTypes.FETCH_CUSTOMER_ORDERS_SUCCESS: {
			const { orders, success } = action.payload;
			const { items, pages, total_items, current_page } = orders;
			return {
				...state,
				success: success,
				orderHistory: items,
				page: current_page,
				total: total_items,
				pages: pages,
			};
		}
		case ActionTypes.FETCH_ORDER_BY_ID_FAILED:
		case ActionTypes.FETCH_CUSTOMER_ORDERS_FAILED: {
			const { success } = action.payload;
			return {
				...state,
				success: success,
			};
		}
		case ActionTypes.FETCH_ORDER_BY_ID_SUCCESS: {
			const { order, index, success } = action.payload;
			const orders = [...state.orderHistory];
			const selectedOrder = orders[index];

			selectedOrder?.products.map((val, key) => {
				order?.orderLines.map((val2) => {
					const productAttr = val.product_attribute_set_name;
					if (productAttr !== "Buy") {
						const orderType = attributeSets[val2.orderType];
						const explodedSku = val.product_sku.split("-");
						if (
							explodedSku[0] === val2.productId &&
							productAttr === orderType
						) {
							orders[index].products[key].details = val2;
						}
					} else {
						if (val.product_sku === val2.productId) {
							orders[index].products[key].details = val2;
						}
					}
				});
			});
			return {
				...state,
				success: success,
				orderHistory: orders,
			};
		}
		default:
			return state;
	}
};
export default ordersReducer;
