import ActionTypes from "../store/types/actionTypes";
import { getStorageItem, StorageKeys } from "../common";

const initialState = {
	cartInfo: {},
	totals: {},
	isPending: true,
};

function cartReducer(state = initialState, action: any) {
	switch (action.type) {
		case ActionTypes.CART_LOAD_PENDING:
			return {
				...state,
				cartInfo: {},
				isPending: true,
			};
		case ActionTypes.CART_LOAD_SUCCESS: {
			return {
				...state,
				cartInfo: action.payload.cartInfo,
				isPending: false,
			};
		}
		case ActionTypes.CART_DELETE_SUCCESS:
			return {
				...state,
				previousCartInfo: JSON.parse(
					getStorageItem(StorageKeys.CART_INFO),
				),
			};
		case ActionTypes.EMPTY_PREVIOUS_CART_INFO:
			return {
				...state,
				previousCartInfo: {},
			};
		case ActionTypes.UPDATE_PREVIOUS_CART:
			return {
				...state,
				previousCartInfo: action.payload.previousCartInfo,
			};
		case ActionTypes.UPDATE_CART_TOTAL:
			return {
				...state,
				totals: action.payload.totals,
			};
		default:
			return state;
	}
}
export default cartReducer;
