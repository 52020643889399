import { nodeAPI } from "../services/abstract.service";

export const fetchAllAssociations = () => {
	return async (): Promise<any> => {
		return new Promise((resolve, reject) => {
			nodeAPI
				.get("association")
				.then((response) => {
					resolve(response);
				})
				.catch((error) => {
					reject(error);
				});
		});
	};
};

export const checkAssociationPDP = (
	organisationID: string,
	productID: string,
) => {
	return new Promise((resolve, reject) => {
		nodeAPI
			.get(`/association/product/access/${organisationID}/${productID}`)
			.then((response) => {
				resolve(response);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

export const fetchAssociationsByPostalCode = (postalCodeNumber: string) => {
	return async (): Promise<any> => {

		return new Promise((resolve, reject) => {
			nodeAPI
				.get(`association?postcode=${postalCodeNumber}`)
				.then((response) => {
					resolve(response);
				})
				.catch((error) => {
					reject(error);
				});
		});
	};
};

export const validateAssociationWithPostalCode = (values: any) => {
	return async (): Promise<any> => {

		return new Promise((resolve, reject) => {
			nodeAPI
				.post("association", values)
				.then((response) => {
					resolve(response);
				})
				.catch((error) => {
					reject(error);
				});
		});
	};
};
