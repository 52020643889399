import { Form, Formik, FormikProps } from "formik";
import React, {
	Dispatch,
	ReactElement,
	SetStateAction,
	useContext,
	useEffect,
	useState,
	useCallback,
	useRef,
} from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { ProductQuantity } from "../..";
import {
	getLoggedInUserInfo,
	getStorageItem,
	isBusiness,
	isPartner,
	isProductOutOfStock,
	ProductType,
	ProductTypeIds,
	sortObjectByKey,
	StorageKeys,
} from "../../../../common/common";
import {
	ConfigurableProductOptions,
	ProductInterface,
} from "../../../Pages/Catalog/catalog-interfaces";
import * as Yup from "yup";
import ConfigurableProduct from "../../Product/ConfigurableProduct/ConfigurableProduct";
import ProductPricing from "../../Product/ProductInfo/ProductPricing";
import { ObjectProps } from "../../../../common/interfaces/common.interfaces";
import { ProductDetailContext } from "../../../../common/contexts";
import GroupedItem from "./GroupedItem";
import { useDispatch } from "react-redux";
import { useLoadingEndpoints } from "../../../../common/common-hooks";
import { Skeleton } from "@mui/material";
import ProductCtaButtons from "../../Buttons/ProductCtaButtons/ProductCtaButtons";

type QuantityAndCartProps = {
	product: ProductInterface;
	quantity: number;
	setQuantity: Dispatch<SetStateAction<number>>;
	maxAllowedQty?: number;
	clickHandler: (e: any) => void;
	disableButton?: boolean;
	detailDisplay?: boolean;
	productAttributeChange?: (data: ObjectProps<string>) => void;
	isBedVerhogers?: boolean;
	linkedProducts?: {
		sku: any;
		name: string;
		media_gallery_entries: any;
		extension_attributes: any;
		price: number;
		custom_attributes: any;
	}[];
	setAddedToCart?: (data: ObjectProps<string>) => void;
	loaderStart?: any;
	loaderStop?: any;
	categories?: any;
	showAddedModal?: (data: boolean) => void;
	setEnableButton?: (data: boolean) => void;
};

const QuantityAndCart = (props: QuantityAndCartProps): ReactElement => {
	const { t } = useTranslation();
	const {
		product,
		detailDisplay = true,
		isBedVerhogers,
		setAddedToCart,
		setEnableButton,
		categories,
		productAttributeChange,
		loaderStop,
		showAddedModal,
		clickHandler
	} = props;
	const [validateSchema, setValidateSchema] = useState({});
	const [quantity, setQuantity] = useState(0);
	const [disableAddToCart, setDisabledAddToCart] = useState(false);
	const cartInfo = JSON.parse(getStorageItem(StorageKeys.CART_INFO));
	const [maxAllowedQty, setMaxAllowedQty] = useState(0);
	const [minAllowedQty, setMinAllowedQty] = useState(0);
	const [groupedState, setGroupedState] = useState([]);
	const [configHasBeenSelected, setConfigHasBeenSelected] = useState(false);
	const [configProduct, setConfigProduct] = useState<any>(product);
	const userInfo = getLoggedInUserInfo();
	const feeLabel = userInfo?.feeLabel;
	const evean = feeLabel?.toLowerCase().includes("evean");
	const { isOutOfStock, setIsOutOfStock } = useContext(ProductDetailContext);
	const [groupProduct, setGroupProduct] = useState<
		unknown | any | undefined
	>();
	const dispatch = useDispatch();
	const [addedGroup, setAddedGroup] = useState([]);
	const [isShopOnly, setIsShopOnly] = useState<boolean>(false);
	const { loadingGetSingleProduct } = useLoadingEndpoints(); // Use the useLoadingEndpoints hook
	const formRef = useRef<FormikProps<any>>(null);

	useEffect(() => {
		if (product?.sku) {
			setConfigProduct(product);
		}
	}, [product?.sku]);

	useEffect(() => {
		if (isBedVerhogers) {
			setQuantity(4);
			setMinAllowedQty(4);
			setMaxAllowedQty(24);
		} else {
			setMinAllowedQty(1);
			setQuantity(1);
		}
		if (setIsOutOfStock) {
			setIsOutOfStock(isProductOutOfStock(product));
		}
		setDisabledAddToCart(false);
	}, [product, isBedVerhogers, setIsOutOfStock]);

	useEffect(() => {
		if (addedGroup.length > 0) {
			setAddedToCart(addedGroup);
		}
	}, [addedGroup, setAddedToCart]);

	useEffect(() => {
		if (configProduct && configProduct?.custom_attributes) {
			let maxAllowedQuantity = 1000;
			let minAllowedQuantity = 1;
			const attributeValue = configProduct.custom_attributes.max_order;
			const inStockQty =
				configProduct.extension_attributes.stock_item.qty;
			if (isBedVerhogers) {
				maxAllowedQuantity = 24;
				minAllowedQuantity = 4;
			} else if (attributeValue) {
				const val = Number(attributeValue);
				maxAllowedQuantity =
					val === 0 || val > inStockQty ? inStockQty : val;
			}

			if (cartInfo?.items && cartInfo.items.length && quantity) {
				const productInCart = cartInfo.items.find(
					// Bugfix: Config products are added to the cart with their main SKU,
					// not option SKU so we should check for the main product SKU as well
					(item) =>
						item.sku === configProduct.sku ||
						item.sku === product.sku,
				);
				if (productInCart) {
					if (maxAllowedQuantity === productInCart.quantity) {
						maxAllowedQuantity = -1;
					} else if (maxAllowedQuantity > 1) {
						maxAllowedQuantity =
							maxAllowedQuantity - productInCart.quantity;
					}
					setDisabledAddToCart(
						productInCart.quantity + quantity > maxAllowedQuantity,
					);
				}
			}
			if (quantity < minAllowedQuantity) {
				setQuantity(minAllowedQuantity);
			}
			setMinAllowedQty(minAllowedQuantity);
			setMaxAllowedQty(maxAllowedQuantity);
		}
	}, [product, configProduct, cartInfo, quantity, isBedVerhogers]);

	const stockClass = classNames(
		"fw-bold my-1 text-uppercase",
		!isOutOfStock && "text-primary text-uppercase",
		detailDisplay,
	);

	const isThuisservice = (): boolean =>
		product?.custom_attributes?.delivery_type === "Thuisservice";

	const isConfigurableProduct = useCallback((): boolean => {
		return product.type_id === ProductTypeIds.CONFIGURABLE;
	}, [product.type_id]);

	const getConfigureSimpleProductMinPrice =
		useCallback((): ProductInterface => {
			const simpleProduct = sortObjectByKey(
				product.configurable_attributes,
				"price",
			)[0];
			return simpleProduct || product;
		}, [product.configurable_attributes, product]);

	useEffect(() => {
		if (isConfigurableProduct()) {
			const configurableOptionSchema = {};
			product.extension_attributes?.configurable_product_options.forEach(
				(option) => {
					configurableOptionSchema[option.attribute_code] =
						Yup.string().test(
							"configurable-option-validator",
							t("form.validation.required"),
							(value) => value,
						);
				},
			);
			setValidateSchema(configurableOptionSchema);
		}
	}, [isConfigurableProduct, product.extension_attributes, t]);

	useEffect(() => {
		if (isConfigurableProduct()) {
			setConfigProduct(getConfigureSimpleProductMinPrice());
		}
	}, [getConfigureSimpleProductMinPrice, isConfigurableProduct]);

	const getDeliveryOptionLabel = (): string => {
		return product?.custom_attributes?.delivery_options_new;
	};

	const getConfigurableProductOptions = (
		prod: ProductInterface,
	): Array<ConfigurableProductOptions> => {
		return sortObjectByKey(
			prod?.extension_attributes?.configurable_product_options,
			"position",
		);
	};

	const getConfigurableProductOptionPayload = (
		values: ObjectProps<string>,
	): Array<ObjectProps<string>> => {
		const options = getConfigurableProductOptions(product);
		const payload = [];
		const arrValues = Object.keys(values);
		options.forEach((option) => {
			if (arrValues.includes(option.attribute_code)) {
				payload.push({
					option_id: option.attribute_id,
					option_value: values[option.attribute_code].toString(),
				});
			}
		});
		return payload;
	};

	const addGroupToCart = async (): Promise<void> => {
		await clickHandler(groupedState)
			.then(() => dispatch(loaderStop));
	};

	const getCartItems = (): any => {
		const cart = JSON.parse(getStorageItem(StorageKeys.CART_INFO));
		const items = cart.items.slice(-4);
		const grouped = groupedState.filter(
			(product) => product.quantity !== 0,
		);

		// Creating a new array based on data from the cart items, but updated quantities of newly added items
		// We take and open items and grouped and reduce them to
		// create a new array with combined keys and values from both arrays.
		const merged = [...items, ...grouped].reduce(
			(
				accumulator,
				{
					sku,
					quantity,
					image_url,
					price,
					buy_sku_id,
					product_type,
					custom_attributes,
					loan_sku_id,
					free_rent_sku_id,
					name,
				},
			) => {
				const item = accumulator.find((q) => q.sku === sku);
				if (item) item.quantity = quantity;
				else
					accumulator.push({
						sku,
						quantity,
						image_url,
						price,
						buy_sku_id,
						product_type,
						custom_attributes,
						loan_sku_id,
						free_rent_sku_id,
						name,
					});
				return accumulator;
			},
			[],
		);
		// Due to the array of objects still containing
		// the zero values of the zero-able product, we need to filter those out again
		// We create a new SKUS array
		const skus = grouped.map((e) => e.sku);
		// And then we finalize the selection by filtering out the SKUs that aren't in the groupedState
		const final = merged.filter((e) => skus.includes(e.sku));
		return final;
	};

	const submitHandler = (): void => {
		const { values, setSubmitting, resetForm } = formRef.current;

		if (product.type_id === "grouped") {
			addGroupToCart().then(() => {
				showAddedModal(true);
				setAddedGroup(getCartItems());
			});
		} else {
			let payload = {
				quantity: quantity,
				sku: product?.sku,
			};

			if (isConfigurableProduct()) {
				const getConfigSku = (): ObjectProps<string> => {
					const { name: variantName } = configProduct;
					let variantId;
					switch (configProduct.product_type) {
						case ProductType.LOAN:
							variantId = configProduct.loan_sku_id;
							break;
						case ProductType.BUY:
							variantId = configProduct.buy_sku_id;
							break;
						case ProductType.RENT:
							variantId = configProduct.rent_sku_id;
							break;
					}

					return {
						variantId,
						variantName,
					};
				};

				payload = {
					...payload,
					product_option: {
						extension_attributes: {
							configurable_item_options:
								getConfigurableProductOptionPayload(values),
						},
					},
					...getConfigSku(),
				};
			}
			setSubmitting(false);
			resetForm();
			clickHandler(payload);
		}
	};

	const isButtonDisabled = (isSubmitting, dirty, errors): boolean => {
		const isEnabled =
			!configProduct?.is_active ||
			(isOutOfStock && !isPartner()) ||
			disableAddToCart ||
			isSubmitting ||
			(Object.keys(validateSchema).length > 0 && !dirty) ||
			Object.keys(errors).length > 0;

		setEnableButton && setEnableButton(isEnabled);
		return isEnabled;
	};

	const getStockLabel = (isOutOfStock): any => {
		if (loadingGetSingleProduct) {
			return <Skeleton animation="wave" width="50%" height="39px" />;
		} else {
			switch (isOutOfStock) {
				case true:
					if (isPartner()) {
						return t("product_detail.outOfStockButOrderable");
					} else if (isShopOnly) {
						// Check if product is only available in a store
						return t("product_detail.shopOnly");
					} else {
						return t("product_detail.outOfStock");
					}
				case false:
					if (isShopOnly) {
						// Check if product is only available in a store
						return t("product_detail.shopOnly");
					} else {
						return t("product_detail.inStock");
					}
			}
		}
	};

	const isOutOfStockDisplay = (): React.ReactElement => {
		return (
			<div className={`${stockClass}`}>{getStockLabel(isOutOfStock)}</div>
		);
	};

	const updateProductStatus = useCallback(
		(newProduct, configHasBeenSelected): void => {
			let hasOutOfStock;
			if (isConfigurableProduct()) {
				if (configHasBeenSelected) {
					hasOutOfStock = isProductOutOfStock(newProduct);
				} else {
					hasOutOfStock = product.configurable_attributes.every(
						(prod) => {
							return isProductOutOfStock(prod);
						},
					);
				}
			} else {
				hasOutOfStock = isProductOutOfStock(newProduct);
			}
			setQuantity(isBedVerhogers ? 4 : 1);
			if (setIsOutOfStock) {
				setIsOutOfStock(hasOutOfStock);
			}
		},
		[
			isConfigurableProduct,
			product.configurable_attributes,
			setIsOutOfStock,
		],
	);

	useEffect(() => {
		updateProductStatus(configProduct, configHasBeenSelected);
	}, [configHasBeenSelected, configProduct, updateProductStatus]);

	useEffect(() => {
		if (product.groupedProducts) {
			setGroupProduct([...product.groupedProducts]);
			setGroupedState(
				product.groupedProducts.map((product) => ({
					sku: product.sku,
					quantity: product.groupedDefaultQty,
				})),
			);
		}
	}, [product]);

	useEffect(() => {
		// Check if product is only available in a shop
		if (!isBusiness()) {
			// Configurable products
			if (
				product?.custom_attributes?.shop_only &&
				product?.custom_attributes?.shop_only === "1"
			) {
				setIsShopOnly(true);
			} else {
				setIsShopOnly(false);
			}
		}
	}, [product]);

	return (
		<Formik
			initialValues={{}}
			validationSchema={Yup.object().shape(validateSchema)}
			onSubmit={submitHandler}
			innerRef={(instance): void => {
				formRef.current = instance;
			}}
		>
			{({
				isSubmitting,
				errors,
				dirty,
				handleSubmit
			}): React.ReactElement => {
				return (
					<Form id="couponForm" name="couponForm">
						<>
							{/*----------- CONFIGURABLE PRODUCT ------------*/}
							<ConfigurableProduct
								product={product}
								productAttributeChange={(newProduct): void => {
									setConfigHasBeenSelected(true);
									setConfigProduct(newProduct);
									productAttributeChange &&
										productAttributeChange(
											newProduct,
										);
									updateProductStatus(newProduct, true);
								}}
								resetProductHandler={(): void => {
									setConfigHasBeenSelected(false);
									setConfigProduct(
										getConfigureSimpleProductMinPrice(),
									);
									updateProductStatus(product, false);
								}}
							/>
							{/* ------------- PRODUCT PRICING --------------*/}
							<div className="d-flex justify-content-between align-items-end">
								{product.type_id !== "grouped" ? (
									<ProductPricing product={configProduct} />
								) : (
									<div className="mt-4 mb-4 d-flex flex-column w-100">
										{groupProduct &&
											groupProduct
												.sort(
													(a, b) =>
														a.position - b.position,
												)
												.map((e, i) => {
													return (
														<GroupedItem
															key={i}
															index={e?.position}
															minQuantity={
																e?.groupedDefaultQty
															}
															increment={
																e
																	.extension_attributes
																	?.stock_item
																	?.qty_increments ||
																1
															}
															maxQuantity={
																e
																	.extension_attributes
																	?.stock_item
																	?.max_sale_qty
															}
															sku={e.sku}
															groupedState={
																groupedState
															}
															setGroupedState={
																setGroupedState
															}
															name={e.name}
															customAttributes={
																e.custom_attributes
															}
															mediaGalleryEntries={
																e.media_gallery_entries
															}
															price={e.price}
															product={e}
														/>
													);
												})}
									</div>
								)}
								{(!product?.custom_attributes?.category_ids.includes(
									"16",
								) ||
									!evean) &&
									!isOutOfStock &&
									!isThuisservice() && (
										<div className="quantity-counter">
											<div className="product-info-qty">
												<ProductQuantity
													updateQuantity={setQuantity}
													qty={quantity}
													minAllowedQuantity={
														minAllowedQty
													}
													maxAllowedQuantity={
														maxAllowedQty
													}
												/>
											</div>
										</div>
									)}
							</div>
							{/* ---- OUT OF STOCK -----*/}
							{product?.custom_attributes?.category_ids.includes(
								"16",
							) && evean ? (
								<strong className="out-of-stock">
									{t("product_detail.evean")}
								</strong>
							) : (
								<div className="stock-info mb-1">
									{product.type_id !== "grouped" &&
										isOutOfStockDisplay()}
								</div>
							)}
							<ProductCtaButtons
								quantity={quantity}
								product={product}
								configProduct={configProduct}
								isShopOnly={isShopOnly}
								isDisabled={isButtonDisabled(
									isSubmitting,
									dirty,
									errors,
								)}
								categories={categories}
								type="submit"
								onSubmit={handleSubmit}
							/>
							{(!product?.custom_attributes?.category_ids.includes("16",) ||
								!evean) && (
								<div className="btn-delivery-info">
									{!isThuisservice() && !product?.custom_attributes?.thuisservice && (
										<div className="d-block my-3_5 grouped-delivery-info">
											<div className="grouped-left">
												{product.type_id === "grouped" && !loadingGetSingleProduct && (
													<div className="delivery-label">
														<label>
															{`${t(
																"product_detail.deliveryOptionLabel",
															)} ${getDeliveryOptionLabel()}`}
														</label>
													</div>
												)}
											</div>
										</div>
									)}
									{product.type_id ===
										"grouped" && (
											<div className="grouped-right">
												{/* -------------- USPs -----------------*/}
												{product.type_id === "grouped" &&
												product?.custom_attributes?.usp?.length > 0 ? (
													<div className="usp-list mt-md-4 mt-lg-0 grouped-usps">
														<ul>
															{product?.custom_attributes?.usp.map(
																(
																	val,
																	key,
																) => {
																	return (
																		<li
																			key={
																				key
																			}>
																			{
																				val
																			}
																		</li>
																	);
																},
															)}
														</ul>
													</div>
												) : (
													<div className="usp-list mt-md-4 mt-lg-0 grouped-usps">
														<ul>
															<li>
																Gratis
																verzending
																vanaf €50,-
															</li>
															<li>
																Gratis
																retour
																binnen 14
																dagen
																(m.u.v.
																hygiëne
																artikelen)
															</li>
															<li>
																Grootste
																assortiment
																van
																Nederland
															</li>
														</ul>
													</div>
												)}
											</div>
										)}
									{/* --------- Delivery label --------*/}
									{product.type_id !== "grouped" &&
										!isThuisservice() &&
										getDeliveryOptionLabel() &&
										!loadingGetSingleProduct && (
											<div className="delivery-label">
												<label>
													{`${t(
														"product_detail.deliveryOptionLabel",
													)} ${getDeliveryOptionLabel()}`}
												</label>
											</div>
										)}
								</div>
							)}
						</>
					</Form>
				);
			}}
		</Formik>
	);
};

export default QuantityAndCart;
