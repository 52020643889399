const labelClass = "mb-0 text-break";
const attrRegex = /(\S+)=["']?((?:.(?!["']?\s+(?:\S+)=|\s*\/?[>"']))+.)["']?/;
const htmlFromTag = (result: RegExpExecArray, html: string): string => {
	const clsLst: string[] = [];
	const tagReg = /[h 1-6]{2}/g;
	const tagLst = tagReg.exec(html);
	const tagname = tagLst !== null ? tagLst[0] : "";
	clsLst.push(labelClass);
	if (result[1] != "") {
		let linkRes;
		let newHtml = result[1];
		while ((linkRes = attrRegex.exec(newHtml)) !== null) {
			if (linkRes[1] === "class") clsLst.push(linkRes[2]);
			newHtml = newHtml?.replace(linkRes[0], "");
		}
	}
	return `<${tagname} class="${clsLst.join(" ")}">${result[2]}</${tagname}>`;
};
const stringToContent = (regParser: RegExp, html: string): string => {
	let result;
	let data = html;
	while ((result = regParser.exec(html)) !== null) {
		data = htmlFromTag(result, html);
	}
	return data;
};
const CmsTags = [
	{
		regex: /\[b](.*?)\[\/b]/g,
		replacer: "<b>$1</b>",
	},
	{
		regex: /\[u](.*?)\[\/u]/g,
		replacer: "<u>$1</u>",
	},
	{
		regex: /\[i](.*?)\[\/i]/g,
		replacer: "<i>$1</i>",
	},
	{
		regex: /\[br\/]/g,
		replacer: "<br/>",
	},
	{
		regex: /\[?h[1-6](.*?)](.*?)\[\/?h[1-6]]/g,
		replacer: stringToContent,
	},
	{
		regex: /<style (.*?)>(.*?)<\/style>/g,
		replacer: " ",
	},
	{
		regex: /\[link (.*?)\](.*?)\[\/link]/g,
		replacer: (reg: RegExp, html: string): string => {
			let result;
			while ((result = reg.exec(html)) !== null) {
				const linkContent = result[2];
				let url;
				let linkRes;
				let newHtml = result[1];
				const props: string[] = [];
				while ((linkRes = attrRegex.exec(newHtml)) !== null) {
					if (linkRes[1] === "url") {
						url = linkRes[2];
						if (url && !url.startsWith("http")) {
							props.push(`to="${url}"`);
						} else {
							props.push(`href="${url}"`);
						}
					} else if (linkRes[1] === "target") {
						props.push(`target="${linkRes[2]}"`);
					}
					newHtml = newHtml?.replace(linkRes[0], "");
				}
				const Comp = url && !url.startsWith("http") ? "CmsLink" : "a";
				const comContent = `<${Comp} ${props.join(
					" ",
				)}><u>${linkContent}</u></${Comp}>`;
				html = html.replace(result[0], comContent);
			}
			return html;
		},
	},
];

export const replaceCmsTagsWithHtmlTags = (stringHTML: string): string => {
	let html = stringHTML;
	CmsTags.forEach((reg) => {
		if (typeof reg.replacer === "function") {
			html = reg.replacer(reg.regex, html);
		} else {
			html = html?.replace(reg.regex, reg.replacer);
		}
	});
	return html;
};
