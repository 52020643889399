import React, { lazy, ReactElement, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
	getCatalogImage,
	GetEcommerceData,
	getLoggedInUserInfo,
	getProductUrl,
	gtmEventPublish,
	isBusiness,
	isItemOutOfStock,
	isMobile, isPartner,
	Roles,
} from "../../../../common/common";
import {
	ProductTileProps,
} from "../../../Pages/Catalog/catalog-interfaces";
import TextEllipsis from "../../TextEllipsis/TextEllipsis";
import { ProductModal } from "..";
import { useTranslation } from "react-i18next";
import ProductPricing from "../../Product/ProductInfo/ProductPricing";
import { connect } from "react-redux";
import * as cartActions from "../../../../common/actions/cart";

const ProductBuyType = lazy(() => import("../ProductTile/ProductBuyType"));

const mapStateToProps = () => {
	return {};
};

const connector = connect(mapStateToProps, {
	...cartActions,
});

const ProductListView = (props: ProductTileProps): ReactElement => {
	const [show, setShow] = useState(false);
	const [addedToCart, setAddedToCart] = useState(false);
	const image = props.product.media_gallery_entries[0];
	const { t } = useTranslation();
	const urlRewrite = props?.product.extension_attributes?.url_rewrite
		? props.product.extension_attributes.url_rewrite[0]
		: { id: "0" };	const navigate = useNavigate();
	const userData = getLoggedInUserInfo();

	// get allowed quantity to be added to cart
	const [quantity, setQuantity] = useState(props.isBedVerhogers ? 4 : 1);
	const [_maxAllowedQty, setMaxAllowedQty] = useState(0);
	const [_minAllowedQty, setMinAllowedQty] = useState(0);
	const [isShopOnly, setIsShopOnly] = useState<boolean>(false);

	useEffect(() => {
		const maxOrder = props.product?.custom_attributes?.max_order;

		if (props.isBedVerhogers) {
			setQuantity(4);
			setMinAllowedQty(4);
			setMaxAllowedQty(24);
		} else {
			setMinAllowedQty(1);
			setQuantity(1);
			setMaxAllowedQty(maxOrder > 0 ? maxOrder : 1);
		}
	}, [props.isBedVerhogers, props.product]);

	useEffect(() => {
		// Check if product is only available in a shop
		if (!isBusiness()){
			// Configurable products
			if (props.product?.custom_attributes?.shop_only && props.product?.custom_attributes?.shop_only === "1") {
				setIsShopOnly(true);
			} else {
				setIsShopOnly(false);
			}
		}
	}, [props.product]);

	// out of stock
	const isOutOfStock = isItemOutOfStock(props.product) && !isPartner();

	// Function to remove any lingering HTML from string
	const removeTags = (str) => {
		if (str === null || str === "") return false;
		else str = str?.toString();
		// Regular expression to identify HTML tags in
		// the input string, replacing the identified
		// HTML tag with a null string.
		return str?.replace(/(<([^>]+)>)/gi, "");
	};

	// Product short description to be used in ListView
	const shortDescription =
		props.product?.custom_attributes?.short_description;
	const filteredShortDescription = removeTags(shortDescription);
	const finalShortDescription =
		filteredShortDescription?.substring(0, 146) + "...";

	return (
		<div className={"product-list-view d-flex justify-content-between"}>
			<div className="d-flex w-100">
				<div className="product-list-view__type-attributes d-flex justify-content-end">
					{!props.button && (
						<ProductBuyType
							attributes={props.attributes.filter(
								(attribute) =>
									props.product.product_type ===
									attribute.value,
							)}
							productType={props.product?.product_type}
						/>
					)}
				</div>
				<div
					className={
						"product-list-view__content d-flex align-items-center justify-content-center w-100"
					}
				>
					<Link
						className="d-flex"
						to={
							getProductUrl(props.product)
						}
						onClick={
							!isMobile()
								? (): void => {
										props.button &&
											props.closeCartModal &&
											props.closeCartModal();
										gtmEventPublish(
											"select_item",
											GetEcommerceData(
												[props?.product],
												[],
												props?.categories,
												"select_item",
											),
										);
								  }
								: null
						}
					>
						<div
							className={
								"product-list-view__image d-flex align-items-center justify-content-center me-4"
							}
						>
							{/* Image */}
							{image ? (
								<img
									key={image.id}
									src={getCatalogImage(
										image.url || image.file,
									)}
									alt={props.product.name}
									width="100%"
									height="auto"
								/>
							) : (
								<img
									alt={props.product.name}
									width={143}
									height={164}
								/>
							)}
						</div>
					</Link>
					<div className="mb-2 list-content w-100">
						<TextEllipsis
							className={"product-list-view__title mt-4"}
						>
							<Link
								to={
									getProductUrl(props.product)
								}
								onClick={(): void => {
									if (window.dataLayer) {
										// eslint-disable-next-line @typescript-eslint/ban-ts-comment
										// @ts-ignore
										window.dataLayer.push({
											ecommerce: null,
										});
									}
									gtmEventPublish(
										"select_item",
										GetEcommerceData(
											[props?.product],
											[],
											props?.categories,
											"select_item",
										),
									);
								}}
							>
								{props.product.name}
							</Link>
						</TextEllipsis>
						<div className="pricing">
							{props.product.product_type === "free_rental" ? (
								props.product.price === 0 ? (
									"Gratis"
								) : (
									<ProductPricing
										onTile
										product={props.product}
									/>
								)
							) : (
								<ProductPricing
									onTile
									product={props.product}
								/>
							)}
							<div className="short-description">
								{finalShortDescription}
							</div>
						</div>
						<div className="d-flex align-items-center justify-content-between">
							<div className="more-info">
								<Link
									to={
										getProductUrl(props.product)
									}
									onClick={(): void => {
										navigate(getProductUrl(props.product));
										if(window.dataLayer){
											// eslint-disable-next-line @typescript-eslint/ban-ts-comment
											// @ts-ignore
											window.dataLayer.push({
												ecommerce: null,
											});
										}
										gtmEventPublish(
											"select_item",
											GetEcommerceData(
												[props?.product],
												[],
												props?.categories,
												"select_item",
											),
										);
									}}
									className="more-info"
								>
									{t("common.productMoreInfo")}
								</Link>
							</div>
							{isShopOnly && (
								<button
									type="button"
									onClick={() => {
										window.location.href = "/locaties";
									}}
									className={
										addedToCart
											? "btn btn-primary added"
											: "btn btn-primary"
									}
								>
									{t("common.findStoreBtnLabel")}
								</button>
							)}
							{props.button && !isShopOnly ? (
								props.product.type_id === "simple" ? (
									<button
										type="button"
										onClick={() => {
											props.addToCart({
												sku: props.product.sku,
												quantity: quantity,
											});
											setAddedToCart(true);
										}}
										disabled={addedToCart}
										className={
											addedToCart
												? "btn btn-primary added"
												: "btn btn-primary"
										}
									>
										{addedToCart
											? "Toegevoegd"
											: props.button}
									</button>
								) : (
									<button
										type="button"
										onClick={() => {
											userData?.role ==
											Roles.CARE_PROFESSIONAL
												? setShow(true)
												: navigate(getProductUrl(props.product));
											props.closeCartModal();
										}}
										className="btn btn-primary added"
									>
										{props.button}
									</button>
								)
							) : null}
							{/* When isOutOfStock is true, show "out of stock" text*/}
							{/* If false, show CTA button*/}
							{isOutOfStock && !isShopOnly && (
								<div className={"out-of-stock"}>
									{props.product.type_id !== "configurable" &&
										isOutOfStock &&
										t("product_detail.outOfStock")}
								</div>
							)}
							{/* "Stel samen" button for configurable products */}
							{props.product.type_id === "configurable" && !isShopOnly && (
								<button
									type="button"
									onClick={(): void =>
										navigate(getProductUrl(props.product))
									}
									className="btn btn-default text-truncate"
								>
									{t("common.buttons.configurableButton")}
								</button>
							)}
							{/* When IN stock and simple product */}
							{!isOutOfStock && !isShopOnly &&
								props.product.type_id !== "configurable" && (
									<>
										{props.product.type_id === "simple" ? (
											<button
												type="button"
												onClick={() => {
													props.addToCart({
														sku: props.product.sku,
														quantity: quantity,
													});
													setAddedToCart(true);
												}}
												disabled={addedToCart}
												className={
													addedToCart
														? "btn btn-primary cross-sell added"
														: "btn btn-primary"
												}
											>
												{addedToCart
													? "Toegevoegd"
													: t(
															"common.addToCartBtnLabel",
													  )}
											</button>
										) : (
											<button
												type="button"
												onClick={() => {
													userData?.role ==
													Roles.CARE_PROFESSIONAL
														? setShow(true)
														: navigate(getProductUrl(props.product));
													props.closeCartModal();
												}}
												className="btn btn-primary text-truncate cross-sell"
											>
												{props.button}
											</button>
										)}
									</>
								)}
						</div>
						{show && (
							<ProductModal
								sku={urlRewrite?.id}
								onHide={(): void => setShow(false)}
								show={show}
								callbackHandler={(buyOptions): void => {
									props.callback &&
										props.callback({
											buyOptions,
											callback: () => setShow(false),
										});
								}}
							/>
						)}
					</div>
				</div>
			</div>
			{!props.button &&
				props.product?.custom_attributes?.delivery_options_new && (
					<div className={"delivery"}>
						{props.product.custom_attributes.delivery_options_new}
					</div>
				)}
		</div>
	);
};

export default connector(ProductListView);
