import { Dispatch } from "redux";
import {
	UPDATE_CHECKOUT_ORDER,
	UPDATE_CHECKOUT_STEP,
	CLEAR_CHECKOUT_ORDER,
	SET_CHECKOUT_ORDER_ID,
	SET_CHECKOUT_ISECD
} from "../types/actionTypes";
import { Order } from "../types/checkoutTypes";
import { initialState } from "../reducers/checkoutReducers";
import { InsuranceIndicationState } from "../types/insuranceIndicationTypes";

export const updateOrder = (
	order: Order,
	step: number,
	checkoutName : "business" | "consumer",
	insuranceIndicationInformation: InsuranceIndicationState
) =>
	async (dispatch: Dispatch) => {
		try {
			dispatch( {
				type: UPDATE_CHECKOUT_ORDER,
				name: checkoutName,
				payload: {
					order: order,
					step: step,
					insuranceIndicationInformation: insuranceIndicationInformation
				}
			});
		} catch {
			console.error("Unable to update order");
		}
	};

export const updateStep = (step: number, completedList: number[], checkoutName : "business" | "consumer" ) =>
	async (dispatch: Dispatch) => {
		window.scrollTo(0, 0);
		try {
			dispatch( {
				type: UPDATE_CHECKOUT_STEP,
				name: checkoutName,
				payload: {
					step: step,
					completedList: completedList,
				}
			});
		} catch {
			console.error("Unable to update step");
		}
	};

export const setOrderId = (orderId: string, checkoutName : "business" | "consumer" ) =>
	async (dispatch: Dispatch) => {
		try {
			dispatch( {
				type: SET_CHECKOUT_ORDER_ID,
				name: checkoutName,
				payload: {
					orderId: orderId
				}
			});
		} catch {
			console.error("Unable to update order id");
		}
	};

export const setIsECD = (isECD: boolean, ecdClientId: string, ecdClientCode: string) =>
	async (dispatch: Dispatch) => {
		try {
			dispatch( {
				type: SET_CHECKOUT_ISECD,
				name: "business",
				payload: {
					ecd: {
						isEcd: isECD,
						ecdClientId: ecdClientId,
						ecdClientCode: ecdClientCode
					}
				}
			});
		} catch {
			console.error("Unable to set ECD data");
		}
	};

export const clearOrder = (checkoutName : "business" | "consumer", step = 0) =>
	async (dispatch: Dispatch) => {
		try {
			dispatch({
				type: CLEAR_CHECKOUT_ORDER,
				name: checkoutName,
				payload: {
					order: initialState.order,
					step: step,
					completedList: [],
					orderId: "",
					insuranceIndicationInformation: null,
					ecd: {
						isEcd: false,
						ecdClientId: "",
						ecdClientCode: ""
					}
				}
			});
		} catch {
			console.error("Unable to clear order");
		}
	};
