const ActionTypes = {
	LOGIN_SUCCESS: "LOGIN_SUCCESS",
	LOGIN_ERROR: "LOGIN_ERROR",
	LOGIN_PENDING: "LOGIN_PENDING",
	CHANGE_PASSWORD_SUCCESS: "CHANGE_PASSWORD_SUCCESS",
	CHANGE_PASSWORD_ERROR: "CHANGE_PASSWORD_ERROR",
	CHANGE_PASSWORD_PENDING: "CHANGE_PASSWORD_PENDING",
	FORGOT_PASSWORD_SUCCESS: "FORGOT_PASSWORD_SUCCESS",
	FORGOT_PASSWORD_ERROR: "FORGOT_PASSWORD_ERROR",
	UPDATE_FIELD_VALUE: "UPDATE_FIELD_VALUE",
	UPDATE_PRODUCT_QUANTITY: "UPDATE_PRODUCT_QUANTITY",

	CATEGORY_GET_ALL: "CATEGORY_GET_ALL",
	CATEGORY_GET_ALL_PENDING: "CATEGORY_GET_ALL_PENDING",
	CATEGORY_GET_ALL_SUCCESS: "CATEGORY_GET_ALL_SUCCESS",
	SET_SELECTED_CATEGORY_PATH: "SET_SELECTED_CATEGORY_PATH",

	CART_LOAD_ERROR: "CART_LOAD_ERROR",
	CART_LOAD_SUCCESS: "CART_LOAD_SUCCESS",
	CART_LOAD_PENDING: "CART_LOAD_PENDING",

	UPDATE_CART_TOTAL: "UPDATE_CART_TOTAL",

	CART_DELETE_SUCCESS: "CART_DELETE_SUCCESS",
	EMPTY_PREVIOUS_CART_INFO: "EMPTY_PREVIOUS_CART_INFO",
	UPDATE_PREVIOUS_CART: "UPDATE_PREVIOUS_CART",

	SET_FREE_RENTAL_MEMBERSHIP_NAME: "SET_FREE_RENTAL_MEMBERSHIP_NAME",
	PRODUCT_GET_ALL_SUCCESS: "PRODUCT_GET_ALL_SUCCESS",
	PRODUCT_GET_ALL_PENDING: "PRODUCT_GET_ALL_PENDING",
	PRODUCT_DETAIL_SUCCESS: "PRODUCT_DETAIL_SUCCESS",
	CMS_PRODUCT_DETAIL_SUCCESS: "CMS_PRODUCT_DETAIL_SUCCESS",
	LINKED_PRODUCT_SUCCESS: "LINKED_PRODUCT_SUCCESS",
	CLEAR_LINKED_PRODUCTS: "CLEAR_LINKED_PRODUCTS",

	PRODUCT_FILTER_CHANGE: "PRODUCT_FILTER_CHANGE",
	LAST_SELECTED_CATEGORY_CHANGE: "LAST_SELECTED_CATEGORY_CHANGE",
	SET_PRICE_FILTER: "SET_PRICE_FILTER",
	CLEAR_PRICE_FILTER: "CLEAR_PRICE_FILTER",

	CMSPAGE_CONTENT_PENDING: "CMSPAGE_CONTENT_PENDING",
	CMSPAGE_CONTENT_SUCCESS: "CMSPAGE_CONTENT_SUCCESS",
	CMSPAGE_CONTENT_UPDATE_REQUEST: "CMSPAGE_CONTENT_UPDATE_REQUEST",
	CMSPAGE_CONTENT_UPDATE: "CMSPAGE_CONTENT_UPDATE",
	CMSPAGE_CONTENT_ERROR: "CMSPAGE_CONTENT_ERROR",

	CMSPAGE_CONTENT_BLOCK_PENDING: "CMSPAGE_CONTENT_BLOCK_PENDING",
	CMSPAGE_CONTENT_BLOCK_SUCCESS: "CMSPAGE_CONTENT_BLOCK_SUCCESS",
	CMSPAGE_CONTENT_BLOCK_ERROR: "CMSPAGE_CONTENT_BLOCK_ERROR",

	FAQPAGE_CONTENT_PENDING: "FAQPAGE_CONTENT_BLOCK_PENDING",
	FAQPAGE_CONTENT_SUCCESS: "FAQPAGE_CONTENT_BLOCK_SUCCESS",
	FAQPAGE_CONTENT_ERROR: "FAQPAGE_CONTENT_BLOCK_ERROR",

	MEMBERSHIP_SUCCESS: "MEMBERSHIP_SUCCESS",
	MEMBERSHIP_ERROR: "MEMBERSHIP_ERROR",

	PAYMENT_COUPON_SUCCESS: "PAYMENT_COUPON_SUCCESS",

	ADD_VECOZO_DETAILS: "ADD_VECOZO_DETAILS",

	CLIENT_GET_ALL_SUCCESS: "CLIENT_GET_ALL_SUCCESS",
	CLIENT_GET_ALL_PENDING: "CLIENT_GET_ALL_PENDING",

	ADD_NEWSLETTER: "ADD_NEWSLETTER",

	FETCH_CUSTOMER_ORDERS_SUCCESS: "FETCH_CUSTOMER_ORDERS_SUCCESS",
	FETCH_ORDER_BY_ID_SUCCESS: "FETCH_ORDER_BY_ID_SUCCESS",
	FETCH_CUSTOMER_ORDERS_FAILED: "FETCH_CUSTOMER_ORDERS_FAILED",
	FETCH_ORDER_BY_ID_FAILED: "FETCH_ORDER_BY_ID_FAILED",

	URL_REWRITE: "URL_REWRITE",

	USER_DETAIL_PENDING: "USER_DETAIL_PENDING",
	USER_DETAIL_SUCCESS: "USER_DETAIL_SUCCESS",
	USER_DETAIL_ERROR: "USER_DETAIL_ERROR",
	USER_DETAIL_CLEAR: "USER_DETAIL_CLEAR",

	SET_BUTTON_DISABLE: "SET_BUTTON_DISABLE",

	SEARCH_ELASTIC_SEARCH_PRODUCTS_HEADER: "SEARCH_ELASTIC_SEARCH_PRODUCTS_HEADER",
	SEARCH_ELASTIC_SEARCH_CONTENT_HEADER: "SEARCH_ELASTIC_SEARCH_CONTENT_HEADER",
	SEARCH_ELASTIC_SEARCH_CATEGORIES_HEADER: "SEARCH_ELASTIC_SEARCH_CATEGORIES_HEADER",

	SEARCH_ELASTIC_SEARCH_PRODUCTS_PAGE: "SEARCH_ELASTIC_SEARCH_PRODUCTS_PAGE",
	SEARCH_ELASTIC_SEARCH_CONTENT_PAGE: "SEARCH_ELASTIC_SEARCH_CONTENT_PAGE",
	SEARCH_ELASTIC_SEARCH_CATEGORIES_PAGE: "SEARCH_ELASTIC_SEARCH_CATEGORIES_PAGE",

	SET_LOADING: "SET_LOADING",
};

/** A list of actions for the new store
 *  If you create a ticket that changes the store, please increase
 *  the estimation to include the store refactoring to the new implementation
 *  and add the actions to the list below
 */
export const UPDATE_CHECKOUT_ORDER = "UPDATE_CHECKOUT_ORDER";
export const UPDATE_CHECKOUT_STEP = "UPDATE_CHECKOUT_STEP";
export const SET_CHECKOUT_ORDER_ID = "SET_CHECKOUT_ORDER_ID";
export const SET_CHECKOUT_ISECD = "SET_CHECKOUT_ISECD";
export const CLEAR_CHECKOUT_ORDER = "CLEAR_CHECKOUT_ORDER";

export const UPDATE_CHECKOUT_INDICATIONS = "UPDATE_CHECKOUT_INDICATIONS";
export const CLEAR_CHECKOUT_INDICATIONS = "CLEAR_CHECKOUT_INDICATIONS";

export const UPDATE_PRODUCT_FILTERS = "UPDATE_PRODUCT_FILTERS";
export const CLEAR_PRODUCT_FILTERS = "CLEAR_PRODUCT_FILTERS";
export const CLEAR_PRODUCT_FILTER_BY_KEY = "CLEAR_PRODUCT_FILTER_BY_KEY";
export const RETRIEVE_PRODUCTS = "RETRIEVE_PRODUCTS";
export const CLEAR_ECOMMERCE_OBJECT = "CLEAR_ECOMMERCE_OBJECT";
export const UPDATE_ECOMMERCE_OBJECT = "UPDATE_ECOMMERCE_OBJECT";
export const ADD_ECOMMERCE_ITEMS = "ADD_ECOMMERCE_ITEMS";
export const PREPARE_ECOMMERCE_FOR_PURCHASE = "PREPARE_ECOMMERCE_FOR_PURCHASE";

export const START_LOADING = 'START_LOADING';
export const STOP_LOADING = 'STOP_LOADING';

export const RESET_STATE = "RESET_STATE";
export const RESET_REDUX_TIMESTAMP = "RESET_REDUX_TIMESTAMP";
export default ActionTypes;
