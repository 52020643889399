import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {
	currencyFormatter,
	getLoggedInUserInfo,
	productNormalPrice,
	productSpecialPrice,
	userMembershipPrice,
	userHasHighMembership,
	isTablet,
} from "../../../../common/common";
import { ProductInterface } from "../../../Pages/Catalog/catalog-interfaces";
import PriceTooltip from "./PriceTooltip";
import { useLoadingEndpoints } from "../../../../common/common-hooks";
import { Skeleton } from "@mui/material";
const ProductPricing = (props: {
	product: ProductInterface;
	onTile?: boolean;
	modal?: boolean;
}): React.ReactElement => {
	const { t } = useTranslation();
	const { product } = props;
	const { product_type, tier_prices, custom_attributes } = product;
	const isBuy = product_type === "buy";
	const isRent = product_type === "rent";
	const isLoan = product_type === "lend" || product_type === "loan";
	const modal = props.modal;
	const price = productNormalPrice(product);
	const specialPrice = productSpecialPrice(product);
	const userInfo = getLoggedInUserInfo();
	const hasHighMembership = userHasHighMembership(
		String(userInfo?.groupId),
		custom_attributes?.extra_high_membership_price_customer_groups,
	);
	const highMembershipPrice =
		custom_attributes?.extra_high_membership_price || 0;

	const { loadingGetSingleProduct } = useLoadingEndpoints(); // Use the useLoadingEndpoints hook

	let membershipPrice = null;

	if (!hasHighMembership && !highMembershipPrice && !specialPrice) {
		membershipPrice =
			(isBuy && userMembershipPrice(userInfo?.groupId, tier_prices)) ||
			(isRent && custom_attributes?.rent_member_price);
	}
	const memberPrice = isBuy
		? membershipPrice || highMembershipPrice
		: isRent && membershipPrice;
	const actionPrices = specialPrice && memberPrice > 0;
	const nonRegularPrices = specialPrice || memberPrice || actionPrices;

	const configCheckProductTiles = (product: any): string | JSX.Element => {
		if (product.type_id === "configurable") {
			if (props.modal) {
				return currencyFormatter(props.product.price);
			} else if (isLoan) {
				return (
					<div className="pricing-text fw-bold">
						{pricingText()}
					</div>
				);
			} else {
				return (
					<>
						<div className="d-flex flex-row justify-content-between zp-price fw-bold mt-2">
							{product?.custom_attributes?.prijs_soort ===
								"6097" ||
								product?.custom_attributes?.prijs_soort === "9864"
								? t("product_detail.from")
								: product?.custom_attributes?.prijs_soort ===
									"6098" ||
									product?.custom_attributes?.prijs_soort ===
									"9867"
									? t("product_detail.normal")
									: ""}
							{props.product.custom_attributes.special_price >
								0 ? (
								<>
									<>
										<div className="text-brand">
											{currencyFormatter(
												props.product.custom_attributes
													.special_price,
											)}
										</div>
										<div>
											<del>
												{currencyFormatter(
													props.product
														.custom_attributes
														.price_configurable,
												)}
											</del>
										</div>
									</>
								</>
							) : (
								<>
									{currencyFormatter(
										props.product.custom_attributes
											.price_configurable,
									)}
								</>
							)}
						</div>
						{memberPrice > 0 && (
							<div className="d-flex flex-row justify-content-between text-info fw-bold mt-2">
								<div className="d-flex align-items-center">
									{t(
										`product_detail.${membershipPrice
											? "membershipPrice"
											: "specialMemberPrice"
										}`,
									)}
									<OverlayTrigger
										placement="right"
										overlay={
											<Tooltip id="tooltip-disabled">
												{t(
													"product_detail.tooltip.membership",
												)}
											</Tooltip>
										}
									>
										<em className="icon icon-sm info-circle-blue-16 ms-2"></em>
									</OverlayTrigger>
								</div>
								<div>
									{currencyFormatter(parseFloat(memberPrice))}
								</div>
							</div>
						)}
					</>
				);
			}
		} else {
			if (product_type === "free_rental") {
				if (price < 1) {
					return "Gratis";
				} else {
					return currencyFormatter(price);
				}
			} else {
				return currencyFormatter(price);
			}
		}
	};
	const pricingText = (): string | JSX.Element => {
		if (product.product_type === "free_rental") {
			if (product.price === 0) {
				return "Gratis";
			} else {
				return <del>{currencyFormatter(product.price)}</del>;
			}
		} else if (isRent) {
			if (membershipPrice > 0) {
				return (
					<div className="fw-normal">
						{currencyFormatter(
							product?.custom_attributes?.rent_price,
						)}
					</div>
				);
			} else {
				return currencyFormatter(
					product?.custom_attributes?.rent_price,
				);
			}
		} else if (product.product_type === "loan") {
			return <div>Gratis lenen</div>;
		} else if (specialPrice || membershipPrice) {
			if (specialPrice > 0) {
				return (
					<del className="fw-normal">
						{currencyFormatter(product.price)}
					</del>
				);
			} else if (membershipPrice > 0) {
				return (
					<div className="fw-normal">
						{currencyFormatter(product.price)}
					</div>
				);
			}
		} else {
			return <div>{currencyFormatter(product.price)}</div>;
		}
	};

	const membershipActionPricing = (): JSX.Element => {
		// Actie prijs voor leden
		return (
			<>
				{isTablet()
					? <>
						{t(
							`product_detail.${membershipPrice
								? "membershipPrice"
								: "specialMemberPrice"
							}`
						)}
						<div className="d-inline-flex align-items-center">
							<OverlayTrigger
								placement="right"
								overlay={
									<Tooltip id="tooltip-disabled">
										{t("product_detail.tooltip.membership")}
									</Tooltip>
								}
							>
								<em className="icon icon-sm info-circle-blue-16 ms-2"></em>
							</OverlayTrigger>
						</div>
					</>
					: null}
				<div className="pricing-text text-info">
					{product_type === "free_rental"
						? memberPrice === 0
							? "Gratis"
							: currencyFormatter(parseFloat(memberPrice))
						: currencyFormatter(parseFloat(memberPrice))}
				</div>
					{!isTablet() &&
						<div className="d-flex align-items-center">
							{t(
							`product_detail.${membershipPrice
								? "membershipPrice"
								: "specialMemberPrice"
							}`,
						)}
							<OverlayTrigger
								placement="right"
								overlay={
									<Tooltip id="tooltip-disabled">
										{t("product_detail.tooltip.membership")}
									</Tooltip>
								}
							>
								<em className="icon icon-sm info-circle-blue-16 ms-2"></em>
							</OverlayTrigger>
						</div>
					}
			</>
		);
	};

	return (
		<div className={`${props.onTile ? "" : "product-pricing"}`}>
			<div className="d-flex align-items-center gap-3  mt-2">
				{props.onTile ? (
					// No price shown for configurable product
					props.product.type_id === "configurable" ? (
						<div>{configCheckProductTiles(product)}</div>
					) : (
						<>
							<div className="flex-row d-flex mb-0 fw-bold special-pricing-container">
								{isBuy && specialPrice > 0 ? (
									<>
										<div className="pricing special-pricing text-brand">
											{currencyFormatter(specialPrice)}
										</div>
										<div>
											<del>
												{product_type === "free_rental"
													? price === 0
														? "Gratis"
														: currencyFormatter(price)
													: currencyFormatter(price)}
											</del>
										</div>
									</>
								) : isRent ? (
									<>
										{memberPrice > 0 ? (
											<>
												{props.modal ? (
													<>
														<div className="d-flex flex-row justify-content-between">
															<div className="d-flex flex-row text-info">
																<div>
																	{product_type ===
																		"free_rental"
																		? memberPrice ===
																			0
																			? "Gratis"
																			: currencyFormatter(
																				memberPrice,
																			)
																		: product_type ===
																			"lend"
																			? t(
																				"product_detail.loanPrice",
																			)
																			: currencyFormatter(
																				memberPrice,
																			)}
																</div>
															</div>
															<div>
																<del>
																	{product_type ===
																		"free_rental"
																		? price ===
																			0
																			? "Gratis"
																			: currencyFormatter(
																				price,
																			)
																		: currencyFormatter(
																			price,
																		)}
																</del>
															</div>
														</div>
													</>
												) : (
													<>
														<div className="d-flex flex-row justify-content-between text-info">
															<div className="align-items-center">
																<OverlayTrigger
																	placement="right"
																	overlay={
																		<Tooltip id="tooltip-disabled">
																			{t(
																				"product_detail.tooltip.membership",
																			)}
																		</Tooltip>
																	}
																>
																	<em className="icon icon-sm info-circle-blue-16 me-2"></em>
																</OverlayTrigger>
															</div>
															<div>
																{product_type ===
																	"free_rental"
																	? memberPrice ===
																		0
																		? "Gratis"
																		: currencyFormatter(
																			memberPrice,
																		)
																	: currencyFormatter(
																		memberPrice,
																	)}
															</div>
														</div>
														<div>
															<del>
																{product_type ===
																	"free_rental"
																	? price === 0
																		? "Gratis"
																		: currencyFormatter(
																			price,
																		)
																	: currencyFormatter(
																		price,
																	)}
															</del>
														</div>
													</>
												)}
											</>
										) : (
											<div>
												{configCheckProductTiles(product)}
											</div>
										)}
									</>
								) : isLoan ? (
										<div className="pricing-text">
											{pricingText()}
										</div>
								) : (
									<div>
										{configCheckProductTiles(product)}
									</div>
								)}
							</div>
							{isRent && !props.onTile && (
								<>
									<div className="flex-row rent-time-container">
										<div className="me-1">
											{t(
												"product_detail.price",
											)}
										</div>
										<div>
											{
												product
													?.custom_attributes
													?.rent_time
											}
										</div>
									</div>
								</>
							)}
							{!isRent &&
								memberPrice > 0 &&
								membershipActionPricing()}
						</>
					)
				) : (
					<>
						<div className={!props.onTile ? "mt-4" : "mt-2"}>
							{loadingGetSingleProduct ? (
								<>
									<Skeleton animation="wave" width="118px" height="39px" />
									<Skeleton animation="wave" width="135px" height="80px" />
								</>
							) : (
								<>
									{/* ----- ADVIESPRIJS ----- */}
									<div className="pricing regular-pricing">
										{/* When all prices are shown, don't show title */}
										{!nonRegularPrices && (
											<div className="d-flex align-items-center">
												{/* Title */}
												{product?.custom_attributes?.prijs_soort ===
													"9864"
													? t("product_detail.fromPrice")
													: t("product_detail.normalPrice")}
												<PriceTooltip product={product} />
											</div>
										)}
										{/* Pricing text */}
										<div className="pricing-text">{pricingText()}</div>
									</div>
									{/* ----- ACTIE PRIJS ----- */}
									{specialPrice > 0 && isBuy && (
										<div className="pricing special-pricing">
											{isTablet()
												? t("product_detail.specialPrice")
												: null}
											{memberPrice > 0 ? (
												<div className="pricing-text text-brand normal-font">
													{currencyFormatter(specialPrice)}
												</div>
											) : (
												<div className="pricing-text text-brand">
													{currencyFormatter(specialPrice)}
												</div>
											)}
											{isTablet()
												? null
												: t("product_detail.specialPrice")}
										</div>
									)}
									{/* ----- ACTIE PRIJS VOOR LEDEN ----- */}
									{memberPrice > 0 &&
										(specialPrice > 0 ? (
											<div className="pricing member-pricing">
												{membershipActionPricing()}
											</div>
										) : (
											<div className="pricing member-pricing large-font">
												{membershipActionPricing()}
											</div>
										))}
								</>
							)}
						</div>
					</>
				)}
				{/* Rent time (a.k.a "Per daag" text) */}
				{isRent && !modal && (
					<>
						<div className="rent-time">
							<strong>{product?.custom_attributes?.rent_time}</strong>
						</div>
					</>
				)}
			</div>
			{/* Discount text "Uw extra ledenkorting is toegepast" */}
			{(membershipPrice || hasHighMembership) && !modal && (
				<div className="mt-3">
					<div className="me-1">
						{t("product_detail.discountApplied")}
					</div>
				</div>
			)}
		</div>
	);
};

export default ProductPricing;
