import ActionTypes from "../store/types/actionTypes";

const initialState = {
  isLoading: false,
};

function loadingReducer(state = initialState, action: any) {
	switch (action.type) {
		case ActionTypes.SET_LOADING:
			return {
				...state,
        isLoading: action.payload.loading,
			};
		default:
			return state;
	}
}
export default loadingReducer;
