import { Client, FoundClient } from "./client-types";
import {
	getStorageItem,
	dateFormat,
	StorageKeys,
} from "../../../common/common";
import moment from "moment";
import { ObjectProps } from "../../../common/interfaces/common.interfaces";

export const getSelectedClient = (): Client => {
	return JSON.parse(getStorageItem(StorageKeys.SELECTED_CLIENT));
};

export const getSelectedFoundClient = (): FoundClient => {
	return JSON.parse(getStorageItem(StorageKeys.SELECTED_CLIENT));
};
export const prepareServicePayloadFromConsumer = (userInfo): any => {
	return {
		...userInfo,
		email: userInfo?.emailAddress,
		dateOfBirth: userInfo?.birthDate,
		homeAddress: {
			street: userInfo?.street,
			houseNumber: userInfo?.houseNumber,
			houseNumberSuffix: userInfo?.houseNumberSuffix,
			postalCode: userInfo?.postalCode,
			city: userInfo?.city,
		},
	};
};

export const getDisplayName = (user?: any): string => {
	const selectedClient = user || getSelectedFoundClient();
	const { invoiceAddress, client, clientInformation } = selectedClient || {};

	if (clientInformation?.firstName) {
		const { firstName, middleName, lastName } = clientInformation;
		return middleName
			? `${firstName} ${middleName} ${lastName}`
			: `${firstName} ${lastName}`;
	}

	if (invoiceAddress?.firstName) {
		const { firstName, middleName, lastName } = invoiceAddress;
		return middleName
			? `${firstName} ${middleName} ${lastName}`
			: `${firstName} ${lastName}`;
	}

	if (!client?.firstName && !client?.initials) {
		const { firstName, middleName, lastName } = selectedClient;
		return middleName
			? `${firstName} ${middleName} ${lastName}`
			: `${firstName} ${lastName}`;
	}

	return `${client?.initials} ${client?.lastName}`;
};

export const getFullAddress = (): string => {
	const selectedClient = getSelectedClient();
	return `${selectedClient.homeAddress.streetName} ${selectedClient.homeAddress.houseNumber
		}${selectedClient.homeAddress.houseNumberSuffix
			? " " + selectedClient.homeAddress.houseNumberSuffix
			: ""
		} ${selectedClient.homeAddress.postalCode} ${selectedClient.homeAddress.city
		}`;
};

export const createClientReducer = (client: Client): any => ({
	"Client information": {
		Initials: client.initials,
		"First Name": client.firstName,
		"Middle Name": client.middleName,
		"Last Name": client.lastName,
		Gender: client.gender,
		"Date of Birth": client.dateOfBirth,
		Address: {
			Street: client.address.street,
			"House number": client.address.houseNumber,
			"House number suffix": client.address.houseNumberSuffix,
			"Postal code": client.address.postalCode,
			City: client.address.city,
		},
		Email: client.email,
		"Phone number": client.phoneNumber,
		"Membership type": client.membershipType || "",
		"Membership number": client.membershipNumber || "",
		Gewicht: client.weight || "",
		Lengte: client.length || "",
		Indicatie: client.indication,
	},
});

export const searchClientReducer = (client: Client): any => ({
	"Client information": {
		Initials: client.initials,
		"First Name": client.firstName,
		"Middle Name": client.middleName,
		"Last Name": client.lastName,
		Gender: client.gender,
		birthDate: client.client.birthDate,
		"Home Address": {
			Street: client.address.streetName,
			"House number": client.address.houseNumber,
			"House number suffix": client.address.houseNumberSuffix,
			"Postal code": client.address.postalCode,
			City: client.address.city,
		},
		Email: client.email,
		"Phone number": client.phoneNumber,
		"Membership type": client.membershipType || "",
		"Membership number": client.membershipNumber || "",
		Gewicht: client.weight || "",
		Lengte: client.length || "",
		Indicatie: client.indication,
	},
});

export const clientResolver = (item: ObjectProps<any>): Client => {
	return {
		id: item["Id"],
		initials: item["Initials"] || "",
		firstName: item["First Name"],
		middleName: item["Middle Name"] || "",
		lastName: item["Last Name"],
		gender: item["Gender"],
		dateOfBirth: moment(item["Date of Birth"]).format(dateFormat),
		email: item["Email"],
		phoneNumber: item["Phone number"] || "",
		contactDetails: {},
		homeAddress: {
			streetName: item["Home Address"].Street,
			houseNumber: String(item["Home Address"]["House number"]),
			houseNumberSuffix:
				item["Home Address"]["House number suffix"] || "",
			postalCode: item["Home Address"]["Postal code"],
			city: item["Home Address"].City,
			country: "NL",
		},
		deviatingAddress: {
			streetName: item["Home Address"].Street,
			houseNumber: String(item["Home Address"]["House number"]),
			houseNumberSuffix:
				item["Home Address"]["House number suffix"] || "",
			postalCode: item["Home Address"]["Postal code"],
			city: item["Home Address"].City,
			country: "NL",
		},
		membershipType: item["Membership type"] || "",
		membershipNumber: item["Membership number"] || "",
		weight: item["Gewicht"] || "",
		indication: item["Indicatie"] || "",
		length: item["Lengte"] || "",
	};
};
