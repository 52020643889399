import React from "react";
import Skeleton, { SkeletonProps } from "@mui/material/Skeleton";

/**
 * CustomSkeleton component that wraps MUI Skeleton with default props.
 *
 * @param {CustomSkeletonProps} props - Props for the CustomSkeleton component.
 * @returns {JSX.Element} The CustomSkeleton component.
 */
interface CustomSkeletonProps extends SkeletonProps {
  button?: boolean;
}

const CustomSkeleton: React.FC<CustomSkeletonProps> = ({ button, ...props }) => {
  const buttonStyles = button ? { borderRadius: 22, height: 42 } : {};

  return <Skeleton {...props} sx={{ ...buttonStyles, ...props.sx }} />;
};

CustomSkeleton.defaultProps = {
  animation: "wave",
  variant: "rectangular",
  width: "100%",
  height: 35
};

export default CustomSkeleton;
