import { ProductActions, Products } from "../types/productTypes";
import {
	 CLEAR_PRODUCT_FILTERS,
	 RETRIEVE_PRODUCTS,
	 UPDATE_PRODUCT_FILTERS,
	 CLEAR_PRODUCT_FILTER_BY_KEY
} from "../types/actionTypes";

export const initialStateConsumer: Products = {
	products: [],
	filters: {
		productType: [],
		categoryId: [],
		sortBy: "recommended",
		sortDirection: "DESC",
		currentPage: "1",
		pageSize: "12",
		priceRange: "",
	},
};

export const initialStateBusiness: Products = {
	products: [],
	filters: {
		productType: [],
		categoryId: [],
		sortBy: "name",
		sortDirection: "ASC",
		currentPage: "1",
		pageSize: "12",
		priceRange: "",
	},
};

export default function productReducerWithType (filterName: "searchPage" | "listPage", userType: "consumer" | "business") {
	const initialState =
		userType === "consumer" ? initialStateConsumer : initialStateBusiness;
	return function productReducer (state = initialState, action: ProductActions) {
		if(filterName !== action.filterName || userType !== action.userType)
			return state;
		switch (action.type as string) {
			case CLEAR_PRODUCT_FILTERS: {
				// Remove all query params from URL
				window.history.replaceState({ path: window.location.pathname }, "", window.location.pathname);

				return {
					...state,
					filters: initialState.filters,
				};
			}
			case CLEAR_PRODUCT_FILTER_BY_KEY: {
				const { filters } = state;

				const searchParams = new URLSearchParams(window.location.search);
				searchParams.delete(action.payload.key);
				window.history.replaceState({ path: window.location.pathname }, "", `${window.location.pathname}?${searchParams.toString()}`);

				return {
					...state,
					filters: {
						...filters,
						[action.payload.key]: initialState.filters[action.payload.key],
					}
				};
			}
			case UPDATE_PRODUCT_FILTERS:
				return {
					...state,
					filters: action.payload.filters,
				};
			case RETRIEVE_PRODUCTS:
				return {
					...state,
					products: action.payload.products
				};
			default:
				return state;
		};
	};
}
