import React, { ReactElement, useState, useEffect } from "react";
import { getCatalogImage, isMobile } from "../../../../common/common";
import { ProductImageInterface } from "../../../Pages/Catalog/catalog-interfaces";
import { ImageMagnifier } from "../../index";
import SimpleModal from "../../SimplestModal/SimpleModal";

interface ImagesInterface {
	images: Array<ProductImageInterface>;
}

const ProductImages = (props: ImagesInterface): ReactElement => {
	const [activeImage, setActiveImage] = useState(null);
	const [showModal, setShowModal] = useState(false);
	const [imageIndex, setImageIndex] = useState(0);
	const goToPreviousPicture = () => {
		let newImageIndex = imageIndex - 1;
		if (newImageIndex < 0) {
			setImageIndex(props.images.length - 1);
			newImageIndex = props.images.length - 1;
		} else {
			setImageIndex(newImageIndex);
		}
		setActiveImage(props.images[newImageIndex].file);
	};
	const goToNextPicture = () => {
		let newImageIndex = imageIndex + 1;
		if (props.images.length === newImageIndex) {
			setImageIndex(0);
			newImageIndex = 0;
		} else {
			setImageIndex(newImageIndex);
		}
		setActiveImage(props.images[newImageIndex].file);
	};
	const navToImage = (index) => {
		setImageIndex(index);

		setActiveImage(props.images[index].file);
	};
	let count = 0;
	const totalCount = props.images?.length;

	const closeSimpleModal = (): void => {
		setShowModal(false);
	};

	useEffect(() => {
		if (props.images?.length > 0) {
			setActiveImage(props.images[0].file);
		}
	}, [props.images]);

	return (
		<div className="product__image">
			<div className="product__image-big">
				<div className="tab-pane active" id="pic-1">
					{activeImage && (
						<div>
							{props.images?.length > 1 &&
								props.images?.map(
									(image, index): ReactElement =>
										imageIndex !== 0 && (
											<button
												className="btn previous"
												onClick={goToPreviousPicture}
												key={index}
											>
												<em className="icon breadcrumb-arrow"></em>
											</button>
										),
								)}
							<ImageMagnifier
								src={getCatalogImage(activeImage)}
							/>
							{props.images?.length > 1 &&
								props.images?.map(
									(image, index): ReactElement =>
										imageIndex !==
											props.images.length - 1 && (
											<button
												className="btn next"
												onClick={goToNextPicture}
												key={index}
											>
												<em className="icon breadcrumb-arrow"></em>
											</button>
										),
								)}
						</div>
					)}
				</div>
			</div>
			{activeImage && (
				<div
					className="zoom-button"
					onClick={(): void => {
						setShowModal(true);
					}}
				>
					<img
						className="img-fluid zoom-button"
						src={"/assets/images/zoom.svg"}
					/>
				</div>
			)}
			<ul className="product__image-thumb nav nav-tabs">
				{props.images?.length > 0 &&
					props.images?.map(
						(image, index): ReactElement =>
							isMobile() ? (
								count++ <= 2 ? (
									<li key={index}>
										<button
											className={
												imageIndex === index
													? "p-0 m-0 active-thumbnail"
													: "p-0 m-0"
											}
											data-bs-target="#pic-1"
											data-bs-toggle="tab"
											onClick={(): void =>
												navToImage(index)
											}
										>
											<img
												className="img-fluid"
												src={getCatalogImage(
													image.file,
												)}
											/>
										</button>
									</li>
								) : (
									count === 4 && (
										<li key={index}>
											<button
												className={
													imageIndex === index
														? "p-0 m-0 active-thumbnail"
														: "p-0 m-0"
												}
												data-bs-target="#pic-1"
												data-bs-toggle="tab"
												onClick={(): void =>
													navToImage(index)
												}
											>
												<div
													className={"count"}
													onClick={(): void => {
														setShowModal(true);
													}}
												>
													+{totalCount - count}
												</div>
												<img
													className="img-fluid"
													src={getCatalogImage(
														image.file,
													)}
												/>
											</button>
										</li>
									)
								)
							) : (
								<>
									{count++ <= 5 ? (
										<li key={index}>
											<button
												className={
													imageIndex === index
														? "p-0 m-0 active-thumbnail"
														: "p-0 m-0"
												}
												data-bs-target="#pic-1"
												data-bs-toggle="tab"
												onClick={(): void =>
													navToImage(index)
												}
											>
												<img
													className="img-fluid"
													src={getCatalogImage(
														image.file,
													)}
												/>
											</button>
										</li>
									) : (
										count === 7 && (
											<li key={index}>
												<button className="p-0 m-0">
													<div
														className={"count"}
														onClick={(): void => {
															setShowModal(true);
														}}
													>
														+{totalCount - count}
													</div>
													<img
														className="img-fluid"
														src={getCatalogImage(
															image.file,
														)}
													/>
												</button>
											</li>
										)
									)}
								</>
							),
					)}
			</ul>
			{showModal && (
				<SimpleModal
					show={showModal}
					class={"large "}
					closeCartModal={closeSimpleModal}
					modalBody={
						<>
							<div
								onClick={(): void => closeSimpleModal()}
								className="item-crossout close-modal-btn"
							>
								<img
									src="/assets/images/icon-close.svg"
									alt="close-icon"
								/>
							</div>
							<div className="product__image-big">
								<div className="tab-pane active" id="pic-1">
									{activeImage && (
										<div>
											{props.images?.length > 1 &&
												props.images?.map(
													(
														image,
														index,
													): ReactElement =>
														imageIndex !== 0 && (
															<button
																className="btn previous"
																onClick={
																	goToPreviousPicture
																}
																key={index}
															>
																<em className="icon breadcrumb-arrow"></em>
															</button>
														),
												)}
											<ImageMagnifier
												src={getCatalogImage(
													activeImage,
												)}
											/>
											{props.images?.length > 1 &&
												props.images?.map(
													(
														image,
														index,
													): ReactElement =>
														imageIndex !==
															props.images
																.length -
																1 && (
															<button
																className="btn next"
																onClick={
																	goToNextPicture
																}
																key={index}
															>
																<em className="icon breadcrumb-arrow"></em>
															</button>
														),
												)}
										</div>
									)}
								</div>
							</div>
							<ul className="product__image-thumb nav nav-tabs">
								{props.images?.length > 0 &&
									props.images?.map(
										(image, index): ReactElement => (
											<li key={index}>
												<button
													className={
														imageIndex === index
															? "p-0 m-0 active-thumbnail"
															: "p-0 m-0"
													}
													onClick={(): void =>
														navToImage(index)
													}
												>
													<img
														className="img-fluid"
														src={getCatalogImage(
															image.file,
														)}
													/>
												</button>
											</li>
										),
									)}
							</ul>
						</>
					}
				/>
			)}
		</div>
	);
};

export default ProductImages;
