import React, { ReactElement } from "react";
import { Skeleton, Box, Stack } from "@mui/material"; // Importing Skeleton from Material-UI
import { connect } from "react-redux";
import { isBusiness } from "../../../../common/common";

const connector = connect();

const ProductListSkeleton = (): ReactElement => {
  return (
    <Box className="product-list-view d-flex justify-content-between product-list-skeleton">
      <Box className="d-flex w-100 align-items-center">
        <Box className="product-list-view__image d-flex align-items-center justify-content-center me-4">
          <Skeleton variant="rectangular" width={145} height={145} animation="wave" />
        </Box>
        <Box className="list-content w-100">
          <Box className="product-list-view__title mb-4" spacing={3}>
            <Skeleton width="70%" height={50} animation="wave"/>
          </Box>
          <Stack direction="column" className="mb-3" spacing={3}>
            <Skeleton width="20%" animation="wave"/>
          </Stack>
          <Box className="short-description">
            <Skeleton width="90%" animation="wave"/>
            <Skeleton width="90%" animation="wave"/>
          </Box>
          <Box className="d-flex align-items-center mt-4 justify-content-between">
            <Skeleton width="30%" height={36} animation="wave"/>
            <div className="d-flex justify-content-center">
              <Skeleton variant="rectangular" animation="wave" width={240} height={40} sx={{ borderRadius: 16 }}/>
            </div>
            {isBusiness() && (
              <Skeleton width="90%" animation="wave"/>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default connector(ProductListSkeleton);
