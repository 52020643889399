import React, { ReactElement, useState } from "react";
import {
	ProductAttribute,
	ProductInterface,
} from "../../../Pages/Catalog/catalog-interfaces";
import QuantityAndCart from "../../Cms/QuantityAndCart/QuantityAndCart";
import bedverhogers from "../../../../common/data/bedverhogers.json";

interface ProductInfoInterface {
	product: ProductInterface;
	buyOptions: Array<ProductAttribute>;
	orderType?: Array<string>;
	clickHandler?: (values: any) => void;
	productChange?: (values: any) => void;
	productAttributeChange?: (values: any) => void;
}

const ProductInfo = (props: ProductInfoInterface): ReactElement => {
	const [quantity, setQuantity] = useState(1);
	const { product } = props;

	const submitHandler = (values: any): void => {
		props.clickHandler(values);
	};

	return (
		<div className="product-info">
			<QuantityAndCart
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				isBedVerhogers={bedverhogers.indexOf(product.sku) > -1}
				product={product}
				quantity={quantity}
				setQuantity={setQuantity}
				clickHandler={submitHandler}
				productAttributeChange={props.productAttributeChange}
				detailDisplay
			/>
		</div>
	);
};

export default ProductInfo;
