import { useState } from "react";
import { ProductAttribute } from "../components/Pages/Catalog/catalog-interfaces";

export function useProductAttribute(
	initialState: ProductAttribute[],
): ProductAttribute[] {
	const [attributes] = useState<ProductAttribute[]>([
		...initialState,
		{ label: "Huren", value: "rent", attributeKey: "rent_sku_id" },
		{ label: "Kopen", value: "buy", attributeKey: "buy_sku_id" },
		{ label: "Lenen", value: "loan", attributeKey: "loan_sku_id" },
	]);
	return attributes;
}
