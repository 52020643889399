import ActionTypes from "../store/types/actionTypes";

function clientReducer(state = {}, action: any) {
	switch (action.type) {
		case ActionTypes.CLIENT_GET_ALL_SUCCESS:
			return {
				...state,
				isLoading: false,
			};
		case ActionTypes.CLIENT_GET_ALL_PENDING:
			return {
				...state,
				isLoading: true,
			};
		default:
			return state;
	}
}
export default clientReducer;
