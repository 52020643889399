import { START_LOADING, STOP_LOADING } from "../types/actionTypes";
import { LoadingActions } from "../types/loadingTypes";

const initialState = {
    loadingEndpoints: {
        loadingCategories: false,
        loadingCategoriesProductQuantity: false,
        loadingGetProducts: false,
        loadingGetSingleProduct: false,
        loadingMenuItems: false,
        loadingCMSHomepage: false,
        loadingGreenTicker: false,
    }
};

const loadingReducer = (state = initialState, action: LoadingActions) => {
    switch (action.type) {
        case START_LOADING:
            return {
                ...state,
                loadingEndpoints: {
                    ...state.loadingEndpoints,
                    [action.payload]: true // Set loading status for the endpoint to true
                }
            };
        case STOP_LOADING:
            return {
                ...state,
                loadingEndpoints: {
                    ...state.loadingEndpoints,
                    [action.payload]: false // Set loading status for the endpoint to false
                }
            };
        default:
            return state;
    }
};

export default loadingReducer;
