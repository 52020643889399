import ActionTypes from "../store/types/actionTypes";

interface FaqState {
	isPending: boolean;
	faqContent?: any;
	isError?: boolean;
}

const initialState: FaqState = {
	isPending: true,
	isError: false,
};

const faqPageReducer = (state = initialState, action: any): FaqState => {
	switch (action.type) {
		case ActionTypes.FAQPAGE_CONTENT_PENDING:
			return {
				...state,
				isPending: true,
				faqContent: {},
			};
		case ActionTypes.FAQPAGE_CONTENT_SUCCESS:
			return {
				...state,
				isPending: false,
				isError: false,
				faqContent: action.payload.faqContent,
			};
		case ActionTypes.FAQPAGE_CONTENT_ERROR:
			return {
				...state,
				isPending: false,
				faqContent: {},
				isError: true,
			};
		default:
			return state;
	}
};

export default faqPageReducer;
