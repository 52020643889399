import React, { ReactElement } from "react";
import Modal from "react-bootstrap/Modal";

interface InnerProps {
	show?: boolean;
	qty?: any;
	closeCartModal?: any;
	modalBody?: any;
	class?: any;

	onHide?(flag: boolean);
}

type Props = InnerProps;

const SimpleModal = (props: Props): ReactElement => {
	return (
		<div className="modal-wrapper-add-to-cart">
			<Modal
				show={props.show}
				onHide={(): void => props.closeCartModal()}
				centered
				dialogClassName={
					props.class
						? props.class + "confirm-modal-container"
						: "confirm-modal-container"
				}
			>
				<Modal.Body>{props.modalBody}</Modal.Body>
			</Modal>
		</div>
	);
};

export default SimpleModal;
