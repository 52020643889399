import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "bootstrap/dist/js/bootstrap.min.js";
import "react-modern-drawer/dist/index.css";
import { polyfill } from "es6-promise";
import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import * as serviceWorker from "./serviceWorker";
import "./index.scss";

import "./i18n";
import { Spinner } from "./components/UI";
import "moment/locale/nl";
import Logo from "./assets/medipoint-logo.svg";

//Configure redux loader
import { detectIE } from "./common/browser";
import { store, persistor } from "./common/store";
import { PersistGate } from "redux-persist/integration/react";

polyfill();

export const SPINNING_LOADER = Spinner;

const App = React.lazy(() => import("./App"));

if (
	window.location.href.search(process.env.REACT_APP_LIVE_URL) > 0 &&
	process.env.NODE_ENV === "production"
) {
	const url = new URL(window.location.href);
	const { protocol, host, pathname } = url;
	if (url.host.search("www") === -1) {
		const redirectUrl = `${protocol}//www.${host}${pathname}`;
		window.location.assign(redirectUrl);
	}
}

const IEBrowser: number = detectIE() as number;

const render =
	IEBrowser && IEBrowser <= 11 ? (
		<div className="ie_not_support">
			<div className="container text-center">
				<img
					src={Logo}
					className="img-fluid justify-self-center"
					alt="Medipoint logo"
					width="200"
					height="auto"
				/>
				<h3>
					Uw browser wordt niet meer ondersteund, update uw browser of
					gebruik een andere browser om gebruik te maken van onze
					website.
				</h3>
			</div>
		</div>
	) : (
		<>
			<Provider store={store}>
				<Spinner />
				<PersistGate loading={null} persistor={persistor}>
					<React.Suspense fallback={<Spinner suspense />}>
						<App />
					</React.Suspense>
				</PersistGate>
			</Provider>
			{/* <LoaderRoot /> */}
		</>
	);

const container = document.getElementById("root") as HTMLElement;
const root = ReactDOM.createRoot(container!);

root.render(render);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
