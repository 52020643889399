import * as React from "react";

import { ContextType, ToasterType } from "./toaster-types";
import Toast from "./Toast";
import { generateUEID } from "../../../common/common";

const ToasterContext = React.createContext<Partial<ContextType>>({});

export const ToasterProvider: React.FC<{
	children: React.ReactNode;
}> = (props: { children: React.ReactNode }) => {
	const [toasts, setToasts] = React.useState<ToasterType[]>([]);

	const hasIndex = (id: string): boolean => {
		return toasts.find((toast) => toast.id === id) !== undefined;
	};

	const removeAll = (): void => {
		setToasts([]);
	};

	const remove = (id: string): void => {
		hasIndex(id) && setToasts(toasts.filter((toast) => toast.id !== id));
	};

	const add = (option: ToasterType): string => {
		option.id = generateUEID();
		setToasts([...toasts, option]);
		return option.id;
	};

	return (
		<ToasterContext.Provider
			value={{
				addToast: add,
				removeToast: remove,
				removeAll: removeAll,
				toasts: toasts,
			}}
		>
			{props.children}
		</ToasterContext.Provider>
	);
};

export const ToasterConsumer = (props: { children: React.ReactNode }) => {
	return (
		<ToasterContext.Consumer>
			{({ toasts, removeToast }): React.ReactElement => {
				return (
					<>
						{toasts.map((toast): React.ReactElement => {
							return (
								<Toast
									key={toast.id}
									{...toast}
									onDismiss={removeToast}
								/>
							);
						})}
						{props.children}
					</>
				);
			}}
		</ToasterContext.Consumer>
	);
};

export const useToaster = (): ContextType => {
	const ctx = React.useContext(ToasterContext);
	return {
		addToast: ctx.addToast,
		removeToast: ctx.removeToast,
		removeAll: ctx.removeAll,
		toasts: ctx.toasts,
	};
};
