import React, { ReactElement, useEffect, useState } from "react";
import {
	currencyFormatter,
	getCatalogImage,
	getProductUrl,
	handleServerError,
} from "../../../../common/common";
import { connect, ConnectedProps } from "react-redux";
import * as productActions from "../../../../common/actions/product";
import { Link } from "react-router-dom";
import { CmsContentToHtml } from "..";
import { useTranslation } from "react-i18next";
import { useToaster } from "../../Toaster";
interface LinkButtonProps {
	label: string;
	name: string;
	link: string;
}

const mapStateToProps = (state: any) => {
	return {
		...state.product,
	};
};

const connector = connect(mapStateToProps, {
	...productActions,
});

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & any;

export const LinkButton = (props: LinkButtonProps): ReactElement => {
	return (
		<div className="product-link d-flex justify-content-center align-items-center">
			<div className="m-0 text-break">
				<Link to={props.link}>
					<span className="d-block">{props.label}</span>
					<span className="d-block">
						<CmsContentToHtml content={props.name} />
					</span>
					<span className="d-block text-center arrow-icon">
						<svg width="16" height="16" viewBox="0 0 16 16">
							<g fill="none" fillRule="evenodd">
								<path d="M0 0L16 0 16 16 0 16z"></path>
								<path
									fill="#ED0C0C"
									d="M8 0L6.59 1.41 12.17 7 0 7 0 9 12.17 9 6.59 14.59 8 16 16 8z"
								>
        </path>
							</g>
						</svg>
					</span>
				</Link>
			</div>
		</div>
	);
};

const Products = (props: Props): ReactElement => {
	const [products, setProducts] = useState([]);
	const { addToast } = useToaster();
	const { t } = useTranslation();

	useEffect(() => {
		props
			.getProductsByIds({
				productsIds: props.ids,
				pageSize: props.pageSize,
			})
			.then(({ data }) => {
				setProducts(data);
			})
			.catch(() => {
				handleServerError(addToast, t("form.error.server"));
			});
	}, []);

	const getProductImage = (product) => {
		return product?.media_gallery_entries?.length > 0
			? getCatalogImage(product.media_gallery_entries[0].file)
			: "/placeholder.jpg";
	};
	return (
		<div className="products_block mb-5">
			<h3 className="mt-0">{props.title}</h3>
			<div className="row pt-3">
				{products.map((product) => (
					<div
						key={product.id}
						className="col-md-6 col-lg-5 col-24 mb-3 mb-lg-0"
					>
						<Link to={getProductUrl(product)}>
							<div className="product d-flex text-center justify-content-between">
								<div className="product__image mb-3 mt-4">
									<img
										src={getProductImage(product)}
										alt={product.name}
									/>
								</div>
								<div className="product__title">
									{product.name}
								</div>
								<div className="product__price">
									{product.product_type === "free_rental"
										? product.price === 0
											? "Gratis"
											: currencyFormatter(product.price)
										: currencyFormatter(product.price)}
								</div>
							</div>
						</Link>
					</div>
				))}
				<div className="col-lg-4 col-24 d-flex flex-column justify-content-between">
					{props.children}
				</div>
			</div>
		</div>
	);
};
export default connector(Products);
